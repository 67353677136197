import { React } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";

import RecentPosts from "../../molecules/RecentPosts";
import { FallBackView } from "../FallBackView";
import Error from "../Error";
import { blogServices } from "../../../services/api/blog";
import { useBlog } from "../../../hooks/reactQueryCustomHook/blog/useBlog";
import MainLayout from "../../../layout/MasterLayout";
import { formatDate } from "../../../utils/formatdate";

import "../../../assets/styles/blog.css";
import DangerouslySetHtml from "../../atoms/DangerouslySetHtml";
import { Col, Container, Row } from "react-bootstrap";

const BlogDescription = () => {
  const params = useParams();
  const slug = params?.slug;
  const { data: blog, isLoading, isError } = useBlog(slug);
  const { data: recentBlog, isLoading: isLoadingRecentBlogs } = useQuery(
    "recentBlogs",
    blogServices.recentBlogs
  );

  if (isLoading || isLoadingRecentBlogs) return <FallBackView />;
  if (isError) return <Error />;

  return (
    <MainLayout>
      <div className="blog-page">
        <Container className="mb-5">
          <Row>
            {/* main-blog-side  */}
            <Col md={12} lg={8} className="col-12 mt-5">
              {/* first-card-starts  */}
              <h2 className="post-title">{blog.title}</h2>
              <div className="profile mb-5">
                <span className="mr-2 avatar-name">
                  <span className="mr-2">BY</span>
                  {blog.users.name}
                </span>
                |{" "}
                <span className="text-secondary small">
                  {formatDate(blog.created_at)}
                </span>
              </div>
              <div className="mb-0 border-0 rounded-0">
                <img
                  src={blog.files[0]?.url}
                  className="w-100"
                  alt="Image"
                  style={{ height: "350px" }}
                />
                <div className="card-body px-1 mt-3">
                  {/* text-part-of-card  */}
                  {/* profile-and-avatar  */}
                    <p className="font-weight-light">
                      <DangerouslySetHtml contents={blog.body} />
                    </p>
                </div>
              </div>
            </Col>
            {/* side-bar-right */}
            <RecentPosts recentBlog={recentBlog} />
            {/* side-bar-right-ends  */}
          </Row>
        </Container>
      </div>
    </MainLayout>
  );
};

export default BlogDescription;
