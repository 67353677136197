import React from 'react'
import PropTypes from 'prop-types';

const SubmitButton = (props) => {
    const { isLoading, title , className , onClick , type="submit" } = props
    return (
        <button
            type={type}
            className={`btn btn-md btn-primary w-10 ${className}`}
            disabled={isLoading}
            onClick={onClick}
        >
            {!isLoading && (
                <span className="indicator-label">
                    {title}
                </span>
            )}
            {isLoading && (
                <span
                    className="indicator-progress"
                    style={{ display: "block" }}
                >
                    Please wait...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
            )}
        </button>
    )
}

export default SubmitButton

SubmitButton.defaultProps = {
    title: 'Sumbit'
}

SubmitButton.propTypes = {
    title: PropTypes.string,
    handleEvent: PropTypes.func
}