import React from "react";
import { Card, Row, Col } from "react-bootstrap";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { Link, useHistory } from "react-router-dom";
import clsx from "clsx";

import { useAuth } from "../../../hooks/useAuth";
import { authenticationService } from "../../../services/api/authentication";

import Navbar from "../../molecules/Navbar";
import { initialValues, registerSchema } from './registrationValidation';
// import SocialMediaLogin from "./SocialMediaLogin";
import '../../../assets/styles/register.css'

const UserRegister = () => {
  const history = useHistory();

  const { setAuth } = useAuth();

  const {
    mutate,
    isLoading,
  } = useMutation(authenticationService.handleRegister, {
    onSuccess: (data) => {
      setAuth(data?.data)
      history.push('/')
      toast.success('User registered successfully')
      document.location.reload();
    },
    onError: (err) => {
      toast.error(`${err?.response?.data?.error?.message}`)
    }
  });

  const formik = useFormik({
    initialValues,
    validationSchema: registerSchema,
    onSubmit: (values) => {
      mutate({ ...values })
    },
  });

  return (
    <div className="register">
      <Navbar title=".Travel" />
      <Card className="p-md-5 p-3">
        <div className="register-wrapper">
          <form
            className="form"
            onSubmit={formik.handleSubmit}
            noValidate
            id="kt_login_signin_form"
          >
            <Row>
              <Col md={6}>
                <div className="control-group">
                  <div className="controls">
                    <div className="my-2">
                      <input
                        placeholder="First name"
                        {...formik.getFieldProps("Firstname")}
                        className={clsx(
                          "form-control",
                          {
                            "is-invalid":
                              formik.touched.Firstname && formik.errors.Firstname,
                          }
                        )}
                        type="name"
                        name="Firstname"
                        autoComplete="off"
                      />
                      <div className="underline"></div>
                      {formik.touched.Firstname && formik.errors.Firstname && (
                        <div className="fv-plugins-message-container text-danger">
                          <span role="alert">{formik.errors.Firstname}</span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div className="control-group">
                  <div className="controls">
                    <div className="my-2">
                      <input
                        placeholder="Last name"
                        {...formik.getFieldProps("Lastname")}
                        className={clsx(
                          "form-control",
                          {
                            "is-invalid":
                              formik.touched.Lastname &&
                              formik.errors.Lastname,
                          }
                        )}
                        type="name"
                        name="Lastname"
                        autoComplete="off"
                      />
                      <div className="underline"></div>
                      {formik.touched.Lastname && formik.errors.Lastname && (
                        <div className="text-danger fv-plugins-message-container">
                          <span role="alert">{formik.errors.Lastname}</span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <div className="control-group">
                  <div className="controls">
                    <div className="my-2">
                      <input
                        placeholder="Email"
                        {...formik.getFieldProps("email")}
                        className={clsx(
                          "form-control",
                          {
                            "is-invalid":
                              formik.touched.email && formik.errors.email,
                          }
                        )}
                        type="email"
                        name="email"
                        autoComplete="off"
                      />
                      <div className="underline"></div>
                      {formik.touched.email && formik.errors.email && (
                        <div className="text-danger fv-plugins-message-container">
                          <span>{formik.errors.email}</span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div className="control-group">
                  <div className="controls">
                    <div className="my-2">
                      <input
                        placeholder="Phone Number"
                        {...formik.getFieldProps("phoneNumber")}
                        className={clsx(
                          "form-control",
                          {
                            "is-invalid":
                              formik.touched.phoneNumber && formik.errors.phoneNumber,
                          }
                        )}
                        type="number"
                        name="phoneNumber"
                        autoComplete="off"
                      />
                      <div className="underline"></div>
                      {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                        <div className="text-danger fv-plugins-message-container">
                          <span>{formik.errors.phoneNumber}</span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <div className="my-2">
              <input
                placeholder="Password"
                {...formik.getFieldProps("password")}
                className={clsx(
                  "form-control",
                  {
                    "is-invalid":
                      formik.touched.password && formik.errors.password,
                  }
                )}
                type="password"
                name="password"
                autoComplete="off"
              />
              <div className="underline"></div>
              {formik.touched.password && formik.errors.password && (
                <div className="text-danger fv-plugins-message-container">
                  <span role="alert">{formik.errors.password}</span>
                </div>
              )}
            </div>
            <div className="control-group">
              <div className="controls">
                <div className="my-2">
                  <input
                    placeholder="Password Confirmation"
                    {...formik.getFieldProps("password_confirmation")}
                    className={clsx(
                      "form-control",
                      {
                        "is-invalid":
                          formik.touched.password_confirmation &&
                          formik.errors.password_confirmation,
                      }
                    )}
                    type="password"
                    name="password_confirmation"
                    autoComplete="off"
                  />
                  <div className="underline"></div>
                  {formik.touched.password_confirmation &&
                    formik.errors.password_confirmation && (
                      <div className="text-danger fv-plugins-message-container">
                        <span role="alert">
                          {formik.errors.password_confirmation}
                        </span>
                      </div>
                    )}
                </div>
              </div>
            </div>
            <div className="form-check d-flex align-items-center my-3">
              <div>
                <input
                  {...formik.getFieldProps("newsletterSubscription")}
                  className={clsx(
                    "form-check-input",
                    {
                      "is-invalid":
                        formik.touched.newsletterSubscription && formik.errors.newsletterSubscription,
                    }
                  )}
                  type="checkbox"
                  id="newsletterSubscription"
                  name="newsletterSubscription"
                />
                <label
                  className="form-check-label text-white small"
                  htmlFor="newsletterSubscription"
                >
                  <span className="small">
                    Email me exclusive SuYatra promotions. I can opt out later as stated in the Privacy Policy.
                  </span>
                </label>
                {/* <label
                  className="form-check-label text-white"
                  htmlFor="exampleCheck1"
                >
                  I agree to the{" "}
                  <span to="/" className="text-primary">
                    terms an conditions.
                  </span>
                </label> */}
                {formik.touched.newsletterSubscription &&
                  formik.errors.newsletterSubscription && (
                    <div className="text-danger fv-plugins-message-container">
                      <span role="alert">
                        {formik.errors.newsletterSubscription}
                      </span>
                    </div>
                  )}
              </div>
            </div>
            <button
              type="submit"
              id="kt_sign_in_submit"
              className="btn btn-md btn-primary text-white w-100 my-4"
              disabled={isLoading}
            >
              {!isLoading && <span className="indicator-label">Register</span>}
              {isLoading && (
                <span
                  className="indicator-progress"
                  style={{ display: "block" }}
                >
                  Please wait...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
          </form>
          <div className="text-white text-center my-2">
            <span>Already Have An Account</span>
            <Link to="/user/login" className="text-primary ml-2">
              Sign in
            </Link>
          </div>
          <p className="text-light text-center small">
            By signing in, I agree to SuYatra's
            <Link to="/terms-condition" className="text-primary ml-2">
              Terms of Service and 
            </Link>
              <Link to="/privacy-disclaimer-user-agreement" className="text-primary mx-2">
              Privacy Statements
              </Link>
            and Suscribe to our transaction mail
          </p>
          {/* <div className="text-white text-center my-3">
            <a rel="noreferrer" href={`${process.env.REACT_APP_SUPPLIER_URL}} className="text-primary ml-2" target="_blank">
              Apply As a Supplier
            </a>
          </div> */}
        </div>
        {/* <SocialMediaLogin /> */}
      </Card>
    </div>
  );
};

export default UserRegister;