import React from "react";
import { Link, useHistory } from "react-router-dom";
import { useFormik } from "formik";
import clsx from "clsx";
import { FaUserAlt, FaLock } from 'react-icons/fa'

import { useAuth } from '../../../hooks/useAuth';

import { initialValues, loginSchema } from "../../validation/AuthValidation";
// import SocialMediaLogin from "./SocialMediaLogin";
import Navbar from '../../molecules/Navbar';

import "../../../assets/styles/login.css";
import { useHandleLogin } from "../../../hooks/useHandleLogin";

const UserLogin = () => {

  const history = useHistory();

  const { setAuth } = useAuth();

  const {
    mutate,
    isLoading,
  } = useHandleLogin();

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values) => {
      mutate({ ...values } , {
        onSuccess : (data) => {
          setAuth(data?.data)
          history.push('/')
          document.location.reload();
        }
      });
    },
  });

  return (
    <div className="login">
      <Navbar title=".Travel" />
      <div className="login-wrapper">
        <form
          className="form"
          onSubmit={formik.handleSubmit}
          noValidate
        >
          <div className="user-form">
            <span className="text-white">
              <FaUserAlt />
            </span>
            <input
              placeholder="Email or Phone number ...."
              {...formik.getFieldProps("userName")}
              className={clsx(
                "form-control",
                { "is-invalid": formik.touched.email && formik.errors.email },
              )}
              type="email"
              name="userName"
              autoComplete="false"
              required
            />
          </div>
          {formik.touched.userName && formik.errors.userName && (
            <div className="fv-plugins-message-container">
              <span role="alert" className="text-danger">
                {formik.errors.userName}
              </span>
            </div>
          )}
          <div className="my-4 password-wrapper">
            <div className="user-form">
              <span className="text-white">
                <FaLock />
              </span>
              <input
                placeholder="Password"
                {...formik.getFieldProps("password")}
                className={clsx(
                  "form-control",
                  {
                    "is-invalid":
                      formik.touched.password && formik.errors.password,
                  },
                )}
                type="password"
                name="password"
                autoComplete="off"
              />
            </div>
            {formik.touched.password && formik.errors.password && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert" className="text-danger">
                    {formik.errors.password}
                  </span>
                </div>
              </div>
            )}
          </div>
          <div className="form-check">
            <div className="float-right">
              <Link to="/forget-password" className="text-white">
                Forget Password
              </Link>
            </div>
          </div>
          <button
            type="submit"
            id="kt_sign_in_submit"
            className="btn btn-md p-2 btn-primary w-100 my-3"
            disabled={isLoading}
          >
            {!isLoading && <span className="indicator-label">Continue</span>}
            {isLoading && (
              <span className="indicator-progress" style={{ display: "block" }}>
                Please wait...
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
        </form>
        <div className="text-white text-center my-4">
          <span>Don't have an account?</span>
          <Link to="/user/register" className="text-primary ml-2">
            Sign up
          </Link>
        </div>
        {/* <SocialMediaLogin /> */}
      </div>
    </div>
  );
};

export default UserLogin;
