import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { BiMap } from 'react-icons/all';
import defaultImage from '../../../assets/images/logo.png'

// import Rating from "../../atoms/Rating";
import "../../../assets/styles/card.css";
import { Card } from "react-bootstrap";
import { searchParams } from "../../../_helpers/initialValues/searchParams";
// import Price from "../../atoms/Price";

const PropertyCard = (props) => {
  const {
    image,
    title,
    location,
    alt,
    // rating,
    item,
  } = props;

  const propertyParams = searchParams()

  return (
    <Link to={`/property/${item.slug}?${propertyParams}`}>
      <Card>
        <div className="card-img">
          <img src={image || defaultImage} alt={alt ? alt : "image"} />
        </div>
        <Card.Body>
          <div className="card-title mt-1">
            <div className=" justify-content-between align-items-center">
              <div className="d-flex align-items-center mb-3">
              {/* <span className="brand-name badge badge-pill badge-info pl-2">{item.brand_name}</span> */}
              <h4 className="text-capitalize mb-0" style={{flex : 1}}>{title}
              </h4>
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <div className="text-muted font-weight-light"> <BiMap /> {location}</div>
              {/* <div className="text-muted font-weight-light">
              {rating &&
                <Rating star={item?.average_rating} />
              }
              <br />
              ({item?.reviews_count} Reviews)</div>
            */}
            </div> 
            
          </div>
        </Card.Body>
        <Card.Footer className="bg-white pt-3 pb-3">
        <div className="starting-price text-muted">
          From
           <span className="font-weight-bold ml-2 text-dark h6">
          NPR {item.starting_price}
          </span>
          </div>
        </Card.Footer>
      </Card>
    </Link>
  );
};

export default PropertyCard;

PropertyCard.propTypes = {
  alt: PropTypes.string,
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  location: PropTypes.string.isRequired,
  websiteLink: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired
};

PropertyCard.defaultProps = {
  websiteLink: '',
  location: ''
};