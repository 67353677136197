import axios from 'axios';
import { toast } from 'react-toastify';
import { getSession } from '../../_helpers/authCrud';

// axios instance for making requests 
const session = getSession();

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  headers: {
    "Content-Type": "application/json"
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    if (session) {
      const { token } = session
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use((res) => {
  return res;
}, async (err) => {
  if (err.response.status === 401) {
    toast.error(`User expired`)
    localStorage.removeItem('session')
    window.location.reload()
  }
  return Promise.reject(err);
}
);

export default axiosInstance;


