import React from 'react'
import { BsFillCheckCircleFill } from 'react-icons/bs';
import PropType from "prop-types";

export const PaymentMethodsWalletItem = (props) => {
    const { handleSelection, isSelected, featureName , logo , showTitle , code } = props;
  
    return (
        <button
          className={`btn btn-white mb-2 position-relative`}
          onClick={() => handleSelection(code)}
        >
          <img
            src={logo}
            className={`w-100`}
            alt={featureName}
          />
          {isSelected && (
            <BsFillCheckCircleFill size={20} className="selected-wallet-checkbox" />
          )}
          <p className="small mt-1 text-muted">{showTitle && featureName}</p>
        </button>
    );
  };

  PaymentMethodsWalletItem.prototype = {
    isSelected: PropType.string.bool,
    featureName : PropType.string.isRequired,
    handleSelection: PropType.func.isRequired,
  };
  