import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { newsletterServices } from "../services/api/newsletter";

export const useGuestNewsletterSubscribe = () => {
   return useMutation(newsletterServices.postNewsletter , {
        onSuccess : () => {
          toast.success(`Subscription Successfull` , {
            position : "bottom-right"
          })
        },
        onError : (error) => {
          toast.error(`${error.response.data.error.message}` , {
            position : "bottom-right"
          })
        }
      });
}