import moment from "moment"
import { createContext, useState } from "react"

import { getPropertyData, setPropertySearchInLocalStorage } from '../_helpers/propertySearchCrud'

const today = new Date();
const tomorrow = moment(today).add(1, 'days').format('YYYY/MM/DD');

const initialState = {
    where: '',
    adult: 1,
    child: 0,
    startDate: today,
    endDate: tomorrow,
    rooms: 1,
    totalAmount: 0
};

export const SearchProperty = createContext(initialState);

export const SearchPropertyContext = ({ children }) => {

    const propertySearchData = getPropertyData();

    const [searchProperty, setSearchProperty] = useState(propertySearchData || initialState);

    const handlePropertySearch = (data) => {
        setSearchProperty(data);
        setPropertySearchInLocalStorage(data);
    };

    return (
        <SearchProperty.Provider value={{ searchProperty, handlePropertySearch }}>
            {children}
        </SearchProperty.Provider>
    )
}