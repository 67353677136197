export const data = [
    {
        id: 1,
        title: "Become a supplier",
        link: "/supplier/home"
    },
    {
        id: 3,
        title: "Contact us",
        link: "/contact-us"
    },
    {
      id: 4,
      title: "About Us",
      link: "/about"
    },
    // {
    //   id: 5,
    //   title: "Services",
    //   link: "/services"
    // },
    // {
    //   id: 6,
    //   title: "Blog",
    //   link: "/blog"
    // },
    {
        id: 7,
        title: "Terms and conditions",
        link: "/terms-condition"
    },
    // {
    //     id: 8,
    //     title: "Cookies and privacy",
    //     link: "/privacy-cookies"
    // },
    // {
    //     id: 9,
    //     title: "About us",
    //     link: "/about"
    // },
    {
        id:10,
        title:"FAQ",
        link:"/faq"
    }
];