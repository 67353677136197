import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import MainHeading from "../atoms/MainHeading";
import { mockData } from "../../utils/mock/data";
import PropertyCard from "../molecules/cards/Propertycard";

import { useProperties } from "../../hooks/reactQueryCustomHook/property/useGetProperties";
import Error from "../pages/Error";
import SkeletonCard from "../molecules/cards/SkeletonCard";

const PopularHotels = () => {
  const {
    data: popularHotels,
    isLoading,
    isError,
    error,
  } = useProperties("popular");

  if (isError) {
    return <Error error={error} />;
  }

  return (
    <section className="section popular-hotels">
      <Container>
        <MainHeading
          text={mockData.sectionHeader.popularHotels.title}
          para={mockData.sectionHeader.popularHotels.subTitle}
        />
        <Row>
          {isLoading ? (
            <SkeletonCard count={3} />
          ) : (
            popularHotels?.map((item) => (
              <Col lg={4} md={6} key={item?.id}>
                <PropertyCard
                  title={item.title}
                  isLoading={isLoading}
                  image={item?.cover_image?.url}
                  location={`${item?.city}`}
                  displayButton={false}
                  displayFooter={false}
                  rating={true}
                  item={item}
                  alt={item?.thumbnail?.original_name}
                  link="property"
                ></PropertyCard>
              </Col>
            ))
          )}
        </Row>
      </Container>
    </section>
  );
};

export default PopularHotels;
