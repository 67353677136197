import { useMutation } from "react-query"
import { toast } from "react-toastify"
import { subscriptionsServices } from "../services/api/subscriptions"

export const useUnsubscribe = () => {
    return useMutation(subscriptionsServices.unSuscribe , {
            onSuccess : (data) => {
                toast.success(data.data.message)
            },
            onError : (error) => {
                toast.error(`${error?.response?.data?.error?.message || "Please try again later."}`)
            }
    })
}