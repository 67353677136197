import axios from "../../setup/axios/SetupAxios";

const user = async () => {
    const { data } = await axios.get(`/user/profile`)
    return data;
};

const createProfile = ({ ...value }) => {
    const response = axios.put(
        `/user/profile`,
        {
            name: `${value.Firstname} ${value.Lastname}`,
            city: value.address,
            phone: value.phone,
            country: "Nepal",
            state: value.state,
            address: value.address,
            date_of_birth: value.DOB,
        },
    );
    return response
};


/****************************************************
Phone Verification
 ****************************************************/
const phoneVerification = async (phone) => {
    const response = await axios.post(`/phone-verification`, { phone: Number(phone) })
    return response

};

const verificationCode = async ({ code, phone }) => {
    const response = await axios.post(`/phone-verification/check`, { code, phone })
    return response
}

const khaltiPayout = async ({ token, amount, bookingReference }) => {
    return axios.post(`/payment/verification/${bookingReference}`, {
        token,
        amount
    }
    )
}

const couponCode = async ({ couponCode, bookingReference }) => {
    const response = await axios.get(`/coupon/check/${bookingReference}?coupon_code=${couponCode}`)
    return response
}

export const userServices = {
    user,
    createProfile,
    phoneVerification,
    verificationCode,
    khaltiPayout,
    couponCode
}