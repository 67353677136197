import React from "react";
import { Link } from "react-router-dom";
import { BiMap } from "react-icons/bi";
import { AiOutlineTags } from "react-icons/ai";

import { Badge } from "@mantine/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { formattedPrice } from "../../_helpers/formattedPrice";

export function PackageCard(props) {
  const { link, item, image, title, location, type, price, offerPrice, grid } =
    props;
  return (
    <div className="horizontal-card card mb-2">
      <Link to={`/${link}/${item?.slug}`}>
        <div className={`${grid !== "vertical" && "row no-gutters"}`}>
          <div className={`${grid !== "vertical" && "col-md-4 p-2 m-0"}`}>
            <img
              className="bd-placeholder-img rounded"
              width="100%"
              height="100%"
              src={image}
              alt={item?.title}
              style={{ aspectRatio: "2 / 1" }}
            />
          </div>
          <div className={`${grid !== "vertical" && "col-md-8"}`}>
            <div className="card-body p-3">
              {/* <div className="float-right">
                <div className="text-right">
                  <>
                    <Rating star={item?.average_rating} />
                  </>
                  <br />
                  <span className="small font-weight-light ml-3 text-muted">
                    <>({item?.reviews_count} Reviews)</>
                  </span>
                </div>
              </div> */}
              <div className="card-title text-left">
                <Badge
                  variant="gradient"
                  gradient={{ from: "teal", to: "lime", deg: 105 }}
                  className="mb-3"
                >
                  {type}
                </Badge>
                <h5 className="mb-0 text-capitalize font-weight-light">
                  {title}
                </h5>
              </div>
              <div className="card-description text-left">
                <span className="font-weight-light text-capitalize float-right">
                  <>
                    <BiMap className="mr-1" />
                    {location}
                  </>
                </span>
                {/* {isFetching ? <Skeleton width={70} /> : children} */}
                <div className="mt-3">
                  <>
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="text-success mr-2"
                    />
                    <span className="text-capitalize">Free cancellation</span>
                    <br />
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="text-success mr-2"
                    />
                    <span className="text-capitalize">Fully refundable</span>
                    {item.campaign_name && (
                      <p className="small-text fw-600 mt-2 mb-0 text-info">
                        <AiOutlineTags className="mr-2" size={20} />
                        Deals : {item.campaign_name}
                      </p>
                    )}
                  </>
                </div>
              </div>
              <p className="float-right text-right">
                <span className="small">From</span>
                <br />
                {offerPrice ? (
                  <>
                    <span className="font-weight-light strike-price small mr-2">
                      ({formattedPrice(price)})
                    </span>
                    <span className="h5 fw-500">{formattedPrice(offerPrice)}</span>
                  </>
                ) : (
                  <span className="h5 fw-500">{formattedPrice(price)}</span>
                )}
                <br />
                <span className="small">per person</span>
              </p>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}
