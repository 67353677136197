import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Col, Row } from "react-bootstrap";

import Header from "../../organisms/Header";
import Results from "../../atoms/Results";
import Footer from "../../molecules/Footer";
import { PackageCard } from "../../molecules/PackageCard";
import SearchBox from "./SearchBox";
import Error from "../Error";
import PaginationNew from "../../molecules/Pagination";
import { HashLoader } from "react-spinners";
import { useFilter } from "../../../hooks/useFilter";
import { useOrientation } from "../../../hooks/useOrientation";
import { useCounter } from "../../../hooks/useCounter";
import { usePackageAvaibility } from "../../../hooks/usePackageAvaibility";
import NoResults from "../../atoms/NoResults";

const PackageSearchPage = () => {
  const location = useLocation();
  const { sortType, handleSort, isOffer, handleIsOffer } = useFilter();
  const { grid, clickOrientation } = useOrientation();
  const [page, setPage] = useState(1);
  const {
    search: where,
    count: total,
    latitude,
    longitude,
    address,
    campaignCode,
  } = location?.state || {};
  const { count, setCount } = useCounter(total || 1);
  const {
    data: packages,
    isFetching,
    isLoading,
    isError,
    refetch,
  } = usePackageAvaibility({
    where,
    total,
    sortType,
    latitude,
    longitude,
    address,
    isOffer,
    campaignCode,
    page,
  });

  if (isError) return <Error />;

  return (
    <section className="listing-blog bg-light">
      <Header />
      <div className="container mb-5 pb-5">
        <Row>
          <Col md="4">
            <SearchBox
              search={where}
              count={count}
              setCount={setCount}
              latitude={latitude}
              longitude={longitude}
              address={address}
              refetch={refetch}
            />
          </Col>
          <Col md="8">
            <Results
              type="Package"
              sortType={sortType}
              refetch={refetch}
              handleSort={handleSort}
              grid={grid}
              handleIsOffer={handleIsOffer}
              clickOrientation={clickOrientation}
            ></Results>
            {isLoading || isFetching ? (
              <div className="d-flex justify-content-center align-items-center h-100">
                <HashLoader color="#AC77EF" />
              </div>
            ) : (
              <>
              {/* pacakage's card */}
                {packages?.data?.length > 0 ? (
                  <div className="text-center">
                      <Row>
                        {packages?.data?.map((item) => (
                          <Col md={`${grid === "vertical" ? 6 : 12}`} key={item.id}>
                            <PackageCard
                              key={item?.id}
                              offerPrice={item.offer_price}
                              type={item?.category}
                              title={item.title}
                              image={item?.thumbnail?.url}
                              location={`${
                                item?.location?.address?.city ||
                                item?.location?.address?.county ||
                                item?.location?.address?.region 
                              }`}
                              displayButton={true}
                              displayFooter={true}
                              rating={true}
                              item={item}
                              website={item?.company?.company_details?.website.slice(
                                0,
                                25
                              )}
                              number={
                                item?.company?.company_details?.company_phone
                              }
                              alt={item?.thumbnail?.original_name}
                              link="package"
                              websiteLink={
                                item?.company?.company_details?.website
                              }
                              price={item?.unit_price}
                              isFetching={isFetching}
                              grid={grid}
                            >
                              {/* {item.additional_information.substring(0, 50)} */}
                            </PackageCard>
                          </Col>
                        ))}
                      </Row>
                    <PaginationNew
                      page={page}
                      lastPage={packages.last_page}
                      setPage={setPage}
                    />
                  </div>
                ) : (
                  <NoResults />
                )}
              </>
            )}
          </Col>
        </Row>
      </div>
      <Footer />
    </section>
  );
};

export default PackageSearchPage;
