import React from "react";
import Header from "../organisms/Header";
import "../../assets/styles/faq.css";

import Footer from "../molecules/Footer";
import { Title, Accordion, Grid } from "@mantine/core";

const FAQ = () => {
  return (
    <>
      <div className="faq mb-5 bg-light pb-5">
        <Header />
        <section className="mt-5">
          <div className="container">
            <h1 className="faq-title">Frequently Asked Questions</h1>
            <Grid>
              <Grid.Col md={6}>
                <h2 className="my-5 faq-heading">
                  Troubleshooting FAQs for SuYatra
                </h2>
                <Accordion
                  classNames={{
                    item: "accordion-item",
                    control:"accordion-control",
                    label:"accordion-label"

                  }}
                >
                  <Accordion.Item label="Do my browser’s privacy settings affect my Suyatra.com.np experience?">
                    Yes, browser privacy settings can affect your experience on{" "}
                    <a href={"https://www.suyatra.com.np"}>Suyatra.com.np</a> as
                    they can block or allow cookies and trackers that may be
                    necessary for certain functionalities.
                  </Accordion.Item>

                  <Accordion.Item label="Can I book on SuYatra if I’m using Mac OS X?">
                    Generally, you can book on SuYatra using any operating
                    system, including Mac OS X, as long as the browser you are
                    using is compatible with the website.
                  </Accordion.Item>

                  <Accordion.Item label="Some of the stuff I’m seeing looks a little bit old?">
                    This could be due to caching issues where your browser
                    stores old data. Try clearing your browser cache or using
                    incognito mode to see the most updated content.
                  </Accordion.Item>

                  <Accordion.Item label="I’m getting an Expired Certificate warning.">
                    An expired certificate warning indicates that the SSL
                    certificate of the website has expired. It&#39;s best not to
                    proceed with sensitive transactions until this is resolved.
                  </Accordion.Item>

                  <Accordion.Item label="The SuYatra website’s moving slower than mud. What gives?">
                    Slow website performance can be due to various factors such
                    as server delays, heavy images, or inefficient code. Contact
                    SuYatra support for assistance and check your internet
                    connection as well.
                  </Accordion.Item>

                  <Accordion.Item label="What browser works best with this site?">
                    The best browser for any site is subjective and depends on
                    the site&#39;s optimization. However, popular browsers like
                    Chrome, Firefox, and Safari are commonly used for their
                    compatibility and features.
                  </Accordion.Item>

                  <Accordion.Item label="What do I do if my booking is not completed?">
                    If your booking on SuYatra is not completed, check your
                    internet connection, update your browser, clear
                    cache/cookies, or try a different browser. If the issue
                    persists, contact SuYatra customer support for assistance.
                  </Accordion.Item>

                  <Accordion.Item label="Does SuYatra’s site use cookies?">
                    Most websites, including online travel agencies like
                    SuYatra, use cookies to enhance user experience by
                    remembering user preferences and for analytical purposes.
                  </Accordion.Item>
                </Accordion>
              </Grid.Col>
              <Grid.Col md={6}>
                <h2 className="my-5 faq-heading">
                  FAQs for SuYatra Holiday Packages
                </h2>

                <Accordion
                  classNames={{
                    item: "accordion-item",
                    control:"accordion-control",
                    label:"accordion-label"

                  }}
                >
                  <Accordion.Item label="What types of holiday packages does SuYatra offer?">
                    SuYatra offers a diverse range of holiday packages that
                    allow you to explore the natural beauty, cultural heritage,
                    and adventure activities available throughout Nepal.
                  </Accordion.Item>

                  <Accordion.Item label="Are the holiday packages suitable for all types of travelers?">
                    Yes, our holiday packages cater to various preferences,
                    whether you’re a solo traveler, a couple, a family, or a
                    group of friends looking for an unforgettable experience in
                    Nepal.
                  </Accordion.Item>

                  <Accordion.Item label="How can I book a holiday package with SuYatra?">
                    Booking a holiday package is easy and convenient. Simply
                    visit our website, choose your desired package, and follow
                    the steps to complete your booking online.
                  </Accordion.Item>

                  <Accordion.Item label="Can I customize my holiday package?">
                    We understand that every traveler is unique. Contact our
                    customer support to discuss customization options that can
                    tailor your holiday package to your specific needs.
                  </Accordion.Item>

                  <Accordion.Item label="What are the payment options for booking a package?">
                    We accept{" "}
                    <b>
                      {" "}
                      all major credit cards, local bank transfers, connectIPS,
                    </b>{" "}
                    and
                    <b> all the wallets available in Nepal</b> for payment.
                  </Accordion.Item>

                  <Accordion.Item label="Are there any special deals or discounts on holiday packages?">
                    Yes, we frequently offer special deals and discounts. Please
                    check our website regularly or subscribe to our newsletter
                    to stay updated on the latest promotions.
                  </Accordion.Item>

                  <Accordion.Item label="What should I do if I need to cancel or modify my booking?">
                    For cancellations or modifications, please contact our
                    customer support with your{" "}
                    <b>SuYatra Booking Reference Code </b>. Our team will assist
                    you with the necessary changes.
                  </Accordion.Item>

                  <Accordion.Item label="How do I reach customer support for assistance?">
                    You can contact our customer support team at{" "}
                    <b> 00977 1 4537187</b> or via email at{" "}
                    <b>info@suyatra.com.np</b> for any inquiries or
                    assistance.
                  </Accordion.Item>

                  <Accordion.Item label="What currency are the prices listed in?">
                    All prices for our holiday packages are listed in{" "}
                    <b> Nepali Rupee (NPR).</b>
                  </Accordion.Item>

                  <Accordion.Item label="When we purchase the holiday packages, who will provide the services?">
                    All the services associated with our holiday packages are
                    provided by local suppliers. We partner with experienced and
                    trusted suppliers in each destination to ensure that you
                    have a memorable and hassle-free experience.
                  </Accordion.Item>

                  <Accordion.Item label="Is there a loyalty program for frequent travelers?">
                    We value our customers and are currently working on a
                    loyalty program to reward our frequent travelers. Stay tuned
                    for more information!
                  </Accordion.Item>
                </Accordion>
              </Grid.Col>
              <Grid.Col md={6}>
                <h2 className="my-5 faq-heading">
                  FAQs for SuYatra Hotel Booking Customers
                </h2>
                <Accordion
                  classNames={{
                    item: "accordion-item",
                    control:"accordion-control",
                    label:"accordion-label"

                  }}
                >
                  <Accordion.Item label="How can I book a hotel on SuYatra?">
                    You can book a hotel by visiting the SuYatra website,
                    searching for hotels in your desired location, selecting a
                    hotel, choosing your room type, and completing the booking
                    process with your personal details.
                  </Accordion.Item>

                  <Accordion.Item label="What payment options are available on SuYatra?">
                    SuYatra accepts all major credit cards, online banking
                    transfers, connectIPS, and wallets (eSewa, FonePay & more)
                    available in Nepal.
                  </Accordion.Item>

                  <Accordion.Item label="Are the prices shown on SuYatra in Nepalese Rupees (NPR)?">
                    Yes, all prices on SuYatra are displayed in Nepalese Rupees
                    (NPR) to cater to the local market.
                  </Accordion.Item>

                  <Accordion.Item label="Can international travelers use SuYatra to book hotels in Nepal?">
                    No, SuYatra is designed exclusively to promote domestic
                    tourism within Nepal. The platform offers special rates and
                    services that are available only for Nepalese citizens. As
                    such, international travelers would not be able to use
                    SuYatra for booking hotels in Nepal.
                  </Accordion.Item>

                  <Accordion.Item label="Is it safe to make payments on SuYatra?">
                    Yes, SuYatra uses secure payment gateways to ensure that all
                    transactions are safe and your financial information is
                    protected.
                  </Accordion.Item>

                  <Accordion.Item label="Can I cancel or modify my hotel booking on SuYatra?">
                    Yes, you can cancel or modify your hotel booking on SuYatra.
                    For any manual changes, please contact our customer support
                    directly by email or phone, and provide your SuYatra Booking
                    Reference Code. Our team will assist you with the necessary
                    adjustments according to the specific hotel&#39;s
                    cancellation policy, which you can review on the booking
                    confirmation page.
                  </Accordion.Item>

                  <Accordion.Item label="Does SuYatra offer customer support?">
                    Yes, SuYatra offers customer support. You can reach out to
                    our customer service team via email, phone, or live chat for
                    any assistance.
                  </Accordion.Item>

                  <Accordion.Item label="How do I know my hotel booking is confirmed?">
                    After completing the payment, you will receive a booking
                    confirmation via email with all the details of your
                    reservation.
                  </Accordion.Item>

                  <Accordion.Item label="Are there any hidden fees when booking on SuYatra?">
                    No, there are no hidden fees. The price you see is the price
                    you pay.
                  </Accordion.Item>
                </Accordion>
              </Grid.Col>
              <Grid.Col md={6}>
                <h2 className="my-5 faq-heading">
                  FAQs for SuYatra Registrations &amp; Sign-in:
                </h2>
                <Accordion
                  classNames={{
                    item: "accordion-item",
                    control:"accordion-control",
                    label:"accordion-label"

                  }}
                >
                  <Accordion.Item label="Do I need to create an account to use SuYatra?">
                    Registration is not required to search or book holiday
                    packages and hotels on SuYatra. However, for frequent
                    travelers who appreciate our services, creating an account
                    can streamline the booking process by saving your details
                    for future use. Additionally, registered users have the
                    exclusive opportunity to receive outstanding travel deals
                    via email.
                  </Accordion.Item>

                  <Accordion.Item label="How can I create an account with SuYatra?">
                    Creating an account with SuYatra is straightforward. Simply
                    select the &#39;Register&#39; option located at the top
                    right corner of the page and provide your email address. We
                    will promptly send you an account activation link along with
                    a One-Time Password (OTP) to your mobile number. Follow the
                    link to activate your account, and enjoy the benefits of
                    being a SuYatra member.
                  </Accordion.Item>

                  <Accordion.Item label="Are there any benefits of registering with SuYatra?">
                    Yes, registered users can enjoy faster bookings by saving
                    their information and receiving exclusive travel deals and
                    offers via email.
                  </Accordion.Item>

                  <Accordion.Item label="How do I change my password?">
                    To change your password, log in to your account, navigate to
                    the account settings, and select the option to change your
                    password. If you encounter any issues, contact SuYatra&#39;s
                    customer support for assistance.
                  </Accordion.Item>

                  <Accordion.Item label="I’ve forgotten my password. What do I do?">
                    If you&#39;ve forgotten your password, use the &#39;Forgot
                    Password&#39; feature on the login page. You will be
                    prompted to enter your email address to receive instructions
                    on how to reset your password.
                  </Accordion.Item>

                  <Accordion.Item label="What if my password is not recognized?">
                    If your password is not recognized, double-check that you
                    are entering it correctly. Ensure caps lock is off and that
                    you are using the correct email address. If the problem
                    persists, use the &#39;Forgot Password&#39; feature or
                    contact customer support for help⁹.
                  </Accordion.Item>

                  <Accordion.Item label="How do I edit my account information?">
                    To edit your account information, log in to your SuYatra
                    account, go to account settings, and choose the option to
                    edit your profile. Here you can update your personal details
                    as needed.
                  </Accordion.Item>

                  <Accordion.Item label="How do I sync my SuYatra travel calendar with my personal calendar app?">
                    Currently, there is no direct information available about
                    syncing the SuYatra travel calendar with personal calendar
                    apps. For specific instructions, please refer to the help
                    section on the SuYatra website or contact their customer
                    support.
                  </Accordion.Item>

                  <Accordion.Item label="How do I log out of SuYatra.com.np when I’m done?">
                    To log out of SuYatra.com.np, simply click on the &#39;Log
                    Out&#39; option, usually found at the top right corner of
                    the page or in the account settings menu.
                  </Accordion.Item>
                </Accordion>
              </Grid.Col>
            </Grid>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default FAQ;
