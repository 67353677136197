import moment from "moment";

import axios from "../../setup/axios/SetupAxios";

/**
 * User Room Routes
 */

export const getRoom = async (roomSlug, propertySlug, startDate, endDate , rooms) => {
  const { data } = await axios.get(`properties/${propertySlug}/rooms/${roomSlug}`, {
    params: {
      from_date: startDate,
      to_date: endDate,
      room_count : rooms
    }
  });
  return data;
};

export const getAvailableRooms = async ({ pageParam = 1, propertyId, startDate, endDate, adultCount, childCount }) => {
  const from = moment(startDate).format('YYYY-MM-DD');
  const to = moment(endDate).format('YYYY-MM-DD')
  const { data } = await axios.get(`/properties/${propertyId}/rooms/availabilities?page=${pageParam}&from=${from}&to=${to}&adult=${adultCount}&child=${childCount}`)
  return data
}

/**
 * User Review Routes
 */

export const postReviewPackages = async ({ slug, ...value }) => {
  const response = await axios.post(`/packages/${slug}/reviews`,
    {
      review: value.review,
      rating: value.rating,
    },
  );
  return response;
};

export const postReviewRoom = async ({ id, ...value }) => {
  const response = await axios.post(`/rooms/${id}/reviews`,
    {
      review: value.review,
      rating: value.rating,
    },
  );
  return response;
};

export const postReview = async ({ slug, type, ...value }) => {
  const response = await axios.post(`/${type}s/${slug}/reviews`,
    {
      review: value.review,
      rating: value.rating,
    },
  );
  return response;
};


export const getVisitorCount = async (type) => {
  return await axios.get(`/visitor-count?type=${type}`)
}