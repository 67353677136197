import { STATUS } from "./constants/statusType";

export const isBookingCancellable = (singleBooking) => {
  // Check if the booking status is APPROVED
  const isStatusApproved = singleBooking.status === STATUS.APPROVED;

  // Check if the booking is paid or free
  const isPaidOrFree = singleBooking.is_paid || singleBooking.is_free;

  // Check if the booking is not cancelled, not completed, and has no refund
  const isNotCancelled = !singleBooking.cancelled_at;
  const isNotCompleted = !singleBooking.is_completed;
  const hasNoRefund = !singleBooking.refund_amount;

  // Determine if the booking is cancellable based on all conditions
  const bookingCancellable =
    isStatusApproved &&
    isPaidOrFree &&
    isNotCancelled &&
    isNotCompleted &&
    hasNoRefund;

  return bookingCancellable;
};
