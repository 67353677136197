import { Alert, Text } from "@mantine/core";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { IoAlertCircleOutline } from "react-icons/io5";
import { useQueryClient } from "react-query";
import { toast } from "react-toastify";

import { useUserSubscribe } from "../../hooks/useUserSubscribeNewsletter";
import { useUserUnsubscribe } from "../../hooks/useUserUnsubscribe";
import MantineCustomModal from "./modals/MantineCustomModal";

const NewsletterSubscription = ({ isNewsletterSuscribed }) => {
  const [show, setShow] = useState(false);
  const suscribeMutation = useUserSubscribe();
  const unsubscribeMutation = useUserUnsubscribe();
  const queryClient = useQueryClient();

  const handleSubscribtion = () => {
    isNewsletterSuscribed
      ? unsubscribeMutation.mutate("", {
          onSuccess: () => {
            toast.success(`Newsletter unsubscribed successfully`);
            setShow(false);
            queryClient.invalidateQueries(["user"]);
          },
        })
      : suscribeMutation.mutate("", {
          onSuccess: () => {
            toast.success(`Newsletter subscribed successfully`);
            setShow(false);
            queryClient.invalidateQueries(["user"]);
          },
        });
  };

  return (
    <>
      <MantineCustomModal
        show={show}
        setShow={setShow}
        title={`${
          isNewsletterSuscribed
            ? "Unsubscribe to newsletter"
            : "Subscribe to newsletter"
        }`}
      >
        <hr />
        <h6>
          Do you want to {isNewsletterSuscribed ? "unsubscribe" : "subscribe"}{" "}
          the newsletter ?
        </h6>
        {
          isNewsletterSuscribed && (
          <Text className="pt-3 text-justify">
            <Alert icon={<IoAlertCircleOutline />}>
                 {/* ? Subscribe to receive personalized travel recommendations and insider tips. Our team of experts will provide you with suggestions based on your interests and preferences, so you can plan the perfect vacation , Additionally, subscribing to the newsletter will keep you informed about the latest industry news and trends, and you'll be the first to hear about new properties and destinations. This can be incredibly useful if you're a frequent traveler and want to stay up-to-date on what's happening in the hotel and travel industry." */}
                By unsubscribing you will no longer receive exclusive deals and promotions that are only available to newsletter subscribers , you may miss out on discounted prices for future vacations or special offers that may not be available to the general public.will no longer be informed about events and activities happening at our properties, so you will not be able to plan their trip accordingly. They also will not receive customer testimonials and success stories, which can give them an idea of what other travelers have experienced when you've stayed with us.
            </Alert>
          </Text>
          )
        }
        <hr />
        <div className="float-right">
          <Button
            variant="primary"
            size="md"
            onClick={handleSubscribtion}
            disabled={
              suscribeMutation.isLoading || unsubscribeMutation.isLoading
            }
          >
            {isNewsletterSuscribed ? "Cancel Subscribtion" : "Subscribe"}
          </Button>
        </div>
      </MantineCustomModal>
      <Button className="ml-2" variant={`${!isNewsletterSuscribed ? 'btn btn-sm rounded-pill btn-outline-primary' : 'btn-outline-secondary'}`} size="sm" onClick={() => setShow(true)}>
        {isNewsletterSuscribed
          ? "(Unsubscribe)"
          : "Subscribe"}
      </Button>
    </>
  );
};

export default NewsletterSubscription;
