// customized axios values
import axios from "../../setup/axios/SetupAxios";

/**
 * User Property Routes
 */

const getProperties = async (type) => {
    const { data } = await axios.get(`/properties?type=${type}`);
    return data;
};

const getProperty = async (slug) => {
    const { data } = await axios.get(`/properties/${slug}`)
    return data
};

const getPopularProperties = async () => {
    const { data } = await axios.get(`/properties?type=popular`)
    return data
};

const getFeaturedProperties = async () => {
    return await axios.get(`/properties?type=featured`)
};

const getHotPropertyDeals = async () => {
    const { data } = await axios.get(`/properties?type=hot_deals`)
    return data
};


export const propertyServices = {
    getProperties,
    getProperty,
    getPopularProperties,
    getFeaturedProperties,
    getHotPropertyDeals,
};