import { React, useState } from "react";
import { Card } from "react-bootstrap";
import { Tabs } from "@mantine/core";

import { useBookings } from "../../../hooks/useBookings";

import BookingCard from "../../molecules/BookingCard";
import { categoryType } from "../../../utils/categoryType";
import { FallBackView } from "../FallBackView";
import Error from "../Error";
import "../../../assets/styles/pagination.css";
import "../../../assets/styles/bookings.css";
import PaginationNew from "../../molecules/Pagination";

import DashboardLayout from "../../../layout/DashboardLayout";

const DashboardBookings = () => {
  const [page, setPage] = useState(1);
  const [status, setStatus] = useState("");
  const {
    data: bookings,
    isLoading,
    isFetching,
    isError,
  } = useBookings({ page, status });
  const onChange = (active, tabKey) => {
    setStatus(tabKey);
    setPage(1)
  };

  if (isLoading) {
    return <FallBackView />;
  }

  if (isError) {
    return <Error />;
  }

  const bookingTabs = [
    // {
    //   label: "Pending",
    //   count: bookings?.booking_count?.unpaid,
    //   tabKey: "unpaid",
    // },
    {
      label: "All Bookings",
      count: bookings?.booking_count.total,
      tabKey: "",
    },
    {
      label: "Upcoming",
      count: bookings?.booking_count?.upcoming,
      tabKey: "upcoming",
    },
    {
      label: "Completed",
      count: bookings?.booking_count?.completed,
      tabKey: "completed",
    },
    {
      label: "Cancelled",
      count: bookings?.booking_count?.cancelled,
      tabKey: "cancelled",
    },
    // {
    //   label: "Refundend",
    //   count: bookings?.booking_count?.refunded,
    //   tabKey: "refunded",
    // },
  ];

  return (
    <DashboardLayout>
      <Card>
        <Card.Body>
          <Tabs
            active={status}
            onTabChange={onChange}
            className="text-capitalize"
          >
            {bookingTabs?.map((booking, index) => (
              <Tabs.Tab
                label={`${booking.label} (${booking?.count})`}
                tabKey={booking.tabKey}
                key={index}
              >
                {booking?.count ? (
                  <>
                    {bookings?.bookings?.data?.map((booking) => (
                      <BookingCard
                        key={booking?.id}
                        booking={booking}
                        isFetching={isFetching}
                        type={categoryType(booking)}
                      />
                    ))}
                    <PaginationNew
                      key={page}
                      page={page}
                      setPage={setPage}
                      lastPage={bookings?.bookings?.last_page}
                    />
                  </>
                ) : (
                  `${booking.label} Booking Not Available !`
                )}
              </Tabs.Tab>
            ))}
          </Tabs>
        </Card.Body>
      </Card>
    </DashboardLayout>
  );
};

export default DashboardBookings;
