import React from 'react'
import StarRatings from 'react-star-ratings'

const Rating = (props) => {
    const { star } = props
    return (
        <>
            {
                star === null ? null :
                    <StarRatings
                        rating={Number(star)}
                        starDimension="12px"
                        starSpacing="1px"
                        starRatedColor="#ffc107"
                        starEmptyColor="lightgrey"
                    />
            }
        </>
    )
}

export default Rating

// Rating.propTypes = {
//     ratings: PropT,
// }
