export const STATUS = {
    PENDING : 'pending',
    CANCELLATION_PENDING : 'cancellation_pending',
    CANCELLATION_APPROVED: 'cancellation_approved',
    CANCELLATION_REJECTED : 'cancellation_rejected',
    CANCELLED : 'cancelled',
    APPROVED : 'approved',
    REJECT : 'reject',
    PAYMENT_PENDING  : 'payment_pending',
    PAYMENT_FAILED : 'payment_failed',
    PAYMENT_VERIFICATION_PENDING : "payment_verification_pending",
    PAYMENT_VERIFICATION_FAILED : "payment_verification_failed",
}   