// modules
import React from "react";

//components
import Header from "../../organisms/Header";
import Feature from "../../organisms/Feature";
import Footer from "../../molecules/Footer";
import { Banner } from "../../organisms/Banner";
import PopularHotels from "../../organisms/PopularHotels";
import { Visitor } from "../../organisms/Visitor";
import BlogSection from "../../organisms/BlogSection";
import BestDeals from "../../organisms/BestDeals";

import { useProperties } from "../../../hooks/reactQueryCustomHook/property/useGetProperties";
import CampaignBanner from "../../organisms/CampaignBanner";
import { NewsLetter } from "../../organisms/NewsLetter";

const Home = () => {
  const { data: hotDeals, isLoading: isHotDealsLoading } =
    useProperties("hot_deals");

  return (
    <div className="property-homepage">
      <Header displayHero={true} />
      <CampaignBanner type="property" />
      <BestDeals
        data={hotDeals}
        isLoading={isHotDealsLoading}
        type="property"
      />
      <Banner />
      <PopularHotels />
      <Visitor />
       <Feature  />
      {/* <WhyChooseUsSection /> */}
      <BlogSection />
      <NewsLetter />
      <Footer />
    </div>
  );
};

export default Home;
