import { React } from "react";
import { useParams } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { Col } from "react-bootstrap";
import { useQuery } from "react-query";
import * as Yup from "yup";

import { bookingServices } from "../../../services/api/bookings";
import Header from "../../organisms/Header";
import BreadcrumbTop from '../../atoms/BreadcrumbTop'
import DashboardSideBar from "../../molecules/DashboardSideBar";
import { FallBackView } from "../FallBackView";
import Footer from "../../molecules/Footer";
import "../../../assets/styles/bookings.css";

const EditBookingSchema = Yup.object().shape({
  currency: Yup.string().required("currency is required"),
  price: Yup.number()
    .min(3, "Minimum 2 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Price is required"),
  starts_at: Yup.date().required("Start Date is required"),
  ends_at: Yup.date().required("End Dateis required"),
});

const EditBookings = () => {

  const params = useParams();

  const bookingsId = Number(params?.id);

  const { isLoading, isError } = useQuery(
    ["booking", { bookingsId }],
    bookingServices.getBooking
  );

  if (isLoading) return <FallBackView />;

  if (isError) return "Error";
  
  const onFormSubmit = async (value) => {
  };

  return (
    <>
      <div className="dashboard bookings">
        <Header />
        <div className="container">
          <BreadcrumbTop
            title="Dashboard"
            page="Bookings"
            active="EditBookings"
          />
          <div className="row">
            <div className="col-md-3">
              <DashboardSideBar active="active" />
            </div>
            <div className="col-md-9">
              <div className="card">
                <div className="card-body">
                  <h6 className="mb-2 text-muted">Edit Bookings</h6>
                  <hr />
                  <Formik
                    validationSchema={EditBookingSchema}
                    onSubmit={onFormSubmit}
                  >
                    {(setFieldValue) => (
                      <Form className="">
                        <div
                          className="form-group col"
                        // controlId="formGridCurrency"
                        >
                          <div className="text-danger">
                            <ErrorMessage name="Currency" />
                          </div>
                        </div>
                        <div
                          className="form-group col"
                        // controlId="formGridPrice"
                        >
                          <label>Price</label>
                          <Field
                            placeholder="Price"
                            type="number"
                            className="form-control"
                            name="price"
                            autoComplete="off"
                          />
                          <div className="text-danger">
                            <ErrorMessage name="Price" />
                          </div>
                        </div>
                        <div
                          className="form-group col"
                          as={Col}
                        // controlId="formGridStartsAt"
                        >
                          <label>Starts At</label>
                          <Field
                            placeholder="Enter Your Start Date"
                            type="datetime-local"
                            className="form-control"
                            name="starts_at"
                            autoComplete="off"
                          />
                          <div className="text-danger">
                            <ErrorMessage name="starts_at" />
                          </div>
                        </div>

                        <div
                          className="form-group col"
                        // controlId="formGridEndsAt"
                        >
                          <label>End Date</label>
                          <Field
                            placeholder="Enter End Date"
                            type="datetime-local"
                            name="ends_at"
                            className="form-control"
                            autoComplete="off"
                          />
                          <div className="text-danger">
                            <ErrorMessage name="ends_at" />
                          </div>
                        </div>
                        <button
                          type="submit"
                          id="kt_sign_in_submit"
                          className="btn btn-md btn-primary w-25 col"
                        //   disabled={isLoading}
                        >
                          {!isLoading && (
                            <span className="indicator-label">Update</span>
                          )}
                          {isLoading && (
                            <span
                              className="indicator-progress"
                              style={{ display: "block" }}
                            >
                              Please wait...
                              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                          )}
                          Continue
                        </button>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default EditBookings;
