import React from "react";
import moment from "moment";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { Link, useHistory } from "react-router-dom";
import { BiMap } from "react-icons/bi";
import { Badge } from "@mantine/core";
import { IoAlertCircleOutline } from "react-icons/io5";
import { AiOutlineTags } from "react-icons/ai";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useStringHook } from "../../hooks/useStringHook";
import { usePropertyQuery } from "../../hooks/usePropertyQuery";
import { formatDate } from "../../utils/formatdate";
import { bookingServices } from "../../services/api/bookings";

export function PropertyCard(props) {
  const { handlePropertySearch } = usePropertyQuery();
  const history = useHistory();
  const query = useStringHook();
  const where = query.get("search");
  const startDate = query.get("startDate");
  const endDate = query.get("endDate");
  const adult = query.get("adult");
  const child = query.get("child");
  const roomCount = query.get("rooms");
  const { item, image, title, location, children, grid } = props;
  const mutation = useMutation(bookingServices.bookProperty);

  const diff = moment(endDate).diff(moment(startDate), "days");

  const totalAmount = item.starting_price * roomCount * diff;

  const viewMore = (slug) => {
    handlePropertySearch({
      search: where,
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
      adult,
      child,
      rooms: roomCount,
    });
    history.push({
      pathname: `/property/${slug}`,
      search: `?search=${where}&startDate=${formatDate(
        startDate
      )}&endDate=${formatDate(
        endDate
      )}&adult=${adult}&rooms=${roomCount}&child=${child}`,
    });
  };
  const bookNow = (slug) => {
    handlePropertySearch({
      search: where,
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
      adult,
      child,
      rooms: roomCount,
      totalAmount,
    });
    mutation.mutate(
      {
        propertySlug: slug,
        adultCount: adult,
        childCount: child,
        roomCount,
        startDate,
        endDate,
      },
      {
        onSuccess: (data) => {
          if (data) {
            history.push(`/checkout/property/${slug}/room/${data.slug}`);
          } else {
            history.push({
              pathname: `/property/${slug}`,
              search: `?search=${where}&startDate=${formatDate(
                startDate
              )}&endDate=${formatDate(
                endDate
              )}&adult=${adult}&rooms=${roomCount}&child=${child}`,
            });
          }
        },
        onError: (error) => {
          toast.error(
            `${error.response.data.error.message}` ||
              "Server error!! please try again later"
          );
        },
      }
    );
  };

  return (
    <div className="horizontal-card card mb-5">
      <div className={`${grid !== "vertical" && "row no-gutters"}`}>
        <div className={`${grid !== "vertical" && "col-md-3 p-3"}`}>
          <img
            className="bd-placeholder-img rounded"
            width="100%"
            height="100%"
            src={image}
            alt={item?.title}
            style={{ aspectRatio: "2 / 1" }}
          />
        </div>
        <div className={`${grid !== "vertical" && "col-md-9"}`}>
          <div className="card-body p-3">
            {/* <div className="float-right">
              <div className="text-right">
                <Rating star={item?.average_rating} />
                <br />
                <span className="small font-weight-bold ml-3 text-muted">
                  <>({item?.reviews_count} Reviews)</>
                </span>
              </div>
            </div> */}
            <div className="card-title text-left">
              <Badge
                variant="gradient"
                gradient={{ from: "teal", to: "lime", deg: 105 }}
                className="mb-3"
              >
                {item?.brand_name}
              </Badge>
              <h5 className="mb-0 text-capitalize font-weight-light">
                <Link
                  to={{
                    pathname: `/property/${item.slug}`,
                    search: `?search=${where}&startDate=${startDate}&endDate=${endDate}&adult=${adult}&child=${child}&rooms=${roomCount}`,
                  }}
                >
                  {title && title?.slice(0, 40)}
                </Link>
              </h5>
            </div>
            <div className="card-description text-left mb-2">
              <span className="font-weight-light">
                <>
                  <BiMap className="mr-1" />
                  {`${location} , ${item?.country}`}
                </>
              </span>
              <div className="mt-4">
                {!item.availability ? (
                  <div className="text-danger d-flex align-items-center mb-4">
                    <IoAlertCircleOutline className="mr-2" />
                    <span className="text-danger">
                      The room is not available in the provided date.
                    </span>
                  </div>
                ) : (
                  children
                )}
              </div>
              {item.campaign_name && (
                <p className="small-text fw-600 mt-3 text-info">
                  <AiOutlineTags className="mr-2" size={20} />
                  Deals : {item.campaign_name}
                </p>
              )}
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <>
                  <span className="small">FROM</span>
                  {item.starting_price !== Number(item?.minimum_room_rate) ? (
                    <p className="h5">
                      <span className="small mr-1">
                        NPR
                        <strike className="px-1">
                          {item?.minimum_room_rate}
                        </strike>
                      </span>
                      {`${item?.starting_price?.toFixed(2) || 0}`}
                    </p>
                  ) : (
                    <p className="h5">{`${
                      item?.starting_price?.toFixed(2) || 0
                    }`}</p>
                  )}
                </>
              </div>
              <div className="float-right text-right">
                <button
                  type="submit"
                  className="btn btn-sm btn-primary"
                  disabled={!item.availability || mutation.isLoading}
                  onClick={() => bookNow(item.slug)}
                >
                  {!mutation.isLoading && (
                    <span className="indicator-label">
                      {item.availability ? (
                        <>
                          Book Now
                          <FontAwesomeIcon
                            icon={faChevronRight}
                            className="ml-2"
                          />
                        </>
                      ) : (
                        <>
                          <IoAlertCircleOutline /> Not available
                        </>
                      )}
                    </span>
                  )}
                  {mutation.isLoading && (
                    <span
                      className="indicator-progress"
                      style={{ display: "block" }}
                    >
                      Please wait...
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  )}
                </button>
                <div>
                  <button
                    className="btn btn-info btn-sm mt-2"
                    onClick={() => viewMore(item.slug)}
                  >
                    Check Availability
                    <FontAwesomeIcon icon={faChevronRight} className="ml-2" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
