import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

import Header from "../../organisms/Header";
import Footer from "../../molecules/Footer";
import { PropertyCard } from "../../organisms/PropertyCard";
import { FallBackView } from "../FallBackView";
import Results from "../../atoms/Results";
import StickySearchHeader from "../../organisms/StickySearchHeader";
import PaginationNew from "../../molecules/Pagination";

import { usePropertyAvaibility } from "../../../hooks/reactQueryCustomHook/property/usePropertyAvaibility";

import "../../../assets/styles/listingBlog.css";
import { useFilter } from "../../../hooks/useFilter";
import { useOrientation } from "../../../hooks/useOrientation";
import { usePropertyFilter } from "../../../hooks/usePropertyFilter";
import NoResults from "../../atoms/NoResults";
import SkeletonCard from "../../molecules/cards/SkeletonCard";

const PropertyList = () => {
  const [page, setPage] = useState(1);
  const { grid, clickOrientation } = useOrientation("vertical");
  const {
    startDate,
    endDate,
    adultCount,
    childCount,
    roomCount,
    latitude,
    longitude,
    address,
    searchBy,
    campaignCode,
  } = usePropertyFilter();
  const { sortType, handleSort, isOffer, handleIsOffer } = useFilter();
  const {
    data: property,
    isFetching,
    isLoading,
    refetch,
  } = usePropertyAvaibility({
    startDate,
    endDate,
    adultCount,
    childCount,
    roomCount,
    latitude,
    longitude,
    sortType,
    address,
    searchBy,
    isOffer,
    page,
    campaignCode,
  });

  if (isLoading) return <FallBackView />;

  const propertiesByName = property.properties_by_name;

  return (
    <section className="listing-blog bg-light">
      <Header />
      <StickySearchHeader refetch={refetch} />
      <Container className="mb-3">
        <Row>
          <Col md={12}>
            <Results
              type="Property"
              total={property?.properties_by_location?.data?.length}
              data={property}
              sortType={sortType}
              handleSort={handleSort}
              grid={grid}
              clickOrientation={clickOrientation}
              handleIsOffer={handleIsOffer}
            />
            {isLoading || isFetching ? (
              grid === "vertical" ? (
                <Row>
                  <SkeletonCard count={3} />
                </Row>
              ) : (
                <SkeletonCard count={3} type="horizontal-card" />
              )
            ) : (
              <>
                {property?.properties_by_name && (
                  <PropertyCard
                    title={propertiesByName?.title}
                    location={propertiesByName.city}
                    displayFooter={false}
                    displayButton={false}
                    rating={true}
                    item={propertiesByName}
                    image={
                      propertiesByName?.cover_image?.url ||
                      propertiesByName?.profile_image?.url
                    }
                    type={propertiesByName?.brand_name}
                    isFetching={isFetching}
                    link="property"
                  >
                    <ul>
                      <li>
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          className="text-success pr-2"
                        />
                        Health and safety measures
                      </li>
                      <li>
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          className="text-success pr-2"
                        />
                        Free cancellation
                      </li>
                    </ul>
                  </PropertyCard>
                )}
                {property?.properties_by_location?.data.length > 0 ? (
                  <>
                    <p className="mt-2">Popular hotels nearby :</p>
                    <Row>
                      {property?.properties_by_location?.data?.map((item) => (
                        <Col
                          md={`${grid === "vertical" ? 4 : 12}`}
                          key={item.id}
                        >
                          <PropertyCard
                            key={item?.id}
                            title={item?.title}
                            location={item.city}
                            displayFooter={false}
                            displayButton={false}
                            rating={true}
                            item={item}
                            image={
                              item?.cover_image?.url || item?.profile_image?.url
                            }
                            type={item?.brand_name}
                            isFetching={isFetching}
                            grid={grid}
                            link="property"
                          >
                            <ul>
                              <li>
                                <FontAwesomeIcon
                                  icon={faCheckCircle}
                                  className="text-success pr-2"
                                />
                                Health and safety measures
                              </li>
                              <li>
                                <FontAwesomeIcon
                                  icon={faCheckCircle}
                                  className="text-success pr-2"
                                />
                                Free cancellation
                              </li>
                            </ul>
                          </PropertyCard>
                        </Col>
                      ))}
                    </Row>
                    <PaginationNew
                      page={page}
                      setPage={setPage}
                      lastPage={property?.properties_by_location?.last_page}
                    />
                  </>
                ) : (
                  <div className="text-center">
                    <NoResults paragraph="We couldn't find any properties" />
                  </div>
                )}
              </>
            )}
          </Col>
        </Row>
      </Container>
      <Footer />
    </section>
  );
};

export default PropertyList;
