import React from 'react'
// import '../../../styles/user/Pagination.css'

const PaginationNew = ({ setPage , lastPage , page }) => {
    return (
        <div className='d-md-flex flex-stack flex-wrap pt-10 justify-content-between align-items-center'>
            <span className="text-muted text-lowercase">Showing results of page {page} out of {lastPage}</span>
            <ul className='pagination'>
                <li className='page-item previous'>
                    <button
                        className='page-link'
                        onClick={() => setPage((page) => page - 1)}
                        disabled={page === 1}
                    >
                        <i className='previous'></i>
                        Prev
                    </button>
                </li>
                <li className='page-item active'>
                    <button
                        className='page-link'
                    >
                        {page}
                    </button>
                </li>
                <li className='page-item next'>
                    <button
                        className='page-link'
                        onClick={() => setPage((page) => page + 1)}
                        disabled={page === lastPage}
                    >
                        <i className='next'></i>
                        Next
                    </button>
                </li>
            </ul>
        </div>
    )
}

export default PaginationNew
