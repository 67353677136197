import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

import MainLayout from "../../../layout/MasterLayout";
import StickySearchHeader from "../../organisms/StickySearchHeader";
import { useOrientation } from "../../../hooks/useOrientation";
import { useFilter } from "../../../hooks/useFilter";
import Results from "../../atoms/Results";
import { FallBackView } from "../FallBackView";
import { PropertyCard } from "../../organisms/PropertyCard";
import SkeletonCard from "../../molecules/cards/SkeletonCard";
import PaginationNew from "../../molecules/Pagination";
import NoResults from "../../atoms/NoResults";
import { formatDate } from "../../../utils/formatdate";
import moment from "moment";
import { usePropertyAvaibility } from "../../../hooks/reactQueryCustomHook/property/usePropertyAvaibility";

const ListByCities = () => {
  const [page, setPage] = useState(1);
  const query = useParams();
  const city = query.city;
  const { grid, clickOrientation } = useOrientation("horizontal");
  const { sortType, handleSort, isOffer , handleIsOffer } = useFilter();

  const {
    data: property,
    isFetching,
    isLoading,
    refetch,
  } = usePropertyAvaibility({
    startDate: formatDate(new Date()),
    endDate: moment(new Date(), "DD-MM-YYYY")
      .add(2, "days")
      .format("YYYY/MM/DD"),
    adultCount: 1,
    childCount: 0,
    roomCount: 1,
    sortType,
    address: city,
    searchBy: "city",
    isOffer,
    page,
  });

  if (isLoading) return <FallBackView />;

  const propertiesByName = property.properties_by_name;

  return (
    <MainLayout>
      <StickySearchHeader />
      <Container>
        <Results
          type="Property"
          total={property?.properties_by_location?.data?.length}
          data={property}
          sortType={sortType}
          handleSort={handleSort}
          grid={grid}
          clickOrientation={clickOrientation}
          handleIsOffer={handleIsOffer}
        />
        {isLoading || isFetching ? (
          grid === "vertical" ? (
            <Row>
              <SkeletonCard count={3} />
            </Row>
          ) : (
            <SkeletonCard count={3} type="horizontal-card" />
          )
        ) : (
          <>
            {property?.properties_by_name && (
              <PropertyCard
                title={propertiesByName?.title}
                location={propertiesByName.city}
                displayFooter={false}
                displayButton={false}
                rating={true}
                item={propertiesByName}
                image={
                  propertiesByName?.cover_image?.url ||
                  propertiesByName?.profile_image?.url
                }
                type={propertiesByName?.brand_name}
                isFetching={isFetching}
                link="property"
              >
                <ul>
                  <li>
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className="text-success pr-2"
                    />
                    Health and safety measures
                  </li>
                  <li>
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className="text-success pr-2"
                    />
                    Free cancellation
                  </li>
                </ul>
              </PropertyCard>
            )}
            {property?.properties_by_location?.data.length > 0 ? (
              <>
                <p className="mt-2">Popular hotels nearby :</p>
                <Row>
                  {property?.properties_by_location?.data?.map((item) => (
                    <Col md={`${grid === "vertical" ? 4 : 12}`} key={item.id}>
                      <PropertyCard
                        key={item?.id}
                        title={item?.title}
                        location={item.city}
                        displayFooter={false}
                        displayButton={false}
                        rating={true}
                        item={item}
                        image={
                          item?.cover_image?.url || item?.profile_image?.url
                        }
                        type={item?.brand_name}
                        isFetching={isFetching}
                        grid={grid}
                        link="property"
                      >
                        <ul>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              className="text-success pr-2"
                            />
                            Health and safety measures
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              className="text-success pr-2"
                            />
                            Free cancellation
                          </li>
                        </ul>
                      </PropertyCard>
                    </Col>
                  ))}
                </Row>
                <PaginationNew
                  page={page}
                  setPage={setPage}
                  lastPage={property?.properties_by_location?.last_page}
                />
              </>
            ) : (
              <div className="text-center">
                <NoResults paragraph="We couldn't find any properties" />
              </div>
            )}
          </>
        )}
      </Container>
    </MainLayout>
  );
};

export default ListByCities;
