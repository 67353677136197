import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSnowflake, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { Card } from 'react-bootstrap';
import DescriptionTitle from '../atoms/DescriptionTitle';

const TourActivities = () => {
    return (
        <Card>
            <Card.Body>
                <DescriptionTitle title="About this activities" />
                <hr />
                <div className="activities-wrapper">
                    {/* <p className='mb-0'>
                        <FontAwesomeIcon icon={faCalendarAlt} className="mr-4" />
                        Cancellation Policy
                    </p>
                    <ul className='mx-5'>
                        <li>Full refund if the cancellation is before 24 hours</li>
                    </ul> */}
                    <p className="m-0">
                        <FontAwesomeIcon icon={faSnowflake} className="mr-4" />
                        Covid Precaution </p>
                    <ul className='mx-5'>
                        <li>Special health and safety measures apply.</li>
                    </ul>

                </div>
            </Card.Body>
        </Card>
    )
}

export default TourActivities
