/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import { faClock, faUtensils } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";

import "../../../assets/styles/card.css";
import { usePropertyQuery } from "../../../hooks/usePropertyQuery";
import { useHistory, useParams } from "react-router-dom";
import { useStringHook } from "../../../hooks/useStringHook";
import { formatDate } from "../../../utils/formatdate";
import { IoAlertCircleOutline } from "react-icons/io5";
import { totalAvailableRoomCount } from "../../../_helpers/availableRoomCount";
import { formattedPrice } from "../../../_helpers/formattedPrice";

const RoomCard = (props) => {
  const {
    image,
    title,
    // location,
    alt,
    // displayButton,
    displayFooter,
    offerPrice,
    // website,
    // number,
    // websiteLink,
    rate,
    handleRoomDesc,
    item,
  } = props;

  const { searchProperty, handlePropertySearch } = usePropertyQuery();
  const totalAvailableCount = totalAvailableRoomCount(
    item.daily_availabilities
  );

  const { propertySlug } = useParams();
  const history = useHistory();
  const query = useStringHook();

  const where = query.get("search");
  const startDate = query.get("startDate");
  const endDate = query.get("endDate");
  const adult = query.get("adult");
  const child = query.get("child");
  const roomCount = query.get("rooms");

  const bookNow = (roomSlug) => {
    const diff = moment(endDate).diff(moment(startDate), "days");

    const totalAmount = item.offer_price
      ? item?.offer_price * searchProperty.rooms * diff
      : item?.room_rate * searchProperty.rooms * diff;

    handlePropertySearch({
      search: where,
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
      adult,
      child,
      rooms: roomCount,
      totalAmount,
    });

    history.push(`/checkout/property/${propertySlug}/room/${roomSlug}`);
  };

  return (
    <div className="card card-clickable">
      <a href="#" onClick={() => handleRoomDesc(props.item.slug)}>
        <div className="card-img">
          <img src={image} alt={alt ? alt : "image"} />
        </div>
      </a>
      <div className="card-body p-3">
        <div className="card-title">
          <a href="#" onClick={() => handleRoomDesc(props.item.slug)}>
            <h4 className="mb-0 text-capitalize">{title?.slice(0, 23)}..</h4>
          </a>
        </div>
        <div className="card-description text-muted">
          {item.is_breakfast_included && (
            <p className="text-success pb-0">
              <FontAwesomeIcon
                icon={faUtensils}
                className="text-success mr-2 small"
              />
              Breakfast included
            </p>
          )}
          {totalAvailableCount && (
            <span className={totalAvailableCount > 10 ? 'text-success' : 'text-danger'}>
              <FontAwesomeIcon icon={faClock} className="mr-1" />
              {totalAvailableCount < 10 && `Only ${totalAvailableCount}`} Rooms available
            </span>
          )}
          <ul className="mt-3">
            <Row>
              <Col md={6}>
                <li>Type : {item.type}</li>
                <li>Category : {item.category}</li>
                <li>Total Beds : {item.total_beds}</li>
              </Col>
              <Col md={6}>
                <li>Max Guest : {item.max_allowed_guests}</li>
                <li>Max Children : {item.max_allowed_childrens}</li>
              </Col>
            </Row>
          </ul>
        </div>
      </div>
      {displayFooter && (
        <div className="card-footer bg-transparent py-3 d-flex justify-content-between align-items-center">
          <div className="card-link text-primary">
            <span className="card-footer-price">
              {offerPrice ? (
                <>
                  <span className="strike-price mb-0 px-2">({formattedPrice(rate)})</span>
                  <span className="card-footer-amount">{formattedPrice(offerPrice)}</span>
                </>
              ) : (
                <span className="card-footer-amount">{formattedPrice(rate)}</span>
              )}
            </span>
          </div>
          <button
            type="submit"
            className="btn btn-sm btn-primary"
            onClick={() => bookNow(props.item.slug)}
            disabled={!item.is_room_available}
          >
            <span className="indicator-label">
              {item.is_room_available ? (
                "Book Now"
              ) : (
                <>
                  <IoAlertCircleOutline /> Not available
                </>
              )}
            </span>
          </button>
        </div>
      )}
    </div>
  );
};

export default RoomCard;

RoomCard.propTypes = {
  alt: PropTypes.string,
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  location: PropTypes.string.isRequired,
  websiteLink: PropTypes.string.isRequired,
};

RoomCard.defaultProps = {
  websiteLink: "",
  location: "",
};
