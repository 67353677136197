import React, { useState } from "react";
import { Container, Card, Row, Col } from "react-bootstrap";
import { useInfiniteQuery } from "react-query";
import { Modal } from "@mantine/core";
import moment from "moment";

import { avaibilityService } from "../../services/api/avaibility";
import { RoomAvaibilitySearchBar } from "../molecules/RoomAvaibilitySearchBar";
import { useStringHook } from "../../hooks/useStringHook";
import RoomCard from "../molecules/cards/RoomCard";
import ListingDescription from "../pages/ListingDescription";
import DescriptionTitle from "../atoms/DescriptionTitle";
import SkeletonCard from "../molecules/cards/SkeletonCard";

const RoomDescriptions = ({ propertySlug }) => {
  const [roomDesc, setRoomDesc] = useState(false);
  const [roomId, setRoomId] = useState(null);
  const query = useStringHook();

  const search = query.get("search");
  const startDate = query.get("startDate");
  const endDate = query.get("endDate");
  const adultCount = query.get("adult");
  const childCount = query.get("child");
  const roomCount = query.get("rooms");

  const [date, setDate] = useState([
    new Date(startDate || new Date()),
    new Date(endDate || moment().add(1, "days")),
  ]);
  const [adult, setAdult] = useState(Number(adultCount || 1));
  const [child, setChild] = useState(Number(childCount || 0));
  const [room, setRoom] = useState(Number(roomCount || 1));

  const {
    data: rooms,
    isLoading: isRoomLoading,
    isError,
    isFetching,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    refetch,
  } = useInfiniteQuery(
    ["rooms", propertySlug],
    () =>
      avaibilityService.rooms({
        propertySlug,
        startDate: date[0],
        endDate: date[1],
        adultCount: adult,
        childCount: child,
        roomCount: room,
      }),
    {
      enabled: !!startDate,
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage.next_page_url) return lastPage.current_page + 1;
        return false;
      },
    }
  );

  const handleRoomDesc = (id) => {
    setRoomDesc(true);
    setRoomId(id);
  };

  return (
    <>
      <section className="mb-5">
        <Container>
          <Card>
            <Card.Body>
              <DescriptionTitle title="All Available Rooms" />
              <hr />
              <RoomAvaibilitySearchBar
                search={search}
                slug={propertySlug}
                date={date}
                setDate={setDate}
                room={room}
                setRoom={setRoom}
                refetch={refetch}
                adult={adult}
                setAdult={setAdult}
                child={child}
                setChild={setChild}
                startDate={startDate}
              />
            </Card.Body>
          </Card>
          {isRoomLoading || isFetching ? (
            <Row>
              <SkeletonCard count={3} />
            </Row>
          ) : (
            <>
              <Row>
                {rooms?.pages?.map((page) =>
                  page?.data?.length > 0 ? (
                    page?.data?.map((room, index) => (
                      <Col md={4} key={room.id}>
                        <RoomCard
                          title={room?.title}
                          image={room?.thumbnail?.url}
                          alt={room?.thumbnail?.title}
                          displayFooter={true}
                          item={room}
                          rate={room.room_rate}
                          handleRoomDesc={handleRoomDesc}
                          displayButton={true}
                          offerPrice={room?.offer_price}
                        ></RoomCard>
                      </Col>
                    ))
                  ) : (
                    <div className="text-center w-100">
                      <p className="text-danger h5 mt-2">
                        No matching rooms found.{" "}
                        {isError &&
                          "Please change your dates to see available rooms"}
                      </p>
                    </div>
                  )
                )}
              </Row>
            </>
          )}
          {hasNextPage && (
            <div className="text-center">
              <button
                onClick={fetchNextPage}
                disabled={!hasNextPage || isFetchingNextPage}
                className="text-primary border-0"
              >
                {!isFetchingNextPage && (
                  <span className="indicator-label text-primary">
                    load more..
                  </span>
                )}
                {isFetchingNextPage && (
                  <span
                    className="indicator-progress"
                    style={{ display: "block" }}
                  >
                    Please wait...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </div>
          )}
              {!startDate &&
          <Card>
            <Card.Body>
               <h6 className="text-center font-weight-light"> Please select check-in and check-out time</h6>
            </Card.Body>
          </Card>
               }
        </Container>
      </section>
      {roomDesc && (
        <Modal
          opened={roomDesc}
          onClose={() => setRoomDesc(false)}
          size="100%"
          overflow="inside"
          zIndex={1021}
        >
          <ListingDescription
            startDate={startDate}
            endDate={endDate}
            rooms={roomCount}
            propertySlug={propertySlug}
            id={roomId}
          />
        </Modal>
      )}
    </>
  );
};

export default RoomDescriptions;
