//modules
import React, { useState } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import { Row, Col, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faSearch } from "@fortawesome/free-solid-svg-icons";
import { DateRangePicker } from "@mantine/dates";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";

//components
import { useStringHook } from "../../hooks/useStringHook";
import { usePropertyQuery } from "../../hooks/usePropertyQuery";
import { formatDate } from "../../utils/formatdate";
import SearchLocationInput from "../SearchLocationInput";
import CounterBox from "../molecules/CounterBox";
import moment from "moment";

export const searchSchema = Yup.object().shape({
  search: Yup.string().nullable(),
});

const SearchProperty = (props) => {
  const history = useHistory();

  const { handlePropertySearch } = usePropertyQuery();
  const queryValue = useStringHook();
  const where = queryValue.get("search");
  const startDate = queryValue.get("startDate");
  const endDate = queryValue.get("endDate");
  const adultCount = queryValue.get("adult");
  const childCount = queryValue.get("child");
  const roomCount = queryValue.get("rooms");
  const searchBy = queryValue.get("searchBy");
  let lat = queryValue.get("latitude");
  let long = queryValue.get("longitude");
  let address = queryValue.get("address");

  const [location, setLocation] = useState("");
  const [query, setQuery] = useState(address || "");
  const [adult, setAdult] = useState(Number(adultCount || 2));
  const [child, setChild] = useState(Number(childCount || 0));
  const [rooms, setRooms] = useState(Number(roomCount || 1));
  const [date, setDate] = useState([
    new Date(startDate || new Date()),
    new Date(endDate || moment().add(1 , "days")),
  ]);

  const initialValues = {
    search: where ? where : "",
    startDate: new Date(startDate),
    endDate: new Date(endDate),
  };

  const onFormSubmit = (value) => {
    let searchType;
    if (location) {
      lat = location.geometry.location.lat();
      address = location.name;
      long = location.geometry.location.lng();
      searchType = location?.types?.includes("lodging") ? "hotel" : "city";
    } else {
      searchType = searchBy;
    }
    handlePropertySearch({
      search: value.search,
      startDate: formatDate(date[0]),
      endDate: formatDate(date[1]),
      adult: adult,
      child: child,
      rooms,
      latitude: lat,
      longitude: long,
      address: address,
    });
    history.push({
      pathname: `/search/property/listing`,
      search: `?search=${value.search}&startDate=${formatDate(
        date[0]
      )}&endDate=${formatDate(
        date[1]
      )}&adult=${adult}&child=${child}&rooms=${rooms}&latitude=${lat}&longitude=${long}&address=${address}&searchBy=${searchType}`,
    });
    // props.refetch()
  };

  return (
    <Formik
      validationSchema={searchSchema}
      initialValues={initialValues}
      onSubmit={onFormSubmit}
    >
      {() => (
        <Form>
          <Row className="align-items-center p-3">
            <Col md={3} className="py-2 d-flex">
            {/* <FontAwesomeIcon icon={faSearch} className="headerIcon" /> */}
              <SearchLocationInput
                query={query}
                setQuery={setQuery}
                setLocation={setLocation}
                placeholder="Enter a place / city or hotels."
              />
              <div className="text-danger">
                <ErrorMessage name="search" />
              </div>
            </Col>
            <Col md={4} className="py-2">
              <DateRangePicker
                required
                placeholder="Check-in - Check-out"
                value={date}
                onChange={setDate}
                className="search-item"
                minDate={new Date()}
                amountOfMonths={2}
                initiallyOpened={startDate === null && true}
                icon={<FontAwesomeIcon icon={faCalendarAlt} />}
              />
            </Col>
            <Col md={3} className="py-2">
              <div className="bg-white">
                <CounterBox
                  adult={adult}
                  child={child}
                  rooms={rooms}
                  setRooms={setRooms}
                  setAdult={setAdult}
                  setChild={setChild}
                  type="hotel"
                />
              </div>
            </Col>
            <Col md={2} className="py-2">
              <Button
                type="submit"
                className="w-100 text-center d-flex align-items-center btn btn-primary justify-content-center"
              >
                <FontAwesomeIcon icon={faSearch} />
                <span className="mx-2">Search</span>
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default SearchProperty;
