//modules
// import { Link } from "react-router-dom";

//icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding, faPlaneDeparture } from "@fortawesome/free-solid-svg-icons";

//components and styling
import { useAuth } from "../../hooks/useAuth";

import "../../assets/styles/banner.css";
import {
  bannerLeft,
  bannerRight,
  bannerMiddle,
} from "../../utils/mock/data.json";

export const Banner = (props) => {
  // const { user } = useAuth();
  return (
    <section className="banner d-md-flex">
      <div className="banner-left">
        {props.type === "supplier" ? (
          <>
            <div>
              <FontAwesomeIcon icon={faBuilding} />
              <h3>List Your Property.</h3>
              <p>Hotel, Motel, B&B or Restaurant.</p>
              <a
                rel="noreferrer"
                href={`${process.env.REACT_APP_SUPPLIER_URL}`}
                target="_blank"
                className="btn btn-primary"
              >
                Add Property
              </a>
            </div>
          </>
        ) : (
          <>
            <h3 className="mb-3 banner-title">{bannerLeft.title}</h3>
            <p className="banner-subtitle text-muted">
              {bannerLeft.paragraph}
              {/* {!user && (
                <>
                  <Link
                    to="/user/login"
                    className="text-primary px-1 font-weight-bold"
                  >
                    Sign In
                  </Link>
                  now
                </>
              )} */}
            </p>
          </>
        )}
      </div>
      <div className="banner-right">
        {props.type === "supplier" ? (
          <>
            <div>
              <FontAwesomeIcon icon={faPlaneDeparture} />
              <h3>List Travel Packages.</h3>
              <p>List Travel Packages.</p>
              <a
                rel="noreferrer"
                href={`${process.env.REACT_APP_SUPPLIER_URL}`}
                target="_blank"
                className="btn btn-light text-primary"
              >
                Add Packages
              </a>
            </div>
          </>
        ) : (
          <>
            <h3 className="mb-3 banner-title">{bannerMiddle.title}</h3>
            <p className="banner-subtitle">
              {bannerMiddle.paragraph}
              {/* {!user && (
                <>
                  <a
                    rel="noreferrer"
                    href={`${process.env.REACT_APP_SUPPLIER_URL}`}
                    target="_blank"
                    className="text-white font-weight-bold"
                  >
                    {" "}
                    Become a supplier{" "}
                  </a>
                  today
                </>
              )} */}
            </p>
          </>
        )}
      </div>
      {props.type === "supplier" ? (
        <></>
      ) : (
        <div className="banner-left">

          <h3 className="mb-3 banner-title">{bannerRight.title}</h3>
          <p className="banner-subtitle text-muted">
            {bannerRight.paragraph}
            {/* {!user && (
              <>
                <Link
                  to="/user/login"
                  className="text-primary px-1 font-weight-bold"
                >
                  Sign In
                </Link>
                now
              </>
            )} */}
          </p>
        </div>
      )}
    </section>
  );
};

Banner.defaultProps = {
  type: "user",
};
