import React, { useEffect, useRef } from "react";

let autoComplete;
let googleMapsScriptLoaded = false;

export const loadScript = (url, callback) => {
  if (googleMapsScriptLoaded) {
    callback();
    return;
  }

  let script = document.createElement("script");
  script.type = "text/javascript";

  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        googleMapsScriptLoaded = true;
        callback();
      }
    };
  } else {
    script.onload = () => {
      googleMapsScriptLoaded = true;
      callback();
    };
  }

  script.src = url;
  document.getElementsByTagName("head")[0].appendChild(script);
};

export function handleScriptLoad(updateQuery, autoCompleteRef, setLocation) {
  autoComplete = new window.google.maps.places.Autocomplete(
    autoCompleteRef.current,
    {
      componentRestrictions: { country: "np" },
    }
  );
  autoComplete.setFields([
    "address_components",
    "formatted_address",
    "geometry",
    "place_id",
    "name",
    "type",
  ]);
  autoComplete.addListener("place_changed", () =>
    handlePlaceSelect(updateQuery, setLocation)
  );
}

async function handlePlaceSelect(updateQuery, setLocation) {
  const addressObject = autoComplete.getPlace();
  const query = addressObject.name;
  updateQuery(query);
  setLocation(addressObject);
  console.log(addressObject);
}

function SearchLocationInput({ setLocation, query, setQuery , placeholder }) {
  const autoCompleteRef = useRef(null);

  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE}&libraries=places`,
      () => handleScriptLoad(setQuery, autoCompleteRef, setLocation)
    );
  }, [setQuery, autoCompleteRef, setLocation]);

  return (
    <div className="search-location-input w-100">
      <input
        ref={autoCompleteRef}
        onChange={(event) => setQuery(event.target.value)}
        placeholder={placeholder}
        className="form-control w-100 col-12"
        value={query}
        required
      />
    </div>
  );
}

export default SearchLocationInput;
