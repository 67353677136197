//modules
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

//components/pages
import AuthPage from "../components/auth/components/AuthPage";
import Error404 from "../components/pages/Error404";
import PrivateRoutes from "./PrivateRoutes";
import PublicRoutes from "./PublicRoutes";

import { publicRoutes } from "../_helpers/publicRoutes";
import { privateRoutes } from "../_helpers/privateRoutes";

//hooks
import { useAuth } from "../hooks/useAuth";
import { useOnlineStatus } from "../context/OnlineStatusContext";
import OfflinePage from "../components/pages/offlinePage/OfflinePage";
// import { useQuery } from 'react-query';
// import { userServices } from '../services/api/userApi';

const Routes = () => {
  const { user } = useAuth();

  // // checking if user exits on every render
  // useQuery("user", userServices.user, {
  //     enabled: !!user
  // })

  const isOnline = useOnlineStatus();

  if (!isOnline) {
    return <OfflinePage />;
  }

  return (
    <Switch>
      {/* public routes */}
      {publicRoutes.map((route) => (
        <PublicRoutes
          path={route.path}
          key={route.id}
          component={route.component}
          isAuthenticated={user}
          exact={true}
        />
      ))}
      {privateRoutes.map((route) => (
        <PrivateRoutes
          path={route.path}
          key={route.id}
          component={route.component}
          isAuthenticated={user}
        />
      ))}
          <Route component={Error404} />
    </Switch>
  );
};

export default Routes;
