import React from "react";
import { Container } from "react-bootstrap";
import { CgProfile } from "react-icons/cg";
import { MdCampaign, MdTravelExplore } from "react-icons/md";
import { Link, useLocation } from "react-router-dom";

import "../../assets/styles/mobileFooter.css";
import { AiFillHome } from "react-icons/ai";

const BottomNavigationBar = () => {
  const location = useLocation();

  const isActive = (path) => {
    return location.pathname === path ? "mobile-footer-active" : "";
  };

  return (
    <div className="mobile-footer">
      <Container>
        <div className="d-flex justify-content-between align-items-center text-center">
          <div>
            <Link to="/" className={isActive("/")}>
            <AiFillHome size={25} className="bottom-navigation-icon mb-1"/>
            <br />
            Hotel
            </Link>
          </div>
          <div>
            <Link to="/travel/home" className={isActive("/travel/home")}>
            <MdTravelExplore size={25} className="bottom-navigation-icon mb-1" />
            <br />
            Tours
            </Link>
          </div>
          <div>
          <Link to="/" className={isActive("/campaign")}>
            <MdCampaign size={25} className="bottom-navigation-icon mb-1" />
            <br />
            Deals
          </Link>
          </div>
          <div>
            <Link to="/profile" className={isActive("/profile")}>
            <CgProfile size={25} className="mb-1" />
            <br />
            Profile
            </Link>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default BottomNavigationBar;
