import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFax } from "@fortawesome/free-solid-svg-icons";
import { Card } from "react-bootstrap";
import { AiOutlineClockCircle } from "react-icons/ai";
import moment from "moment";
import { usePackageSearch } from "../../hooks/usePackageSearch";

const CheckoutCard = ({ data, count, price }) => {
  const { packageSearch } = usePackageSearch();

  return (
    <Card style={{ borderTop: "6px solid #AC77EF" }}>
      <Card.Body>
        <div className="cart">
          <h6 className="mb-3">Review Order Details</h6>
          <hr />
          <div></div>
          <div className="row mt-4">
            <div className="col-4">
              <p>Start date :</p>
            </div>
            <div className="col-8">
              <p className="d-flex align-items-center justify-content-end small-text">
                <AiOutlineClockCircle className="mr-2" />
                {
                  Boolean(data?.is_fixed_departure) ?  moment(data?.start_date_time).format("YYYY-MM-DD HH:SS") : moment(packageSearch?.bookingStartDate).format("YYYY-MM-DD")
                }
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-4">
              <p>End date :</p>
            </div>
            <div className="col-8">
              <p className="d-flex align-items-center justify-content-end small-text">
                <AiOutlineClockCircle className="mr-2" />
                {
                  Boolean(data?.is_fixed_departure) ?  moment(data?.end_date_time).format("YYYY-MM-DD HH:SS")  : moment(packageSearch?.bookingEndDate).format("YYYY-MM-DD")
                }
              </p>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-6">
              <p>Price :</p>
            </div>
            <div className="col-6">
              <p className="d-flex justify-content-end">
                Rs. {data?.offer_price || data?.unit_price}
              </p>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-6">
              <p>Total ({count}) :</p>
            </div>
            <div className="col-6">
              <p className="d-flex justify-content-end font-weight-bold">
                Rs.{" "}
                {parseFloat(price * count) ||
                  parseFloat(
                    data?.offer_price * count || data?.unit_price * count
                  ).toFixed(2)}
              </p>
              <span className="text-success">(All fees included)</span>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-2">
              <FontAwesomeIcon icon={faFax} />
            </div>
            <div className="col-10">
              <p className="mb-0">Free Cancellation policy</p>
              <span>(Cancel should be done before time)</span>
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default CheckoutCard;
