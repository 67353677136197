import { Badge } from "@mantine/core";
import { STATUS } from "./constants/statusType";

export const statusCheck = (status) => {
  switch (status) {
    case STATUS.PENDING:
      return <Badge color="yellow">Pending</Badge>;
    case STATUS.REJECT:
      return <Badge color="red">Rejected</Badge>;
    case STATUS.CANCELLATION_PENDING:
      return <Badge color="yellow">Cancellation pending</Badge>;
    case STATUS.CANCELLATION_APPROVED:
      return <Badge color="yellow">Cancellation approved</Badge>;
    case STATUS.CANCELLATION_REJECTED:
      return <Badge color="red">Cancellation rejected</Badge>;
    case STATUS.CANCELLED:
      return <Badge color="red">Cancelled</Badge>;
    case STATUS.PAYMENT_PENDING:
      return <Badge color="yellow">Payment Pending</Badge>;
    case STATUS.PAYMENT_FAILED:
      return <Badge color="red">Payment Failed</Badge>;
    case STATUS.PAYMENT_VERIFICATION_PENDING:
      return <Badge color="yellow">Payment Verification Pending</Badge>;
    case STATUS.PAYMENT_VERIFICATION_FAILED:
      return <Badge color="red">Payment Verification Failed</Badge>;
    case STATUS.APPROVED:
      return <Badge color="green">Approved</Badge>;
    default:
      return <Badge color="yellow">{status}</Badge>;
  }
};
