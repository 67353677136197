import axios from "../../setup/axios/SetupAxios";

// blogs

const getBlogs = async () => {
    const { data } = await axios.get(`/blogs`)
    return data
};

const getBlog = async (slug) => {
    const { data } = await axios.get(`/blogs/${slug}`)
    return data
};

const recentBlogs = async () => {
    const { data } = await axios.get(`/blogs?sort_by=latest`)
    return data
};

export const blogServices = {
    getBlogs, getBlog, recentBlogs
};