import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import {mockData}  from '../../utils/mock/data.js';
import DescriptionTitle from '../atoms/DescriptionTitle.js';
import HealthAndSafety from '../molecules/HealthAndSafety';

const HealthMeasureSection = () => {
    return (
        <Card>
            <Card.Body>
                <DescriptionTitle title="Health and saftey measures" />
                <hr />
                <p>
                    This property has self-selected and
                    self--certified the following hygiene measures.
                    <Link to="/terms-condition" className="text-primary ml-2">
                        Learn More
                    </Link>
                </p>
                <Row>
                    {
                        mockData.healthMeasures.map((item) => (
                            <Col md={4} key={item.id}>
                                <HealthAndSafety
                                    title={item.title}
                                    color={item.color}
                                    icon={item.icon}
                                    para1={item.paragraphOne}
                                    para2={item.paragraphTwo}
                                >
                                </HealthAndSafety>
                            </Col>
                        ))
                    }
                </Row>
            </Card.Body>
        </Card>
    )
}

export default HealthMeasureSection