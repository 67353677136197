import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { bookingServices } from "../services/api/bookings";

export const useCancelBooking = () => {
  const queryClient = useQueryClient();
  return useMutation(bookingServices.cancelBooking, {
    onSuccess: () => {
      queryClient.invalidateQueries("bookings");
    },
    onError: () => {
      toast.error("please try again later");
    },
  });
};
