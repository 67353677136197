import { Skeleton } from "@mantine/core";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

import { useCampaigns } from "../../hooks/useCampaigns";
import { searchParams } from "../../_helpers/initialValues/searchParams";

const CampaignBanner = (props) => {
  const { data: campaigns, isLoading } = useCampaigns();

  const linkPath =
    props.type === "property" ? "/search/property/listing" : "/package/search";

  return (
    <section className={campaigns?.length > 0 && "py-5"}>
      <Container>
        <Row className="justify-content-center">
          {isLoading
            ? Array.from(new Array(3)).map((number, index) => (
                <Col lg={4} className="my-4" key={index}>
                  <Skeleton height={175} widht="100%"  />
                </Col>
              ))
            : campaigns.data.map((campaign) => (
                <Col
                  md={campaigns.data.length <= 2 ? 6 : 4}
                  key={campaign.id}
                  className="my-4"
                >
                  <Link
                    to={{
                      pathname: linkPath,
                      search: `${
                        props.type === "property"
                          ? new URLSearchParams(
                              searchParams("", "", campaign.code)
                            )
                          : ""
                      }`,
                      state: {
                        campaignCode: campaign.code,
                        count: 1,
                      },
                    }}
                    className="campaign-advertize-item-link"
                  >
                    <div
                      className="campaign-image-wrapper"
                      style={{
                        backgroundImage: `url(${campaign?.ad_banner?.url})`,
                      }}
                    ></div>
                  </Link>
                  {/* <div className="card-title">
                   <h5 className="text-left mt-3">{campaign.name}</h5>
                  </div> */}
                  {/* <p className="text-left mt-3">{campaign.description}</p> */}
                </Col>
              ))}
        </Row>
      </Container>
    </section>
  );
};

export default CampaignBanner;
