import React, { useState } from "react";

import "../blog.css";
import { useBlogs } from "../../../hooks/reactQueryCustomHook/blog/useBlogs";
import { FallBackView } from "../FallBackView";
import MainLayout from "../../../layout/MasterLayout";
import PaginationNew from "../../molecules/Pagination";
import RecentPosts from "../../molecules/RecentPosts";
import BlogCard from "../../molecules/cards/BlogCard";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const BlogPage = () => {
  const [page, setPage] = useState(1);

  const { data: blogs, isLoading } = useBlogs();
  if (isLoading) return <FallBackView />;

  return (
    <MainLayout>
      <div className="blog-page">
        <Container>
          <Row>
            {/* main-blog-side  */}
            <Col md={12} lg={8}>
              <Row>
                {blogs.data.map((blog) => (
                  <div className="col-md-6" key={blog.id}>
                     <Link to={`/blog/${blog?.slug}`}>
                    {/* second-card-starts */}
                    <BlogCard blog={blog} />
                    {/* second-card-ends  */}
                     </Link>
                  </div>
                ))}
            </Row>
              </Col>
            {/* side-bar-right */}
            <RecentPosts recentBlog={blogs} />
            {/* side-bar-right-ends  */}
          </Row>
          {/* pagination */}
          <PaginationNew
            setPage={setPage}
            lastPage={blogs.last_page}
            page={page}
          />
        </Container>
      </div>
    </MainLayout>
  );
};

export default BlogPage;
