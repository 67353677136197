import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import icons from '../../_helpers/icons';

import DescriptionTitle from '../atoms/DescriptionTitle';

const PropertyFacilites = ({ data }) => {
    return (
        <div className="card hotel-facilities-wrapper" >
            <div className="card-body pb-0">
            <DescriptionTitle title="Overview" />
                <Row className='mt-4'>
                    <Col>
                        <div>
                            <h5 className="mb-0">
                                Most Popular Facilities
                            </h5>
                            <hr />
                            <ul className="mx-2">
                                <Row>                                    {
                                    data?.services?.map((item, index) => {
                                        return (
                                            <Col md={4} key={index}>
                                            <li key={index} className="pr-3 py-1">
                                                <FontAwesomeIcon icon={icons[item.toLowerCase()] || faCheckCircle}
                                                    className="text-dark mr-2" />
                                                {item}
                                            </li>
                                            </Col>
                                        )
                                    })
                                }
                                </Row>
                            </ul>
                        </div>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                        <div>
                            <h5 className="mb-0">
                                Amenities
                            </h5>
                            <hr />
                            <ul className="mx-2">
                                <Row>
                                    {
                                        data?.amenities?.map((item, index) => {
                                            return (
                                                <Col md={4} key={index}>
                                                    <li key={index} className="pr-3 py-1">
                                                        <FontAwesomeIcon icon={icons[item.toLowerCase()] || faCheckCircle}
                                                            className="text-dark mr-2" />
                                                        {item}
                                                    </li>
                                                </Col>
                                            )
                                        })
                                    }
                                </Row>
                            </ul>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default PropertyFacilites
