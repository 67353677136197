import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

const HealthAndSafety = (props) => {

    const style = {
        width: '1.5rem',
        height: '1.5rem',
    }

    return (
        <>
            <div className='health-safety-card bg-light mt-3'>
                <div className='p-2'>
                    <div className='d-flex'>
                        <div className='icon mr-2'>
                            <img src={props.icon} style={style} alt={props.icon} />
                        </div>
                        <div>
                            <p className={`text-${props.color} text-capitalize`}>{props.title}</p>
                            <ul>
                                <li className='text-muted'>
                                    <FontAwesomeIcon icon={faCheckCircle} className="pr-2 text-success" />
                                    {props.para1}
                                </li>
                                <li className='text-muted'>
                                    <FontAwesomeIcon icon={faCheckCircle} className="pr-2 text-success" />
                                    {props.para2}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HealthAndSafety