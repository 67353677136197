import React from 'react'
import { useMutation, useQueryClient } from 'react-query';
import { Button, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { userServices } from '../../services/api/userApi';
import { usePropertyQuery } from '../../hooks/usePropertyQuery';
import { usePackageSearch } from '../../hooks/usePackageSearch';

const Coupon = ({ bookingReference, setTotalAmount }) => {
    const { searchProperty, handlePropertySearch } = usePropertyQuery();
    const { packageSearch, handlePackageSearch} = usePackageSearch();
    const queryClient = useQueryClient();
    // const [codeSelected, setCodeSelected] = useState(false);

    const mutation = useMutation(userServices.couponCode , {
        onSuccess : () => {
            queryClient.invalidateQueries(["booking" , bookingReference])
        }
    });

    const handleSubmit = (value) => {
        const couponCode = value.couponCode
        mutation.mutate(({ couponCode, bookingReference }), {
            onSuccess: (data) => {
                toast.success(`Coupon code redeemed successfully`)
                // setTotalAmount(data?.data?.booking_total_amount)
                handlePackageSearch({...packageSearch , totalAmount:data?.data?.booking_total_amount})
                handlePropertySearch({ ...searchProperty, totalAmount: data?.data?.booking_total_amount })

            },
            onError: (error) => {
                toast.error(`${error?.response?.data?.error?.message}`)
            }
        })
    };

    const schema = Yup.object().shape({
        couponCode: Yup.string().required('The field is required'),
    });

    return (
        <div>
            <Formik
                validationSchema={schema}
                initialValues={{
                    couponCode: '',
                }}
                onSubmit={handleSubmit}
            >
                {({
                    handleSubmit,
                    handleChange,
                    values,
                }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label className='optional'>Have coupon? </label>
                            <Form.Group controlId="validationFormik05">
                                <div className="input-group">
                                    <Form.Control
                                        type="text"
                                        placeholder="Coupon code"
                                        name="couponCode"
                                        value={values.couponCode}
                                        onChange={handleChange}
                                        // isInvalid={!!errors.couponCode}
                                    />
                                    <span className="input-group-append">
                                        <Button type="submit" variant="primary" disabled={mutation.isLoading}>
                                            Reedem
                                        </Button>
                                    </span>
                                    {/* <Form.Control.Feedback type="invalid">
                                        {errors.couponCode}
                                    </Form.Control.Feedback> */}
                                </div>
                            </Form.Group>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default Coupon