import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

import icons from '../../_helpers/icons';
import DescriptionTitle from '../atoms/DescriptionTitle';

const TourExperience = ({ data }) => {

    return (
        <>
            <Card>
                <Card.Body>
                <DescriptionTitle title="Itinerary" />
                    <hr />
                    <Row>
                        <Col md={11} className="mt-3 pl-4">
                            {data?.tour_details?.map((item, index) =>
                                <div className='itenary-item' key={index}>
                                    <ul style={{ lisStyle: 'none' }}>
                                        <li key={index}>
                                            <p className='text-justify pl-3'>
                                                Day {item?.day}:
                                                <span className="pl-3">
                                                    {item?.activity}
                                                </span>
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                            )}
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <Card>
                <Card.Body>
                    <DescriptionTitle title="What's Included" />
                    <hr />
                    <ul>
                        <Row>
                            {data?.inclusions.map((item, index) => (
                                <Col md={4} key={index}>
                                    <li className="list-style-type-disc" key={item}>
                                        <p className='text-capitalize'>
                                            <span className='d-flex'>
                                                <FontAwesomeIcon icon={icons[item.toLowerCase()] || faCheckCircle}
                                                    className="text-dark mr-2" />
                                                <span style={{ fontSize: '16px' }}>
                                                    {item}
                                                </span>
                                            </span>
                                        </p>
                                    </li>
                                </Col>
                            ))
                            }
                        </Row>
                    </ul>
                </Card.Body>
            </Card>
            {
                data?.pickup &&
                <Card>
                    <Card.Body>
                        <DescriptionTitle title="Departure & Return" />
                        <hr />
                        <Row>
                            <Col lg={9}>
                                <p className="mb-0">Possible departure locations:
                                    <FontAwesomeIcon icon={faMapMarkerAlt} className="pl-3" />
                                    <span className="mx-2">{data?.pickup?.meeting_point}</span>
                                </p>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            }
            {
                data?.additional_information &&
                <Card>
                    <Card.Body>
                    <DescriptionTitle title="Additional Information" />
                        <hr />
                        <Row>
                            <Col lg={12}>
                                <p className='text-justify'>{data?.additional_information}</p>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            }
        </>
    )
}

export default TourExperience
