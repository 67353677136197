/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { Modal } from "@mantine/core";
import { Link } from "react-router-dom";

import { userServices } from "../../../services/api/userApi";
import { FallBackView } from "../FallBackView";
import Error from "../Error";
import NewsletterSubscription from "../../molecules/NewsletterSubscription";

import DashboardLayout from "../../../layout/DashboardLayout";

export const DashboardUserDetails = () => {
  const queryClient = useQueryClient();
  const [code, setOptCode] = useState("");
  const [verify, setVerify] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { data:user, isLoading, isError } = useQuery("user", userServices.user);
  const mutation = useMutation(userServices.phoneVerification, {
    onSuccess: () => toast.success("Enter Verification Code"),
    onError: () => toast.error("oops something went wrong"),
  });
  const { mutate, isLoading: isMutating } = useMutation(
    userServices.verificationCode,
    {
      onSuccess: () => {
        toast.success("Phone number verified successfully");
        handleClose();
        queryClient.invalidateQueries("user");
      },
      onError: () => {
        toast.error("Oops something went wrong");
        handleClose();
      },
    }
  );

  if (isLoading) return <FallBackView />;

  if (isError) return <Error />;

  const phone = user?.profile?.phone;
  const phoneVerified = user?.profile?.phone_verified;

  const onFormSubmit = () => {
    mutation.mutate(phone, {
      onSuccess: () => {
        setVerify(true);
      },
    });
  };

  return (
    <DashboardLayout>
      <Modal
        opened={show}
        onClose={() => setShow(false)}
        title="Phone Verification"
      >
        {verify ? (
          <>
            <Form.Label htmlFor="otp-code">
              We have send you an verification code
            </Form.Label>
            <Form.Control
              type="text"
              value={code}
              onChange={(e) => setOptCode(e.target.value)}
              placeholder="Enter verification Code"
              id="otp-code"
              required
            />
          </>
        ) : (
          `Your phone number ${phone} is not verified yet`
        )}
        {verify ? (
          <button
            type="submit"
            id="kt_sign_in_submit"
            className="btn btn-sm p-2 btn-primary my-5"
            disabled={isMutating}
            onClick={() => mutate({ code, phone })}
          >
            {!isMutating && <span className="indicator-label">Send</span>}
            {isMutating && (
              <span className="indicator-progress" style={{ display: "block" }}>
                Please wait...
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
        ) : (
          <button
            type="submit"
            id="kt_sign_in_submit"
            className="btn btn-md p-2 btn-primary"
            disabled={mutation.isLoading}
            onClick={() => onFormSubmit({ phone })}
          >
            {!mutation.isLoading && (
              <span className="indicator-label">Verify number</span>
            )}
            {mutation.isLoading && (
              <span className="indicator-progress" style={{ display: "block" }}>
                Please wait...
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
        )}
      </Modal>
      {/* <UserProfile data={user} /> */}
      <div className="card mb-5">
        <div className="card-body">
          <div className="profile-list mb-5">
            <div className="float-right">
            <Link className={`btn btn-outline-info rounded-pill`} to="/edit-profile">
              Edit Profile
            </Link>
            </div>
            <h5 className="card-title mb-3">User details</h5>
            <hr />
            <div className="row py-4">
              <div className="col-lg-6 col-md-12 text-justify ">
                <ul className="profile-unordered-list">
                      <li className="text-secondary">
                        <span className="lists">Full Name :</span>
                        {user?.name}
                      </li>
                      <br />
                      <li className="text-secondary">
                        <span className="lists">Email :</span>
                        {user?.email}
                        <NewsletterSubscription isNewsletterSuscribed = {user.isNewsletterSubscribed} />
                      </li>
                      <br />
                      <li className="text-secondary">
                        <span className="lists">Country :</span>
                        {user?.profile.country}
                      </li>
                      <br />
                </ul>
              </div>
              <div className="col-lg-6 col-md-12">
                <ul className="profile-unordered-list ">
                  <li className="text-secondary text-capitalize">
                    <span className="lists text-secondary text-capitalize">
                      DOB :{" "}
                    </span>
                    {user?.profile?.date_of_birth || "-"}
                  </li>
                  <br />
                  <li>
                    {/* <a href={`tel:${user?.profile?.phone}`} className="text-secondary text-capitalize"> */}
                    <span className="lists">Contact :</span>
                    {!phone ? (
                      "-"
                    ) : (
                      <>
                        <span className="mr-2">{phone}</span>
                        {phoneVerified ? (
                          <span
                            className="badge badge-primary badge-pill disabled"
                            disabled
                          >
                            Verified
                          </span>
                        ) : (
                          <a
                            className="badge badge-danger badge-pill"
                            onClick={handleShow}
                            style={{ cursor: "pointer" }}
                          >
                            Not Verified
                          </a>
                        )}
                      </>
                    )}
                    {/* </a> */}
                  </li>
                  <br />
                  <li className="text-secondary text-capitalize">
                    <span className="lists">State :</span>
                    {user?.profile?.state || "-"}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};
