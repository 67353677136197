import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { bookingServices } from '../../../services/api/bookings';

export const useBookRoom = (otherParams) => {
    //other params contains more functions of react query
    return useMutation(bookingServices.bookRoom, {
        onSuccess: () => {
            toast.success('Booking request sent');
        },
        onError: (error) => {
            toast.error(`${error.response.data.error.message}` || "Server error!! please try again later");
        },
        otherParams
    });
};