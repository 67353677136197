import moment from "moment";

export const bookingExpiry = (bookingCreatedDate, bookingUpdatedDate) => {
    const TEN_MINUTES_IN_MS = 12 * 60 * 1000; // 12 minutes in milliseconds
    let bookingExpiryDate;
  
    if (bookingUpdatedDate === bookingCreatedDate) {
      bookingExpiryDate = new Date(bookingCreatedDate).getTime() + TEN_MINUTES_IN_MS;
    } else if (bookingUpdatedDate) {
      bookingExpiryDate = new Date(bookingUpdatedDate).getTime() + TEN_MINUTES_IN_MS;
    } else {
      return {
        bookingExpiryDate: 'No booking date available',
      };
    }

    return {
      bookingExpiryDate: moment(bookingExpiryDate).format("MMMM Do YYYY, h:mm:ss a"),
    };
  };
  