import imageLogo from "../../assets/images/SuyatraLogo.png";

//health measures
import heartIcon from "../../assets/images/heart.png";
import tickIcon from "../../assets/images/tick.png";
import handIcon from "../../assets/images/hand.png";

//testimonials image
import testimonials1 from "../../assets/images/testimonials/testimonial1.png";
import testimonials2 from "../../assets/images/testimonials/testimonial2.jpg";
import moment from "moment";
import { faHandshake } from "@fortawesome/free-solid-svg-icons";
import { GiBurningPassion, GiIdea } from "react-icons/gi";

export const mockData = {
  companyTitle: "SuYatra",
  logo: {
    image: imageLogo,
  },
  propertyHeader: {
    title: "Discover Your Next Holiday Destinations",
    subTitle: "Book hotels and holiday packages.",
  },
  packageHeader: {
    title: "Discover Your Next Holiday Destinations",
    subTitle: "Discover unforgettable travel experiences.",
  },
  supplierHeader: {
    title: "Your Business Can Make An Impact In Their Experience",
    subTitle: "Coordinated. Connected. Committed.",
  },

  healthMeasures: [
    {
      id: 1,
      title: "Health and Medical",
      color: "danger",
      paragraphOne: "First-aid Kits",
      paragraphTwo: "Doctors On Call Service",
      icon: heartIcon,
    },
    {
      id: 2,
      title: "Safety Features",
      color: "success",
      paragraphOne: "First-aid Kits",
      paragraphTwo: "Health Care Professional",
      icon: tickIcon,
    },
    {
      id: 3,
      title: "Preventive Equipments",
      color: "info",
      paragraphOne: "First-aid Kits",
      paragraphTwo: "Health Care Professional",
      icon: handIcon,
    },
  ],
  coreValues: [
    {
      id: "1",
      title: "Integrity",
      color: "danger",
      icon: faHandshake,
      paragraphOne:
        "We uphold the highest standards of integrity in all our actions.",
      paragraphTwo: "We believe in doing the right thing, always.",
      paragraphThree:
        "We are committed to being transparent, honest, and ethical in all our interactions with employees, clients, and the communities we serve.",
    },
    {
      id: "2",
      title: "Innovation",
      color: "success",
      icon: GiIdea,
      paragraphOne:
        "We foster an environment that encourages innovative thinking and creative solutions.",
      paragraphTwo:
        "We continuously strive to improve our platform, services, and experiences by embracing new technologies, ideas, and practices.",
    },
    {
      id: "3",
      title: "Passion",
      color: "info",
      icon: GiBurningPassion,
      paragraphOne:
        "We are passionate about travel, culture, and the beauty of Nepal.",
      paragraphTwo:
        "This passion drives us to deliver exceptional experiences for our customers.",
      paragraphThree:
        "We put our heart and soul into every service we provide, ensuring that each journey booked through our platform is memorable and enriching.",
    },
  ],
  whyChooseUs: [
    {
      id: "1",
      title: "Handpicked Recommendations",
      content:
        "We work with the best of the best; whether it be hotels, tour packages, guides, travel recommendations. All of our suppliers are trusted partners and approved through thorough review and authentication. We are confident that our recommendations can provide you an authentic experience like never before.",
      url: "../../assets/images/why-choose-us/1.webp",
    },
    {
      title: "Quality and Quantity",
      content:
        "Browse from over hundreds of hotels, restaurants, tours, activities and packages, and book the one that you feel is the 'right one' for you. We offer you countless selection of quality content for your trip. You can take your time to carefully choose your favourite or you can save your time by choosing our recommended; the point is, you won't regret either of your choice!",
      url: "../../assets/images/why-choose-us/2.webp",
    },
    {
      id: "2",
      title: "Trust Our Dedication",
      content:
        "We have dedicated ourselves to deliver you the best bookings, packages and experiences, that  is time-saving, stress-free and fun! As travel enthusiasts, we want our customers to travel and stay in comfort no matter where you go. Its our job to eliminate your issues and guarantee that your trip with us is made just for you.",
      url: "../../assets/images/why-choose-us/3.webp",
    },
    {
      id: "3",
      title: "We are Flexible",
      content:
        "Planning and bookings can be unpredictable, and we've got you covered in all areas. Your booking and packages can be flexibly accommodated with no problems whether that is extending your trip, cancelling, your booking, postponing, or request for changes as long as it is within our policy guidelines.",
      url: "../../assets/images/why-choose-us/4.webp",
    },
  ],
  counter: [
    {
      id: "1",
      title: "300",
      subTitle: "New Visitors every week",
    },
    {
      id: "2",
      title: "2200",
      subTitle: "Happy Coustomer Every Year",
    },
    {
      id: "3",
      title: "500",
      subTitle: "New Listings Every Week",
    },
  ],
  createExperience: [
    {
      id: 1,
      title: "Discover Your Guest",
      content:
        "Connect with thousands of guests looking for comfort, travel and taste under a budget that makes your property the perfect choice",
    },
    {
      id: 2,
      title: "Cater to the right guest",
      content:
        "Get ready to accommodate guests with different needs. From long-period stays, last-minute bookings, travel-friendly planners, and business-oriented, your property can cater to their exclusive needs.",
    },
    {
      id: 3,
      title: "Compete in Real-time",
      content:
        "Connect and compete to grow your business. Make your property the best choice by connecting with guests in real-time by making decisions, responding, and offering incentives. ",
    },
  ],
  becomeSupplier: [
    {
      id: 2,
      title: "3. Start Listing ",
      paragraph:
        "List any type of property or packages your business is offering to the customers. Include necessary details like rooms, amenities, and budget that you your guests to know.",
    },
    {
      id: 3,
      title: "4. Get Bookings",
      paragraph:
        "Once you are all set up, you can start receiving bookings for your product and services through our website. All ready to go!",
    },
  ],
  bannerLeft: {
    title: "Get instant discounts.",
    paragraph:
      "Earn amazing discounts by referring your friends to our website. If you have friends or family who want to plan a relaxing stay, refer them to our website enjoy dis- counts on your booking",
  },
  bannerRight: {
    title: "Why Partner With SuYatra?",
    paragraph:
      "SuYatra makes it easier for suppliers to connect with customers who are looking for your travel now. The first step to do this is register your company, and provide us your information and the services, rooms, and tour packages you offer!",
  },
  testinomonials: [
    {
      id: 1,
      createdAt: "May 8, 2020",
      title: "Really Lovely People ",
      content:
        "An excellent website where I can stay up to date with all of my properties and packages, from the status to analytics, and my customers. I believe that my com-pany can gain more exposure thanks to Suyatra",
      userName: "@MandeepNuepane",
      image: testimonials1,
    },
    {
      id: 2,
      createdAt: "Jan 22, 2018",
      title: "Great Trip",
      content:
        "SuYatra is a great online platform, thanks to which we have gained more customers and even regulars who have come back to book reservations from us again. In a technological age, a travel and lodging online platform like Suyatra helps small companies to gain more exposure easily.",
      userName: "@NirjalWagle",
      image: testimonials2,
    },
    {
      id: 3,
      createdAt: "May 8, 2020",
      title: "Really Lovely People ",
      content:
        "Very easy to navigate and list my property. Regular notifications and email up-dates helps a lot to keep up to date with our operations",
      userName: "@YashuNeupane",
      image: testimonials1,
    },
  ],
  footer: {
    description:
      "Affordable and hassle-free bookings for your holiday plans. SuYatra is curated for travellers and makes it easy, stress-free, and enjoyable for you to plan your next trip.",
    menuTitle1: "SuYatra",
    menuTitle2: "Our Services",
    menuTitle3: "Contact",
    copyrightText: `Copyright © ${moment().format(
      "YYYY"
    )} SuYatra. All rights reserved.`,
  },
  companyContactDetails: {
    email: "info@suyatra.com.np",
    phoneNumber: "+977-1-4537187",
    companyPostOffice: "P.O.Box No. 21255",
    companyLocation: `Trishakti Marg, Uttar Dhoka Rd, Lazimpat, Kathmandu, Nepal`,
  },
  companySocialMediaDetails: {
    instagram: "https://www.instagram.com/suyatraonline/",
    facebook: "https://www.facebook.com/profile.php?id=100088868798676",
    twitter: "https://twitter.com/suyatraonline",
    linkedin: "https://www.linkedin.com/company/suyatraonline/",
  },
  sectionHeader: {
    bestDeals: {
      title: "Best Hot Deals",
      subTitle:
        "Enhance your trip experiencing the best hotels and eating delicious cuisines from the best restaurants in your travel area",
    },
    popularHotels: {
      title: "Popular Hotels and Restaurants",
      subTitle:
        "Enhance your trip experiencing the best hotels and eating delicious cuisines from the best restaurants in your travel area",
    },
    popularPackages: {
      title: "Most Popular Packages.",
      subTitle:
        "These companies release their own versions of the operating systems with minor changes, and yet always.",
    },
    featuredPlaces: {
      title: "Most Featured Places",
      subTitle:
        "Make updates to your trip itinerary by adding these extraordinary places to your places to visit.",
    },
    whyChooseUs: {
      title: "Why Choose Us",
      subTitle:
        "Enhance your trip experiencing the best hotels and eating delicious cuisines from the best restaurants in your travel area",
    },
    blog: {
      title: "Our Blog",
      subTitle:
        "Discover new places and gather new experiences by reading our blogs.",
    },
    testimonials: {
      title: "Your Property Our Value.",
      subTitle: "Our Suppliers’ story.",
    },
    partnerSection: {
      title: "Why Partner With SuYatra ?",
      content:
        "SuYatra aims to offer unique and high quality packages to our guests and we want quality suppliers who can offer this unique and high quality experience to travellers and guests who are looking for the best package.",
    },
    becomeSupplier: {
      title: "How to become a supplier ?",
      paragraph:
        "Whether your company is a hotel, travel company, guide agency or anything related to travel and tour, you can register your business with us as a supplier.",
    },
    deals:{
      title:"Available Deals",
      paragraph:"Get the best deals on hotels, restaurants, and tour packages. Book now and enjoy your trip.",
    }
  },
};
