import React from "react";

import Posts from "../atoms/Posts";
import { AiOutlineSearch } from "react-icons/ai";

const RecentPosts = ({recentBlog}) => {
  return (
    <div className="col-12 col-md-12 col-lg-4">
      <div className="side-bar-right">
        {/* search-bar-starts  */}
        <div className="search-part">
          <form>
            <label className="search-label section-title ">Search</label>
            <div className="search-bar">
              <input className="search-input" placeholder="Search ..." />
              <button type="submit" className="search-btn">
                <AiOutlineSearch className="magnifying-glass" />
              </button>
            </div>
          </form>
        </div>
        {/* search-bar-ends  */}
        {/* sidebar-widget-starts  */}
        <div className="sidebar-widget">
          <label className="sidebar-widget-topic section-title">
            Latest posts
          </label>
          <div className="thumbnail-items p-0">
            {/* post-1  */}
            {recentBlog?.data?.map((item) => (
              <Posts item={item} />
            ))}
          </div>
        </div>
        {/* sidebar-widget-ends  */}
      </div>
    </div>
  );
};

export default RecentPosts;
