import { Card, Col, Row } from "react-bootstrap"
import DescriptionTitle from "../atoms/DescriptionTitle"
import moment from "moment"

export const PackageDateCard = ({ data }) => {
    return (
        <Card>
            <Card.Body>
                <DescriptionTitle title="Start and End Date" />
                <hr />
                <Row>
                    <Col lg={9}>
                        <ul>
                            <li className="list-style-type-disc">
                                <p>
                                    Tour Start Date : {moment(data?.start_date_time).format('YYYY-MM-DD')}
                                </p>
                                <p>
                                    Tour End Date : {moment(data?.end_date_time).format('YYYY-MM-DD') || "-"}
                                </p>
                            </li>
                        </ul>
                        <p>
                            Note* : The tour is available to book till {moment(data?.cutoff_date_time).format('YYYY-MM-DD') || "-"}
                        </p>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}