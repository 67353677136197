import { faUsers } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Menu } from '@mantine/core'
import React from 'react'
import PropTypes from 'prop-types'

import Counter from '../atoms/Counter'

const CounterBox = ({ adult, setAdult, count, setCount, child, setChild, rooms, setRooms, type , className }) => {
    return (
        <Menu
            withArrow
            placement="center"
            style={{ width: '100%' }}
            control={
                <button type="button" className={`btn btn-white w-100 text-left border ${className}`}>
                    <FontAwesomeIcon icon={faUsers} className="headerIcon mr-3 text-primary" />
                    Guests : {type === "hotel" ? adult + child : count} {type === "hotel" && `, Room : ${rooms}`}
                </button>
            }
        >
            {
                type === "hotel" ? (
                    <>
                        <Counter name="Adults" totalCount={1} count={adult} setCount={setAdult} />
                        <br />
                        <Counter name="Child" totalCount={0} count={child} setCount={setChild} />
                        <br />
                        <Counter name="Rooms" totalCount={1} count={rooms} setCount={setRooms} />
                    </>
                )
                    :
                    <Counter name="Count" totalCount={1} count={count} setCount={setCount} />
            }
        </Menu>)
}

export default CounterBox

CounterBox.defaultProps = {
    type: 'hotel'
}

CounterBox.propTypes = {
    adult: PropTypes.number,
    child: PropTypes.number,
    listings: PropTypes.string,
    active: PropTypes.string
}