/* eslint-disable jsx-a11y/anchor-is-valid */

import { createStyles, TextInput, Loader } from "@mantine/core";
import { useState } from "react";
import { Container, Button, Row, Col } from "react-bootstrap";
import appStore from "../../assets/images/install-application/appStore.png";
import googlePlay from "../../assets/images/install-application/googlePlay.png";
import { AiOutlineMail } from "react-icons/ai";
import { useGuestNewsletterSubscribe } from "../../hooks/useGuestNewsletterSubscribe";
import newsLetterImage from "../../assets/images/newsLetter.svg";

const useStyles = createStyles((theme) => ({
  wrapper: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing.xl * 2,
    borderRadius: theme.radius.md,
  },

  image: {
    maxWidth: "40%",

    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      maxWidth: "100%",
    },
  },

  body: {
    paddingRight: theme.spacing.xl * 4,

    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      paddingRight: 0,
      marginTop: theme.spacing.xl,
    },
  },

  title: {
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    lineHeight: 1,
    marginBottom: theme.spacing.md,
  },

  controls: {
    display: "flex",
    marginTop: theme.spacing.xl,
  },

  inputWrapper: {
    width: "100%",
    flex: "1",
  },

  input: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderRight: 0,
    rounded: 0,
  },

  control: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
}));

export function NewsLetter() {
  const { classes } = useStyles();
  const [email, setEmail] = useState("");
  const { mutate, isLoading } = useGuestNewsletterSubscribe();

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = () => {
    mutate(email, {
      onSuccess: () => {
        setEmail("");
      },
    });
  };

  return (
    <section style={{ backgroundColor: "#F1F3F5", padding: "4rem 0" }}>
      <Container>
        <Row className="align-items-center ">
          <Col md={7} className="mb-4">
            <h2>Wait a minute...</h2>
            <p>Subscribe to our newsletter!</p>
            <p className="small-text">
              You will never miss important product updates, latest news and
              community QA sessions. Our newsletter is once a week, every
              Sunday.
            </p>
            <div className={classes.controls}>
              <TextInput
                type="email"
                placeholder="Your email"
                value={email}
                required
                icon={<AiOutlineMail />}
                rightSection={isLoading && <Loader size="xs" />}
                onChange={(e) => handleEmail(e)}
                classNames={{
                  input: classes.input,
                  root: classes.inputWrapper,
                }}
              />
              <Button
                className={"btn btn-primary btn-sm rounded-0"}
                onClick={handleSubmit}
                disabled={!email || isLoading}
              >
                Subscribe
              </Button>
            </div>
          </Col>
          <Col md={1}></Col>
          <Col md={4} className="d-none d-md-block">
            <img
              src={newsLetterImage}
              alt="newsletter"
              width={"100%"}
              height={"100%"}
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
}
