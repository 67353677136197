import * as Yup from 'yup'
import { regression } from '../../../utils/regressions';

const registerSchema = Yup.object().shape({
    Firstname: Yup.string()
        // .min(1, "Minimum 1 characters")
        .max(255, "Maximum 255 characters")
        .required("First Name is required"),
    Lastname: Yup.string()
        // .min(1, "Minimum 1 characters")
        .max(255, "Maximum 255 characters")
        .required("Last Name is required"),
    email: Yup.string()
        .email("Wrong email format")
        .min(3, "Minimum 3 characters")
        .max(255, "Maximum 255 characters")
        .required("Email is required"),
    password: Yup.string()
        .min(8, "Minimum 8 characters")
        .max(255, "Maximum 255 characters")
        .matches(regression.passwordRegx, "Password must be at least 8 characters long including at least one UPPERCASE, one lowercase, one number and one special character")
        .required("Password is required"),
    password_confirmation: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Password is required"),
    // transactionalMail: Yup.boolean().oneOf([true], 'Transaction mail should be accepted'),
    newsletterSubscription: Yup.boolean().nullable(),
    phoneNumber: Yup.string()
    .min(10, "Phone number is not valid")
    .max(10, "Phone number is not valid")
    .matches(regression.phoneRegression, 'Phone number is not valid')
    .required('Phone Number is required'),
});

const initialValues = {
    email: "",
    Firstname: "",
    Lastname: "",
    password: "",
    password_confirmation: "",
    // transactionalMail: false,
    newsletterSubscription: false,
    phoneNumber:''
};

export { initialValues, registerSchema }