//modules
import React from 'react';

// components
import Footer from '../molecules/Footer';
import { Banner } from '../organisms/Banner';
import SupplierHeadingSection from '../organisms/SupplierHeadingSection';
import SupplierPartnerSection from '../organisms/SupplierPartnerSection';
import BecomeSupplier from '../organisms/BecomeSupplier';

import '../../assets/styles/supplier.css'

const SupplierHomePage = () => {

    return (
        <div className='supplierHomePage'>
            <SupplierHeadingSection />
            <Banner type="supplier" />
            <SupplierPartnerSection />
            {/* <Testinomials
                text="I will be the leader of a company that ends up being worth billions of dollars, because I
            got the answers"
            /> */}
            <BecomeSupplier />
            <Footer />
        </div>
    )
}

export default SupplierHomePage