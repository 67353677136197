import React from "react";
import { useMutation } from "react-query";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

import { authenticationService } from "../../../services/api/authentication";
import "../../../assets/styles/settings.css";
import { regression } from "../../../utils/regressions";

import DashboardLayout from "../../../layout/DashboardLayout";

const DashboardSettings = () => {
  const history = useHistory();

  const { mutate, isLoading: isMutating } = useMutation(
    authenticationService.changePassword,
    {
      onSuccess: () => {
        history.push("/profile");
        toast.success("New password updated");
      },
      onError: (error) => {
        toast.error(`${error?.response?.data?.error?.message}`);
      },
    }
  );

  return (
    <DashboardLayout>
      <div className="card">
        <div className="card-body">
          <div className="card-title mb-4">
            <h5 className="card-title mb-3">Change Password</h5>
            <hr />
          </div>
          <Formik
            initialValues={{
              current_password: "",
              new_password: "",
              new_password_confirmation: "",
            }}
            validationSchema={Yup.object({
              current_password: Yup.string()
                .min(8, "Must be 8 characters or less")
                .required("Current password is required"),
              new_password: Yup.string()
                .min(8, "Must be 8 characters or less")
                .matches(
                  regression.passwordRegx,
                  "Password must be at least 8 characters long including at least one Uppercase, one lowercase, one Number and one Special Character"
                )
                .required("New password required"),
              new_password_confirmation: Yup.string()
                .oneOf([Yup.ref("new_password"), null], "Passwords must match")
                .required("Password is required"),
            })}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              setTimeout(() => {
                setSubmitting(false);
                mutate(values);
                resetForm();
              }, 400);
            }}
          >
            <Form>
              <label htmlFor="current_password">Current Password</label>
              <Field
                id="current_password"
                name="current_password"
                type="password"
                className="form-control"
              />
              <div className="text-danger">
                <ErrorMessage name="current_password" />
              </div>
              <label htmlFor="new_password" className="mt-3">
                New Password
              </label>
              <Field
                name="new_password"
                type="password"
                className="form-control"
              />
              <div className="text-danger">
                <ErrorMessage name="new_password" />
              </div>
              <label htmlFor="new_password_confirmation" className="mt-3">
                Confirm New Password
              </label>
              <Field
                name="new_password_confirmation"
                type="password"
                className="form-control"
              />
              <div className="text-danger">
                <ErrorMessage name="new_password_confirmation" />
              </div>
              <button
                type="submit"
                id="kt_sign_in_submit animate.css"
                className="btn btn-md btn-primary w-10 mt-4"
                disabled={isMutating}
              >
                {!isMutating && (
                  <span className="indicator-label">Save Changes</span>
                )}
                {isMutating && (
                  <span
                    className="indicator-progress"
                    style={{ display: "block" }}
                  >
                    Please wait...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </Form>
          </Formik>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default DashboardSettings;
