import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useAuth } from '../../hooks/useAuth';
import { useStringHook } from '../../hooks/useStringHook';
import { authenticationService } from '../../services/api/authentication';
import { b64_to_utf8 } from '../../utils/convertToBase64';

const ImpersontateUser = () => {
  const query = useStringHook();
  const history = useHistory();
  const { setAuth } = useAuth();

  const encodedToken = query.get('token') || '';
  if (!encodedToken) {
    history.push('/');
  }
  let token;
  try {
    token = b64_to_utf8(encodedToken)
  }
  catch (err) {
    history.push('/')
  }

  useQuery(['impersonate-user', token], () => authenticationService.impersonateLogin(token),
    {
      enabled: !!token,
      onSuccess: (data) => {
        //setting token in localstorage
        setAuth(data)
        history.push('/')
        toast.success(`User Logged in successfully`)
        window.location.reload()
      },
      onError: (error) => {
        toast.error(`${error.response.data.error.message}`)
        history.push('/')
      }
    }
  );

  return true
}

export default ImpersontateUser