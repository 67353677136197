import React from "react";
import { useMutation } from "react-query";
import { paymentServices } from "../../../../services/api/payment";

const EsewaPaymentForm = ({ amount, bookingReference }) => {
  const esewaPayment = useMutation(paymentServices.initiateEsewa, {
    onSuccess: (data) => {
      const path = `${process.env.REACT_APP_ESEWA_UAT_URL}/epay/main/v2/form/`;
      function post(esewaData) {
        var form = document.createElement("form");
        form.setAttribute("method", "POST");
        form.setAttribute("action", path);
        // Loop through the data object and create hidden input fields
        for (var key in esewaData) {
          var hiddenField = document.createElement("input");
          hiddenField.setAttribute("type", "hidden");
          hiddenField.setAttribute("name", key);
          hiddenField.setAttribute("value", esewaData[key]);
          form.appendChild(hiddenField);
        }

        document.body.appendChild(form);
        form.submit();
      }

      // Example usage:
      var esewaData = {
        amount: data.amount,
        tax_amount: "0",
        total_amount: data.amount,
        transaction_uuid: data.transaction_uuid,
        product_code: data.product_code,
        product_service_charge: "0",
        product_delivery_charge: "0",
        success_url: `${process.env.REACT_APP_BASE_URL}/payment-verification-page`,
        failure_url: `${process.env.REACT_APP_BASE_URL}/payment/${bookingReference}?q=fu`,
        signed_field_names: data.signed_field_names,
        signature: data.signature,
      };
      if(data){
        post(esewaData);
      }
    },
  });
  return (
    <>
      <button
        type="submit"
        className="btn btn-outline-primary rounded-pill col-12 btn-lg my-4 font-weight-bold"
        onClick={() => esewaPayment.mutate({ bookingReference })}
        disabled={esewaPayment.isLoading}
      >
        {!esewaPayment.isLoading && (
          <span className="indicator-label">Pay Now</span>
        )}
        {esewaPayment.isLoading && (
          <span className="indicator-progress" style={{ display: "block" }}>
            Please wait...
            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
          </span>
        )}
      </button>
    </>
  );
};

export default EsewaPaymentForm;
