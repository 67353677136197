import axios from "../../setup/axios/SetupAxios";

/**
 * User Review Routes
 */

const postReview = async ({ slug, type, ...value }) => {
    const response = await axios.post(`/${type}s/${slug}/reviews`,
        {
            review: value.review,
            rating: value.rating,
        },
    );
    return response;
};

const storeReviewPackages = async ({ id, ...value }) => {
    const response = await axios.post(`/products/packages/${id}/reviews`,
        {
            review: value.review,
            rating: value.rating,
        },
    );
    return response;
};

const storeReviewRoom = async ({ id, ...value }) => {
    const response = await axios.post(`/products/rooms/${id}/reviews`,
        {
            review: value.review,
            rating: value.rating,
        },
    );
    return response;
};

const getReviews = async ({ pageParam = 1 }) => {
    const { data } = await axios.get(`/user/reviews?page=${pageParam}&per_page=4`)
    return data
};

const getReview = async ({ pageParam = 1, slug, type }) => {
    const { data } = await axios({
        method: "GET",
        url: `/${type}/${slug}/reviews?page=${pageParam}&per_page=4`,
    });
    return data
};

export const ReviewService = {
    storeReviewPackages,
    storeReviewRoom,
    getReviews,
    getReview,
    postReview
}