import axios from "../../setup/axios/SetupAxios";

/*******************************************************************
 * image or attachements Routes
 ******************************************************************/

const removeAttachment = async(id) => {
    const response = await axios.delete(`/attachments/${id}`)
    return response.data
};

const uploadProfileImage = (profileImage) => {
    const response = axios.put(
        `/attachments/profile-image`,
        {
            profile_image: profileImage,
        },
    );
    return response
};


export const imageServices = {
 removeAttachment,
 uploadProfileImage
};