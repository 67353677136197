import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { bookingServices } from "../services/api/bookings";

export const useBookPackage = () => {
  return useMutation(bookingServices.bookPackage, {
    onSuccess: (data) => {
      toast.success("Booking request sent");
    },
    onError: (error) => {
      toast.error(`${error.response.data.error.message}` || "server error");
    },
  });
};
