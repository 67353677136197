import React, { Fragment } from 'react'

import SupplierPartnerCard from '../molecules/SupplierPartnerCard'
import supplierPartnerImg from '../../assets/images/suppliers/1.jpg'
import supplierPartnerImage from '../../assets/images/suppliers/2.jpg'
import { createExperience } from '../../utils/mock/data.json'

const SupplierPartnerSection = () => {
    return (
        <div className='supplier-partner-section bg-light'>
            <SupplierPartnerCard content="1" image="2" imageUrl={supplierPartnerImg}>
                <h3 className="suppplier-partner-title">Why Partner With Suyatra ?</h3>
                <p className="suppplier-partner-subtitle pt-4">
                    Suyatra aims to offer unique and high quality packages to our guests and we want quality suppliers who can offer this unique and high quality experience to travellers and guests who are looking for the best package.
                </p>
                <p className='suppplier-partner-subtitle pt-2'>
                    Suyatra makes it easier for suppliers to connect with audiences in real-time who are looking for your services. We want to  provide value for both you, our supplier partner, and the guests, who visit this website and potentially your customers too. The first step to do this is register your company, and provide us your information and the services, rooms, and tour packages you offer!
                </p>
            </SupplierPartnerCard>
            <div className='mt-5 pt-5'>
                <SupplierPartnerCard content="2" image="1" imageUrl={supplierPartnerImage}>
                    <h3 className="suppplier-partner-title">Create the experience for your guest.</h3>
                    {
                        createExperience.map((item) => (
                            <Fragment key={item.id}>
                                <h5 className="pt-4 fw-600 text-primary">{item.title}</h5>
                                <p className="suppplier-partner-subtitle">
                                    {item.content}
                                </p>
                            </Fragment>
                        ))
                    }
                </SupplierPartnerCard>
            </div>
        </div>
    )
}

export default SupplierPartnerSection  