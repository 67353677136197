import { Alert } from "@mantine/core";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { IoAlertCircleOutline } from "react-icons/io5";

import { useCancelBooking } from "../../hooks/useCancelBooking";
import MantineCustomModal from "../molecules/modals/MantineCustomModal";

const CancelBooking = (props) => {
  const {show , setShow , refundableAmount , totalAmount} = props
  const history = useHistory();
  const { mutate, isLoading } = useCancelBooking();

  const handleSubmit = () => {
    mutate((props.bookingReference) , {
      onSuccess: () => {
        setShow(false);
        history.push("/bookings");
      },
    });
  };

  return (
    <MantineCustomModal
      show={show}
      setShow={setShow}
      // backdrop="static"
      // keyboard={false}
      title={
        <span className="font-weight-bold">
          Cancel your booking
        </span>
      }
    >
      <hr />
      <p>Cancellation policy</p>
      <Alert icon={<IoAlertCircleOutline /> } color="red">
        {
          refundableAmount ? (
            <>
             Are you sure? After you cancel your booking some of amount can be deducted through your payment as refund / cancellation policy of the company.
            <br />
            <span className="text-danger">As per company's cancellation policy you will get Rs.{refundableAmount} from total of Rs.{totalAmount}</span>
            </>
          )
          : (
            <>
            No charge for cancellation of booking.
            </>
          )
        }
    </Alert>
    <hr />
      <div className="float-right">
        <Button onClick={() => setShow(false)} variant="outline-danger">
          No , I don't want to cancel.
        </Button>
        <button
          type="submit"
          id="kt_sign_in_submit"
          className="btn btn-outline-primary text-white ml-3"
          disabled={isLoading}
          onClick={handleSubmit}
        >
          {!isLoading && <span className="indicator-label">Yes , cancel this booking</span>}
          {isLoading && (
            <span className="indicator-progress" style={{ display: "block" }}>
              Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
      </div>
    </MantineCustomModal>
  );
};

export default CancelBooking;

CancelBooking.propTypes = {
  bookingReference: PropTypes.string.isRequired,
};
