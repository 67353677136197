import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useParams, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Overlay } from "@mantine/core";

import Footer from "../../molecules/Footer";
import Header from "../../organisms/Header";
import { FallBackView } from "../FallBackView";
import { useGuestBooking } from "../../../hooks/reactQueryCustomHook/booking/useGuestBooking";
import {
  PaymentPriceDetailCard,
  PaymentProductDetails,
} from "./components/PaymentCheckoutCard";
import { useStringHook } from "../../../hooks/useStringHook";
import PaymentMethodWrapper from "./components/PaymentMethodWrapper";
import BookingTimer from "../../atoms/Timer";
import Error from "../Error";
import { useBookingReference } from "../../../hooks/useBookingReference";

const PaymentPage = () => {
  const { bookingReference } = useParams();
  const history = useHistory();
  const query = useStringHook();
  const {
    data: booking,
    isLoading,
    isError,
  } = useGuestBooking(bookingReference);
  const status = query.get("q");
  const { bookingReference: localStorageReference } = useBookingReference();
  console.log({ bookingReference });

  useEffect(() => {
    if (status === "fu") {
      toast.error(
        `Payment Failed !! please try again with another payment method`
      );
    }
  }, [status]);

  if (bookingReference === undefined) {
    history.push(`/payment/${localStorageReference}`);
    window.location.reload();
  }

  if (isLoading) return <FallBackView />;

  if (isError) return <Error />;

  const bookingReserved = booking?.is_reserved;

  return (
    <div>
      <Header />
      <div className="bg-light py-3">
        <Container>
          <Row>
            {/* payment methods lists */}
            <Col md={8}>
              <BookingTimer
                bookingUpdatedDate={booking?.updated_at}
                bookingReference={bookingReference}
                bookingCreatedDate={booking?.created_at}
                bookingReserved={bookingReserved}
              />
              <div>
                {!bookingReserved && <Overlay color="#fff" opacity={0.5} />}
                <PaymentMethodWrapper
                  isFree={booking?.is_free}
                  bookingReference={bookingReference}
                  price={Number(booking?.total_amount)}
                ></PaymentMethodWrapper>
              </div>
              {/* check expiration of booking */}
            </Col>
            {/* booking details */}
            <Col md={4}>
              <PaymentPriceDetailCard booking={booking} />
              <PaymentProductDetails booking={booking} />
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </div>
  );
};

export default PaymentPage;
