import React from 'react'
import { Link } from 'react-router-dom'
import { faBars, faBell, faPaperPlane, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import BreadcrumbTop from '../../atoms/BreadcrumbTop'
import ChatList from '../../molecules/ChatList'
import ChatMessage from '../../molecules/ChatMessage'
import DashboardSideBar from '../../molecules/DashboardSideBar'
import Footer from '../../molecules/Footer'
import Header from '../../organisms/Header'
import '../../../assets/styles/inbox.css'

const DashboardInbox = () => {

    const List = [{
        "variant": "active-chat",
        "title": "Sunil Rajput",
        "para": "Typing ...",
        "img": "https://ptetutorials.com/images/user-profile.png",
    },
    {
        "date": "15 mins ago",
        "title": "Sunil Rajput",
        "para": "Please check your emails on....",
        "img": "https://ptetutorials.com/images/user-profile.png",
    },
    {
        "date": "15 mins ago",
        "title": "Sunil Rajput",
        "para": "Please check your emails on....",
        "img": "https://ptetutorials.com/images/user-profile.png",
    },
    {
        "date": "15 mins ago",
        "title": "Sunil Rajput",
        "para": "Please check your emails on....",
        "img": "https://ptetutorials.com/images/user-profile.png",
    },
    {
        "date": "15 mins ago",
        "title": "Sunil Rajput",
        "para": "Please check your emails on....",
        "img": "https://ptetutorials.com/images/user-profile.png",
    },
    {
        "date": "15 mins ago",
        "title": "Sunil Rajput",
        "para": "Please check your emails on....",
        "img": "https://ptetutorials.com/images/user-profile.png",
    },
    {
        "date": "15 mins ago",
        "title": "Sunil Rajput",
        "para": "Please check your emails on....",
        "img": "https://ptetutorials.com/images/user-profile.png",
    }
    ];

    return (
        <div className="dashboard" >
            <Header />
            <div className="container" >
                <BreadcrumbTop title="Dashboard"
                    page="Profile"
                    active="Inbox" />
            </div>
            <div className="container" >
                <div className="row" >
                    <div className="col-md-3" >
                        <DashboardSideBar />
                    </div>
                    <div className="col-md-9" >
                        <div className="card p-3" >
                            <div className="messaging" >
                                <div className="inbox-msg d-flex" >
                                    <div className="inbox-people" >
                                        <div className="headind-srch" >
                                            <div className="srch-bar" >
                                                <div className="stylish-input-group" >
                                                    <input type="text" className="search-bar" placeholder="Search" />
                                                    <span className="input-group-addon" >
                                                        <button type="button" >
                                                            <FontAwesomeIcon icon={faSearch} />
                                                        </button> </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="inbox-chat" >
                                            {
                                                List.map(item => (
                                                    <ChatList
                                                        key={item}
                                                        variant={item.variant}
                                                        date={item.date}
                                                        title={item.title}
                                                        para={item.para}
                                                        img="https://ptetutorials.com/images/user-profile.png" />
                                                ))
                                            }
                                        </div>
                                    </div>
                                    <div className="mesgs" >
                                        <div className="chat-header d-flex mb-5 pb-5" >
                                            <div className="chat-img" >
                                                <img src="https://ptetutorials.com/images/user-profile.png" alt="images" />
                                            </div>
                                            <div className="ml-3" >
                                                <Link> Jenny Taylor </Link>
                                                <br />
                                                <span > Active Now </span>
                                            </div>
                                            <div className="ml-auto" >
                                                <Link to="" >
                                                    <FontAwesomeIcon icon={faBell} className="text-primary mr-3" />
                                                </Link>
                                                <Link to="" >
                                                    <FontAwesomeIcon icon={faBars} />
                                                </Link> </div> </div>
                                        <div className="msg-history" >
                                            <ChatMessage variant="incoming-msg" optional="received-withd-msg" para="Test, which is a new approach to have" message="recieved" />
                                            <ChatMessage variant="outgoing-msg" para="Test, which is a new approach to have" message="sent" />
                                            <ChatMessage variant="incoming-msg" optional="received-withd-msg" para="Test, which is a new approach to have" message="recieved" />
                                            <ChatMessage variant="outgoing-msg" para="Test, which is a new approach to have" message="sent" />
                                            <ChatMessage variant="incoming-msg" optional="received-withd-msg" para="Test, which is a new approach to have" message="recieved" />
                                            <ChatMessage variant="incoming-msg" optional="received-withd-msg" para="Test, which is a new approach to have" message="recieved" />
                                            <ChatMessage variant="outgoing-msg" para="Test, which is a new approach to have" message="sent" />
                                            <ChatMessage variant="incoming-msg" optional="received-withd-msg" para="Test, which is a new approach to have" message="recieved" />
                                        </div>
                                        <div className="type-msg" >
                                            <div className="input-msg-write" >
                                                <input type="text" className="write-msg" placeholder="Type a message" />
                                                <button className="msg-send-btn" type="button" >
                                                    <FontAwesomeIcon icon={faPaperPlane} />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default DashboardInbox