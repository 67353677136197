import moment from "moment";

import axios from "../../setup/axios/SetupAxios";

/*******************************************************************
 * Avaibility Bookings Routes
 ******************************************************************/

const packages = async ({
  where,
  total: count,
  sortType,
  page,
  latitude,
  longitude,
  address,
  isOffer,
  campaignCode,
}) => {
  const { data } = await axios.get(
    `/packages/all/availability?page=${page}&location[latitude]=${latitude}&location[longitude]=${longitude}&location[address]=${address}&filter[offer]=${isOffer}`,
    {
      params: {
        count,
        sort_order: sortType,
        where,
        search_by: "city",
        campaign_code: campaignCode,
      },
    }
  );
  return data;
};

const property = async (values) => {
  const {
    startDate,
    endDate,
    adultCount,
    childCount,
    roomCount,
    latitude,
    longitude,
    address,
    sortType,
    searchBy,
    isOffer,
    campaignCode,
    page,
  } = values;
  const { data } = await axios.get(
    `/properties/all/availability?page=${page}&location[latitude]=${latitude}&location[longitude]=${longitude}&location[address]=${address}&filter[offer]=${isOffer}`,
    {
      params: {
        from: startDate,
        to: endDate,
        adult: adultCount,
        child: childCount,
        room_count: roomCount,
        search_by: searchBy,
        sort_order: sortType,
        campaign_code: campaignCode,
      },
    }
  );
  return data;
};

const rooms = async ({
  pageParam = 1,
  propertySlug,
  startDate,
  endDate,
  adultCount,
  childCount,
  roomCount,
}) => {
  const from = moment(startDate).format("YYYY-MM-DD");
  const to = moment(endDate).format("YYYY-MM-DD");
  const { data } = await axios.get(
    `/properties/${propertySlug}/rooms/availabilities?page=${pageParam}&from=${from}&to=${to}&adult=${adultCount}&child=${childCount}&room_count=${roomCount}`
  );
  return data;
};

const productAvailablityCheck = async (bookingReference) => {
  const { data } = await axios.get(
    `/bookings/${bookingReference}/availability/check`
  );
  return data;
};

const searchByCity = async ({city}) => {
  const { data } = await axios.get(`/properties/all/availability?city=${city}&search_by=city&location[address]=${city}`, {
    params : {
      adult : 1,
      child : 0,
      from : '2023-09-14',
      to: '2023-09-20',
      room_count : 2,
    }
  });
  return data;
};

export const avaibilityService = {
  packages,
  property,
  rooms,
  productAvailablityCheck,
  searchByCity,
};
