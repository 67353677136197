import { Skeleton } from "@mantine/core";
import React from "react";
import { Card, Col } from "react-bootstrap";

const SkeletonCard = ({
  count = 1,
  className = "lg-4 md-6",
  type = "vertical-card",
}) => {
  return Array.from(new Array(count)).map((number, index) =>
    type === "vertical-card" ? (
      <Col className={`mb-4 col-${className}`} key={index}>
        <Card>
          <div className="card-img">
            <Skeleton height={250} width="100%" mb="xl" />
          </div>
          <Card.Body>
            <div className="card-title mt-3">
              <div className="justify-content-between align-items-center">
                <Skeleton height={20} width="100%" className="mb-2" />
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <div className="text-muted font-weight-light">
                  <Skeleton height={20} width={100} />
                </div>
                <div className="text-muted font-weight-light">
                  <Skeleton height={20} width={100} />
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
      </Col>
    ) : (
      <Col size={12} className={`${className} col-12`}>
        <Card className="mb-5">
          <div className="row no-gutters">
            <Col md={3}>
              <div className="card-img">
                <Skeleton height={200} width="100%" mb="xl" className="mb-0" />
              </div>
            </Col>
            <Col md={9}>
              <Card.Body>
                <div className="card-title mt-3">
                  <div className="justify-content-between align-items-center">
                    <Skeleton height={20} width="100%" className="mb-2" />
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="text-muted font-weight-light">
                      <Skeleton height={20} width={100} />
                    </div>
                    <div className="text-muted font-weight-light">
                      <Skeleton height={20} width={100} />
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Col>
          </div>
        </Card>
      </Col>
    )
  );
};

export default SkeletonCard;
