import { Container } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import DashboardSideBar from "../components/molecules/DashboardSideBar";
import Footer from "../components/molecules/Footer";
import Header from "../components/organisms/Header";

// import Sonnet from '../../components/Sonnet';

function DashboardLayout({ children }) {
  return (
    <>
      <div className="dashboard bookings pb-4">
        <Header />
        <Container>
          <Row>
            <Col md="3">
              <DashboardSideBar />
            </Col>
            <Col md="9">{children}</Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
}

export default DashboardLayout;
