import { createContext, useEffect, useState } from "react";

export const BookingReferenceContext = createContext("");

const initialValues = JSON.parse(localStorage.getItem('bookingReference')) || ''

export const BookingReferenceProvider = ({ children }) => {
    const [bookingReference , setBookingReference] = useState(initialValues);
    const handleBookingReference = (bookingRef) => {
        setBookingReference(bookingRef)
    }
    useEffect(() => {
        localStorage.setItem("bookingReference", JSON.stringify(bookingReference))
    } , [bookingReference])

    return (
        <BookingReferenceContext.Provider value={{ bookingReference, handleBookingReference }}>
            {children}
        </BookingReferenceContext.Provider>
    )
}