// export const getSession = () => {
//     return JSON.parse(localStorage.getItem('session'));
// };
export const getSession = () => {
    const session = localStorage.getItem("session")
  try {
    if(session){
        JSON.parse(localStorage.getItem("session"));
    }
  } catch (e) {
    return false;
  }
  return JSON.parse(localStorage.getItem("session"));
};

/**
 * setToken from localstorage
 */
export const setSessionInLocalStorage = (token) => {
  localStorage.setItem("session", JSON.stringify(token));
  return true;
};
