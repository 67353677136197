export const links = [
    {
        id: 1,
        to: '/',
        title: 'Hotels'
    },
    {
        id: 4,
        to: '/travel/home',
        title: 'Holiday Packages'
    },
    {
        id:3,
        to:'/deals',
        title:"Deals"
    }
    // {
    //   id: 3,
    //   to: '/blog',
    //   title: 'BLOG'
    // }
];
