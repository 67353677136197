import React, { Fragment } from "react";
import { Row, Col, Container } from "react-bootstrap";

import becomeSupplierImage from "../../assets/images/suppliers/3.jpg";
import { mockData } from "../../utils/mock/data";
import { becomeSupplier } from "../../utils/mock/data.json";
import MainHeading from "../atoms/MainHeading";

const BecomeSupplier = () => {
  return (
    <section className="become-supplier bg-light pb-5">
      <Container>
        <MainHeading
          text={mockData.sectionHeader.becomeSupplier.title}
          para={mockData.sectionHeader.becomeSupplier.subTitle}
        />
        <Row>
          <Col md={4}>
            <div>
              <h6 className="fw-600 text-primary">1. Sign up</h6>
              <p className="text-justify suppplier-partner-subtitle">
                Sign up and register your account as a supplier in order to
                access to our features for hotel registration, room information, facilities, tour packages, activities and so much more.
              </p>
            </div>
            <div className="mt-5">
              <h6 className="fw-600 text-primary">2. Add your information </h6>
              <p className="text-justify suppplier-partner-subtitle">
                Once your registration and documents have been approved, your
                account will be verified, you can start adding more information
                about your brand, your product, your services and the facilities
                you provide.
              </p>
            </div>
          </Col>
          <Col md={4}>
            <img
              src={becomeSupplierImage}
              alt="becomeSupplierImage"
              className="w-100 h-100"
            />
          </Col>
          <Col md={4}>
            <div>
              {becomeSupplier.map((item, index) => (
                <Fragment key={index}>
                  <h6 className={`fw-600 text-primary ${index === 1 && 'mt-5'}`}>{item.title}</h6>
                  <p className="text-justify suppplier-partner-subtitle">
                    {item.paragraph}
                  </p>
                </Fragment>
              ))}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default BecomeSupplier;
