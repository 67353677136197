import React from 'react';
import PropTypes from 'prop-types'
import '../../assets/styles/mainHeading.css';

const MainHeading = (props) => {
    const {
        text,
        para
    } = props;

    return (
        <div className="row">
            <div className="col-md-12">
                <div className="main-heading">
                    <h2>{text}</h2>
                    <div className="main-para mt-4">
                        <p>
                            {para}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MainHeading

MainHeading.propTypes = {
    text: PropTypes.string,
}