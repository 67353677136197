import React from "react";
import { Container } from "react-bootstrap";

import image from "../../assets/images/contact-us/contact-us.jpg";
import ScrollToTop from "../../_helpers/ScrollTop";
import Footer from "../molecules/Footer";
import Navbar from "../molecules/Navbar";
import Header from "../organisms/Header";
import { mockData } from "../../utils/mock/data";

const ContactUs = () => {
  return (
    <>
      <ScrollToTop />
      <div className="contact-us bg-light">
        <Navbar />
        <Header />
        <Container>
          <section className="py-5 w-100 w-md-50 mx-auto">
            <div className="row">
              <div className="col-md-6">
                <h3 className="text-primary mb-5">Contact us</h3>
                <p className="font-weight-bold">
                  Email :{" "}
                  <a href={`mailto:${mockData.companyContactDetails.email}`}>
                    <span className="font-weight-light">
                      {mockData.companyContactDetails.email}
                    </span>
                  </a>
                </p>
                <p className="font-weight-bold">
                  {" "}
                  Phone :{" "}
                  <span className="font-weight-light">
                    <a
                      href={`tel:${mockData.companyContactDetails.phoneNumber}`}
                    >
                      {mockData.companyContactDetails.phoneNumber}
                    </a>
                  </span>
                </p>
                <p className="font-weight-bold">
                  Post Box No :{" "}
                  <span className="font-weight-light text-capitalize">
                    {mockData.companyContactDetails.companyPostOffice}
                  </span>
                </p>
                <p className="font-weight-bold">
                  {" "}
                  Location :{" "}
                  <span className="font-weight-light text-capitalize">
                    {mockData.companyContactDetails.companyLocation}
                  </span>
                </p>
              </div>
              <div className="col-md-6">
                <img src={image} alt="contactImage" className="w-100 h-100" />
              </div>
            </div>
          </section>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default ContactUs;
