import React from "react";
import { Container } from "react-bootstrap";

import Footer from "../../molecules/Footer";
import Header from "../../organisms/Header";

const PrivacyCookies = () => {
  return (
    <>
      <div className="privacy-cookies">
        <Header />
        <Container className="my-5">
          <h4 className="main-title text-center text-primary">
            Privacy and Cookies
          </h4>
          <p className="main-paragraph">
            Your privacy is important to us. We value your trust and are
            committed to protecting and safeguarding any personal information
            you give us. This document, which we update from time to time,
            describes how we use and process your personal data and how we use
            cookies. It also tells you how you can contact us if you have
            questions about your personal information.
          </p>
          <p>
            <a href="https://www.suyatra.com.np">Suyatra.com.np</a> provides
            online travel services through its own websites and mobile apps and
            through other online platforms such as partners’ websites and social
            media. The information that follows applies to all of these
            platforms.
          </p>
          <section className="privacy">
            <h6 className="section-title">Privacy</h6>
            <h6 className="section-title">
              What kind of personal information does SuYatra Tech use?
            </h6>
            <p className="section-paragraph">
              When you make bookings, you’ll be asked for your name, address,
              telephone number, email address, payment details, the names of
              guests traveling with you and your preferences for your stay.
            </p>
            <p className="section-paragraph">
              To make it easier to manage your bookings, you can open a user
              account. This allows you to save your personal settings, review
              previous bookings and manage future reservations.
            </p>
            <p className="section-paragraph">
              When you visit our website/App, even if you don’t make a
              reservation, we may collect certain information, like your IP
              address, or browser, and information about your computer’s
              operating system, application version, language settings and pages
              that have been shown to you. If you’re using a mobile device, we
              might also collect data that identifies your mobile device,
              device-specific settings and characteristics and
              latitude/longitude details.
            </p>
            <p className="section-paragraph">
              We may also receive information about you when you use certain
              social media services.
            </p>
          </section>
          <section>
            <h6 className="section-title">
              Why does SuYatra Tech collect, use and share your personal data?
            </h6>
            <p className="section-paragraph">
              Reservations: First and foremost, we use your personal data to
              complete and administer your online reservation and forward your
              reservation details to the accommodation / holiday packages you
              have booked.
            </p>
            <p className="section-paragraph">
              Customer service: We provide international customer service 24/7
              from our local offices. Sharing your details with our global
              customer service allows for a quick response when you need us –
              including helping you find an appropriate service and any
              questions you might have about your reservation.
            </p>
            <p className="section-paragraph">
              Guest reviews: We may use your contact information to invite you
              to write a guest review after your stay/ activities used. This can
              help other travelers choose a place to stay / buy activities that
              suits them best.
            </p>
            <p className="section-paragraph">
              Account administration: We offer a user account facility on our
              website. We use the information you give us to administer this,
              allowing you to manage your bookings, take advantage of special
              offers, make future reservations more easily and manage your
              personal settings. Managing personal settings allows you to keep
              and share lists, share photos, see properties that you’ve searched
              for before, and see other information you’ve provided about
              accommodations and destinations. It also allows you to see any
              reviews you submitted about places you stayed in. If you want, you
              can share certain information in your user account by creating a
              public profile that’s associated with either your own first name
              or a screen name of your choice. The type of information you can
              share on this platform includes your photo, the names of places
              you’ve stayed in, your lists, your plans for future trips, your
              reviews and other information about services and destinations.
            </p>
            <p className="section-paragraph">
              Marketing activities: We also use your information for marketing
              activities, as permitted by law. For example: When you make a
              reservation with us or set up a user account, we may use your
              contact information to send you news about similar travel-related
              products and services. We also send our customers regular
              newsletters by email. You can opt out, or unsubscribe, from
              marketing communication at any time. Based on the information you
              share with us, individualized offers may be shown to you on the
              SuYatra Tech website, in mobile apps or on third-party websites,
              including social media sites.
            </p>
            <p className="section-paragraph">
              When we believe that a particular offer may be of interest to you,
              we may decide to make contact with you by phone. Other
              communications: There may be other times when we get in touch by
              email, by post, by phone or by texting you, depending on the
              contact information you share with us. There could be a number of
              reasons for this: We may need to respond to and handle requests
              you have made.
            </p>
            <p className="section-paragraph">
              If you haven’t finalized a reservation online, we may email you a
              reminder to continue with your reservation. We believe that this
              additional service is useful to you because it allows you to carry
              on with a reservation without having to search for the service
              items again or fill in all the reservation details from scratch.
              When you use our services, we may send you a questionnaire or
              invite you to provide a review about your experience with SuYatra
              Tech.
            </p>
            <p className="section-paragraph">
              We may also send you other material related to your reservation,
              such as how to contact SuYatra Tech if you need assistance while
              you’re away, or a summary of previous reservations you made using
              SuYatra Tech. Market research: We sometimes ask our customers to
              take part in market research. Any additional personal details that
              you give us as part of the market research will only be used with
              your consent.
            </p>
            <p className="section-paragraph">
              Fraud detection and prevention: We may use personal data for the
              detection and prevention of fraud and other illegal or unwanted
              activities.
            </p>
            <p className="section-paragraph">
              Improving our services: Finally, we use personal information for
              analytical purposes, to improve our services, enhance the user
              experience, and improve the functionality and quality of our
              online travel services.
            </p>
          </section>
          <section>
            <h6 className="section-title">
              How does SuYatra Tech use social media?
            </h6>
            <p className="section-paragraph">
              We use social media to promote our services partners’ properties
              and to promote, improve and facilitate our own services website.
              For example, we integrated social media plugins into the SuYatra
              Tech website. So when you click on one of the buttons and register
              with your social media account, information is shared with your
              social media provider, and possibly presented on your social media
              profile to be shared with others in your network.
            </p>
            <p className="section-paragraph">
              In addition to implementing these buttons, SuYatra Tech uses
              social media by maintaining accounts on several social media sites
              and by offering social apps. These social media services may allow
              you to share information with SuYatra Tech. When you register with
              a social media app, you’ll be told which information will be
              shared with SuYatra Tech. The information you choose to share with
              us may include the basic information that’s already available in
              your social media profile, email address, status updates and your
              friends list. This information is necessary to create a unique
              user experience either in the app itself or on our website. It
              facilitates things like personalizing our website to suit your
              needs, connecting you with your friends on travel destinations and
              analyzing and enhancing our travel-related services.
            </p>
            <p className="section-paragraph">
              We may also enable you to sign in to SuYatra Tech services with
              your social media accounts. Your social media provider will be
              able to tell you more about how they use and process your data in
              such cases.
            </p>
          </section>
          <section>
            <h6 className="section-title">
              How does SuYatra Tech share your data with third parties?
            </h6>
            <p className="section-paragraph">
              In certain circumstances, we may share your personal data with
              third parties.
            </p>
            <p className="section-paragraph">
              The accommodation / activities you booked: In order to complete
              your reservation, we need to transfer relevant reservation details
              to the services (hotels/rentals/cars /tours/activities) you booked
              with. This may include information like your name, contact and
              payment details, the names of guests traveling with you and any
              preferences you specified when making a booking.
            </p>
            <p className="section-paragraph">
              SuYatra Tech office: In order to support you during the
              reservation process and throughout your stay, your details may be
              shared with subsidiaries of the SuYatra Tech corporate family. To
              find out more about the SuYatra Tech corporate family, visit About
              SuYatra Tech. Your information might also be shared with other
              members of the SuYatra Tech group (for example: Agoda.com,
              Rentalcars.com and Kayak.com and more) for analysis to provide you
              with travel-related offers that may be of interest to you and to
              offer you customized service.
            </p>
            <p className="section-paragraph">
              Third-party service providers: We may use third-party service
              providers to process your personal information on our behalf for
              the purposes specified above. For example, we may use service
              providers to send the reservation information on our behalf to the
              service item you just booked, or we may instruct third parties to
              contact you. When a reservation requires it, we may also work with
              third-party payment service providers to facilitate payment or
              payment guarantees. We also work with third-party advertisement
              networks to market our accommodation and services on other
              platforms, or involve third-party providers for analytical
              purposes. These third parties involved in any of these services
              will be bound by confidentiality agreements and will not be
              allowed to use your personal information for any purposes other
              than those specified above.
            </p>
            <p className="section-paragraph">
              Competent authorities: We work with partner websites around the
              world to distribute the service items listed on our website and
              mobile apps (Hotels/Rentals/Cars /Tour/Activities). When you make
              a reservation on one of these business partners’ websites, certain
              personal information that you give them may be shared with us.
              Similarly, we may share information with this business partner,
              for example, as part of the administration of your account, in
              order to manage your reservation, so that they can address queries
              related to your reservation and for marketing purposes. In this
              context, your information is governed by the privacy policies of
              these business partners.
            </p>
            <p className="section-paragraph">
              Business partners: We work with business partners around the world
              to distribute or advertise services and to help our business
              partners distribute and advertise their travel-related services.
              This may mean that their services are integrated into our website
              or they have been enabled to show a customized advertisement on
              our website – or we are advertising on theirs. When you make a
              reservation on one of our business partners’ websites, certain
              personal data that you give them will be forwarded to us.
            </p>
            <p className="section-paragraph">
              Certain business partners may receive your personal data from us
              if requested by you. When you make a reservation on a business
              partners’ website, please read the privacy policies on these
              business partners’ websites for more information.
            </p>
          </section>
          <section>
            <h6 className="section-title">
              How does SuYatra Tech use mobile devices?
            </h6>
            <p className="section-paragraph">
              We have free apps for a variety of mobile devices and use versions
              of our regular website that have been optimized for mobile. These
              apps and mobile websites process your personal details in a
              similar way as our website does – and they also allow you to use
              location services to find services nearby.
            </p>
          </section>
          <section>
            <h6 className="section-title">
              How does SuYatra Tech use guest reviews and other
              destination-related information you share with us?
            </h6>
            <p className="section-paragraph">
              After your use any service booked through us, you’ll be invited to
              submit a guest review, which may ask for information about the
              service item, the surrounding areas, and the destination. If you
              don’t want your name to show with your review, you can use your
              screen name (which you can choose in your user account) or it can
              be displayed anonymously. By completing a guest review, you’re
              agreeing that it can be displayed (as further described in our
              Terms and Conditions) on, for example, the relevant service
              information page on our websites, in our mobile apps, in our
              social media accounts and in social apps, or on the website of the
              relevant property or on our business partner’s website, to inform
              other travelers about the quality of the service item you used.
            </p>
            <p className="section-paragraph">
              If you indicate that a guest review was helpful – or not helpful –
              we will aggregate this with feedback from other customers in order
              to sort and prioritize guest reviews. We may use the information
              in your lists or in other destination-related information you
              share with us in an anonymous format to help other travelers find
              the right destination.
            </p>
          </section>
          <section className="cookies">
            <h6 className="section-title">Cookies</h6>
            <h6 className="section-title">What is a cookie?</h6>
            <p className="section-paragraph">
              A cookie is a small amount of data that is placed in the browser
              of your computer or on your mobile device. This Privacy and
              Cookies Policy applies to cookies and similar technologies
              (hereafter together referred to as “cookies”).
            </p>
            <h6 className="section-title">Why are cookies used?</h6>
            <p className="section-paragraph">
              Web pages have no memory. If you are surfing from page to page
              within a website, you will not be recognized as the same user
              across pages. Cookies enable your browser to be recognized by the
              website. So cookies are mainly used to remember the choices you
              have made – choices such as the language you prefer and the
              currency you use. They will also make sure you are recognized when
              you return to a website.
            </p>
            <h6 className="section-title">Do all cookies do the same thing?</h6>
            <p className="section-paragraph">
              No, there are different types of cookies and different ways of
              using them. Cookies can be categorized according to their
              function, their lifespan and according to who places them on a
              website.
            </p>
            <h6 className="section-title">How are cookies used?</h6>
            <p className="section-paragraph">
              Our website uses the following types of cookies:
            </p>
            <p className="section-paragraph">
              Technical cookies: We try to provide our visitors with an advanced
              and user-friendly website that adapts automatically to their needs
              and wishes. To achieve this, we use technical cookies to show you
              our website, to make it function correctly, to create your user
              account, to sign you in and to manage your bookings. These
              technical cookies are absolutely necessary for our website to
              function properly.
            </p>
            <p className="section-paragraph">
              Functional cookies: We also use functional cookies to remember
              your preferences and to help you use our website efficiently and
              effectively. For example, we remember your preferred currency and
              language, your searches and the property you viewed earlier. These
              functional cookies are not strictly necessary for the functioning
              of our website, but they add functionality for you and enhance
              your experience.
            </p>
            <p className="section-paragraph">
              Analytics cookies: We use these cookies to gain insight on how our
              visitors use the website, to find out what works and what doesn’t,
              to optimize and improve our website and to ensure we continue to
              be interesting and relevant. The data we gather includes which web
              pages you viewed, which referring/exit pages you entered and left
              from, which platform type you used, date and time stamp
              information and details such as the number of clicks you make on a
              given page, your mouse movements and scrolling activity, the
              search words you use and the text you type while using our
              website. We also use analytics cookies as part of our online
              advertising campaigns to learn how users interact with our website
              after they’ve been shown an online advertisement, which may
              include advertisements on third-party websites. We will not know
              who you are, and we only obtain anonymous data.
            </p>
            <p className="section-paragraph">
              Commercial cookies: We use these to show you Traveler
              advertisements on other websites. This is called “retargeting” and
              it aims to be based on your browsing activities on our website,
              such as the destinations you’ve been searching for, the properties
              you’ve viewed and the prices you’ve been shown.
            </p>
            <h6 className="section-title">
              How long do SuYatra Tech cookies stay active?
            </h6>
            <p className="section-paragraph">
              The cookies we use have varying lifespans. The maximum lifespan we
              set on some is five years from your last visit to our website. You
              can erase all cookies from your browser any time you want. To
              remove SuYatra Tech cookies from your browser, go to the following
              link (please note that when you click on this link, all SuYatra
              Tech cookies will be removed immediately): Purge Cookies.
            </p>
            <h6 className="section-title">
              How can you recognize SuYatra Tech cookies?
            </h6>
            <p className="section-paragraph">
              You can find our cookies in your browser settings.
            </p>
            <h6 className="section-title">
              Does SuYatra use third-party marketing and analytics cookies?
            </h6>
            <p className="section-paragraph">
              Yes SuYatra Tech uses the services of trusted and recognized
              online advertising and marketing companies. SuYatra Tech may also
              use third-party providers for analytical purposes.
            </p>

            <h6 className="section-title">
              Who has access to SuYatra Tech cookie data?
            </h6>
            <p className="section-paragraph">
              Only SuYatra Tech has access to SuYatra Tech cookies. Cookies
              placed by third parties can be accessed by these third parties
            </p>
            <h6 className="section-title">
              How can you manage your cookie preferences?
            </h6>
            <p className="section-paragraph">
              Using your browser settings in, for example, Internet Explorer,
              Safari, Firefox or Chrome, you can set which cookies to accept and
              which to reject. Where you find these settings depends on which
              browser you use. Use the “Help” function in your browser to locate
              the settings you need.
            </p>
            <p className="section-paragraph">
              If you choose not to accept certain cookies, you may not be able
              to use some functions on our website. Plus, opting out of an
              online advertising network doesn’t mean that you won’t receive or
              be subject to online advertising or marketing analysis. It means
              that the network you opted out of will no longer deliver ads
              tailored to your web preferences and browsing patterns.
            </p>
          </section>
          <section className="contact">
            <h6 className="section-title">Contact</h6>
            <h6 className="section-title">
              How can you control the personal data you have given to SuYatra
              Tech?
            </h6>
            <p className="section-paragraph">
              You always have the right to review the personal information we
              keep about you. You can request an overview of your personal data
              by emailing us at <b>info@suyatra.com.np</b> please write
              “Request personal information” in the subject line of your email
              and include a copy of your identity card to help us prevent
              unauthorized individuals from accessing your personal data.
            </p>
            <p className="section-paragraph">
              If the personal information we have for you is incorrect, we will
              update it at your request. You can also ask us to remove your
              personal data from our customer database by sending an email to{" "}
              <b>info@suyatra.com.np</b> with “Request for removal of
              personal information” in the subject line. However, we may need to
              retain certain information, for example, for legal or
              administrative purposes, such as record keeping or to detect
              fraudulent activities. You can delete your user account at any
              time by signing into your account on the Traveler website and
              choosing to remove your account.
            </p>
            <p className="section-paragraph">
              Who is responsible for the processing of personal data on the
              SuYatra Tech website and apps?
            </p>
            <p className="section-paragraph">
              SuYatra Tech controls the processing of personal data on its
              websites and mobile apps. SuYatra Tech is a private limited
              liability company, incorporated under the laws and has its online
              offices in suyatra.com.np . If you have any suggestions or
              comments about this privacy notice, please send an email to{" "}
              <b>info@suyatra.com.np</b>
            </p>
          </section>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyCookies;
