import React, { useState } from "react";
import { useParams } from "react-router-dom";

import Header from "../organisms/Header";
import Footer from "../molecules/Footer";
import { FallBackView } from "./FallBackView";
import { categoryType } from "../../utils/categoryType";
import { useGuestBooking } from "../../hooks/reactQueryCustomHook/booking/useGuestBooking";
import { statusCheck } from "../../_helpers/statusCheck";
import BookingList from "./dashboard/BookingList";
import { Card, Col, Container } from "react-bootstrap";
import BookingProductCard from "../molecules/BookingProductCard";
import CancelBooking from "../modal/CancelBooking";
import { isBookingCancellable } from "../../_helpers/bookingFunctions";

const GuestView = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { bookingReference } = useParams();
  const { data: booking, isLoading } = useGuestBooking(bookingReference);
  if (isLoading) return <FallBackView />;

  const showCancelButton = isBookingCancellable(booking);

  return (
    <div className="guest-page">
      <Header />
      <Container>
        <Col md={8} className="mx-auto">
        <BookingProductCard data={booking?.product} bookingDetail={booking} />
        <Card>
        <Card.Body>
          <div className="d-flex justify-content-between mb-4">
            <div className="d-flex align-items-center">
              <h4>Booking Details</h4>
              <span className={`ml-2`}>{statusCheck(booking?.status)}</span>
            </div>
            {showCancelButton && (
              <div>
                <button
                  onClick={handleShow}
                  className="btn btn-sm btn-outline-danger"
                >
                  Cancel Booking
                </button>
              </div>
            )}
          </div>
          <hr />
          <BookingList booking={booking} CategoryType={categoryType} />
        </Card.Body>
        </Card>
        </Col>
        <CancelBooking
          show={show}
          setShow={setShow}
          handleShow={handleShow}
          refundableAmount={booking?.refundable_policy?.refundable_amount}
          totalAmount={booking?.refundable_policy?.user_paid_amount}
          bookingReference={bookingReference}
          handleClose={handleClose}
        />
      </Container>
      <Footer />
    </div>
  );
};

export default GuestView;
