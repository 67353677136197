//***************** api request for payment methods *************************

import axiosInstance from "../../setup/axios/SetupAxios";

//***************** wallet initializing starts *************************


const initiateEsewa = async ({bookingReference}) => {
  const response = await axiosInstance.get(`payment/esewa/initiate/${bookingReference}`)
  return response.data
}

const paymentWithEsewa = async ({ bookingReference, referenceId, amount }) => {
  return await axiosInstance.post(
    `/esewa/payment/verification/${bookingReference}`,
    {
      pid: bookingReference,
      rid: referenceId,
      amt: amount,
    }
  );
};

const paymentWithKhalti = async ({ bookingReference, returnUrl, amount }) => {
  return await axiosInstance.post(`/payment/khalti/initiate/${bookingReference}`, {
    return_url: returnUrl,
    amount, //amount in paisa for eg(amount * 100 as 1rs = 100 paisa)
  });
};

const paymentWithNicAsiaBank = async ({ bookingReference}) => {
  return await axiosInstance.get(`/payment/nic/initiate/${bookingReference}`);
};

//***************** wallet initializing ends *************************

//***************** payment post to  server or api *************************

const khaltiVerification = async (paymentOptions) => {
  const { orderId, referenceId, amount } = paymentOptions;
  return await axiosInstance.post(`/payment/khalti/verification`, {
    amount: Number(amount),
    order_id: orderId,
    reference_id: referenceId,
  });
};

const esewaVerification = async (data) => {
  return await axiosInstance.post(`/payment/esewa/verification`, {
    data
  });
}

const getPaymentMethods = async () => {
  const {data} = await axiosInstance.get(`/settings`);
  return data;
}

export const paymentServices = {
  paymentWithEsewa,
  initiateEsewa,
  paymentWithKhalti,
  khaltiVerification,
  paymentWithNicAsiaBank,
  getPaymentMethods,
  esewaVerification
};
