import React from "react";
import PropTypes from "prop-types";

import "../../../assets/styles/card.css";
import { formatDate } from "../../../utils/formatdate";
import DangerouslySetHtml from "../../atoms/DangerouslySetHtml";

const BlogCard = ({ blog }) => {
  return (
    <div className="card">
      <img src={blog?.files[0]?.url} className="card-image w-100" alt="blogs" />
      <div className="card-body">
        {/* text-part-of-card  */}
        <div className="card-title">
          <h4 className="mb-0 text-capitalize">{blog?.title}</h4>
        </div>
        <div className="card-description">
          <DangerouslySetHtml contents={blog?.body?.slice(0, 120)} />
        </div>
        {/* profile-and-avatar  */}
        <div className="profile mt-3">
          <div>
            <span className="text-dark">By: </span>
            <span className="fw-600">{blog?.users?.name}</span>
          </div>
          <div>
            <span className="text-dark small">
              Created at : {formatDate(blog.created_at)}
            </span>
          </div>
        </div>
      </div>
    </div>
    // <div className="card">
    //         <div className="card-img">
    //             <img src={image} alt={alt ? alt : "image"} />
    //         </div>
    //         <div className="card-body h-100">
    //             <div className="card-title">
    //                 <h4>{title}</h4>
    //             </div>
    //             {children}
    //         </div>
    // </div>
  );
};

export default BlogCard;

BlogCard.propTypes = {
  alt: PropTypes.string,
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
};

BlogCard.defaultProps = {
  alt: "Blogimage",
};
