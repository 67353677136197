import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

const SupplierPartnerCard = (props) => {
    return (
        <Container>
            <Row>
                <Col md={6} className={`order-md-${props.content} ${Number(props.image) === 1 && "offset-md-1"}`}>
                    {props.children}
                </Col>
                <Col md={5} className={`order-md-${props.image} ${Number(props.content) === 1 && "offset-md-1"}`}>
                    <div className='supplier-image' style={{backgroundImage : `url(${props.imageUrl})`}}></div>
                    {/* <img src={props.imageUrl} alt={props.imageUrl || "supplier-partner-card"} className='w-100 h-100' /> */}
                </Col>
            </Row>
        </Container>
    )
}

export default SupplierPartnerCard
