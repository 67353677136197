import React from 'react';
import PropTypes from 'prop-types'

import logo from '../../assets/images/bird1.png';

const Error = (error) => {

    return (
        <div className="error-wrapper d-flex flex-column justify-content-center align-items-center">
            <div className='error-404  mt-5'>
                <img src={logo} alt="suyatra logo" />
            </div>
            <h4 className="fw-bold fs-1 my-2">
                Error 500!
            </h4>
            {
                error && <p className='text-danger'>
                    {error}
                </p>
            }
            <span className='text-md fs-2 '>Please contact to administration</span>
            <button className="btn btn-primary my-3">Retry</button>
        </div>
    )
}

Error.propTypes = {
    error: PropTypes.string
}
export default Error

