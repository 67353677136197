import { useQuery } from "react-query";

import { avaibilityService } from "../../../services/api/avaibility";

export const usePropertyAvaibility = (options) => {
  return useQuery(['properties', { ...options }], () => avaibilityService.property(options),
    {
      keepPreviousData: true,
    }
  );
};
