import { useQuery } from "react-query";

import { bookingServices } from "../../../services/api/bookings";

export const useGuestBooking = (referenceId, otherQueries) => {
  return useQuery(
    ["booking", referenceId],
    () => bookingServices.guestBooking(referenceId),
    {
      refetchInterval: 60000,
      enabled : !!referenceId
    }
  );
};
