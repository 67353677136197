import React from "react";

import Header from "../components/organisms/Header";
import Footer from "../components/molecules/Footer";

const MainLayout = ({children}) => {
  return (
    <div className="master-layout">
      <Header />
      
      {children}
      <Footer />
    </div>
  );
};

// MainLayout.prototype = {
//   children: PropTypes.oneOfType([
//     PropTypes.arrayOf(PropTypes.node),
//     PropTypes.node,
//   ]).isRequired,
// };

export default MainLayout;
