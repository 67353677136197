import React from "react";

import { AiOutlineStar } from "react-icons/ai";
import { TbGlassFull, TbBuildingBank, TbBuilding } from "react-icons/tb";
import { GiModernCity, GiSecretDoor } from "react-icons/gi";
import { Row, Col, Card } from "react-bootstrap";
import DescriptionTitle from "../atoms/DescriptionTitle";

const CompanyInformation = (props) => {
  const { title, city, floors, rooms, bars, data } = props;

  const information = [
    {
      id: 1,
      name: "Company",
      value: title,
      icon: <TbBuildingBank size={25}  />,
    },
    {
      id: 2,
      name: "City",
      value: city,
      icon: <GiModernCity size={25}  />,
    },
    {
      id: 4,
      name: "Floor",
      value: floors,
      icon: <TbBuilding size={25}  />,
    },
    {
      id: 5,
      name: "Rooms",
      value: rooms,
      icon: <GiSecretDoor size={25}  />,
    },
    {
      id: 6,
      name: "Bars",
      value: bars,
      icon: <TbGlassFull size={25}  />,
    },
    {
      id: 7,
      name: "Rating",
      value: `${data?.star_rating} star`,
      icon: <AiOutlineStar size={25} />,
    },
  ];

  return (
    <Card>
      <Card.Body>
        <DescriptionTitle title="Property Highlights" />
        <hr />
        <div>
          <Row>
            <Col sm lg={12}>
              <ul>
                {information.map((item) => (
                  <li key={item?.id} className="mb-2">
                    <div className="d-flex">
                      <div>{item.icon}</div>
                      <div className="ml-4">
                        <span className="font-weight-light">
                          {item?.name}:
                        </span>
                          <p className="font-weight-bold mb-1">
                          {item?.value}
                          </p>
                      </div>
                    </div>
                    {/* <FontAwesomeIcon icon={item?.icon} className="mr-2" /> */}
                  </li>
                ))}
              </ul>
            </Col>
          </Row>
        </div>
      </Card.Body>
    </Card>
  );
};

export default CompanyInformation;
