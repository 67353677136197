import React from 'react'
import { Card, Col, Row } from 'react-bootstrap'

const ProductInfo = ({ data }) => {
    return (
        <Card className="mb-3">
            <Card.Body>
                <h6>{data.property_name || data.title}</h6>
                <hr />
                <Row className="no-gutters">
                    <Col md="3">
                        <img
                            style={{ aspectRatio: "16 / 9", objectFit: 'cover' }}
                            width="100%"
                            height="100%"
                            src={data?.thumbnail?.url}
                            alt={data?.title}
                        />
                    </Col>
                    <Col md="9">
                        <Card.Body className="pt-0 px-3">
                            <span className="badge badge-info mb-3 mr-2">{data?.category}</span>
                            <div className="card-title text-left">
                                <h6 className="mb-0 text-capitalize">
                                    {data?.title}
                                </h6>
                            </div>
                                <div className="d-flex justify-content-between align-items-center my-2">
                                    <span className="font-weight-bold">
                                        Rs. {data?.offer_price || data?.unit_price || data?.room_rate}
                                    </span>
                                </div>
                                {data?.description?.slice(0, 100)}
                        </Card.Body>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

export default ProductInfo