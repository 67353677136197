export const PAYMENT_METHOD = {
    ESEWA : 'esewa-payment',
    KHALTI : 'khalti-payment',
    NICASIA : 'nic-payment'
}

export const PAYMENT_METHOD_UNIQUE_KEY = {
    key_esewa : 'PE',
    key_khalti : 'PK',
    key_nicasia : 'PN'
}