import React from "react";
import Header from "../organisms/Header";

import "../../assets/styles/termsAndCondition.css";
import Footer from "../molecules/Footer";

const TermsAndCondition = () => {
  return (
    <>
      <div className="terms-condition mb-5 bg-light pb-5">
        <Header />
        <section className="mt-5">
          <div className="container">
            <p className="mb-3">
              By using this website, you hereby agree to the following terms and
              conditions:
            </p>
            <p>
              Please note that these are the general Terms and Conditions as
              imposed by suyatra.com.np (SuYatra Tech Pvt. Ltd.)
            </p>
            <p className="heading mt-5">Summary</p>
            <p className="mb-5">
              We are an online travel marketplace platform that sells Travel
              services on behalf of Third Party Travel Providers (Providers)
              including airlines, tour and travel operators, car hirers and
              accommodation providers.
            </p>
            <p dir="ltr" className="heading">
              1. Booking and Confirmation:
            </p>
            <ul>
              <li dir="ltr">
                <p dir="ltr">
                  Once you have selected the arrangements you would like, you
                  must complete the details on the website, including the
                  payment details.
                </p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  Every booking is fully confirmed before you receive a final
                  confirmation and before your credit card is charged.
                </p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  We will send you two emails within 24 hours, if not
                  immediately (Note: a SuYatra Tech Pvt. Ltd. "Welcome" email is
                  also sometimes sent to new customers):
                </p>
              </li>
              <ul>
                <li dir="ltr">
                  <p dir="ltr">
                    The First email will state that your "SuYatra Tech Pvt. Ltd.
                    Booking is Being Processed"; and
                  </p>
                </li>
                <li dir="ltr">
                  <p dir="ltr">
                    The Second email provides Confirmation of the booking with
                    the voucher information ( SuYatra Tech Pvt. Ltd. Booking has
                    been Confirmed). If we cannot confirm the booking, then the
                    second email will say that the booking cannot be confirmed.
                    If we are unable to accept your booking we shall not process
                    your payment.
                  </p>
                </li>
              </ul>
              <li dir="ltr">
                <p dir="ltr">
                  If you have not received your confirmation voucher within 24
                  hours of booking, please check your "Bulk Mail" or "Junk Mail"
                  folders of your email system. You may also "login" at any time
                  to <a href="http://www.suyatra.com.np">www.suyatra.com.np</a>{" "}
                  to check your order. If you still do not see your confirmation
                  voucher, please contact us:{" "}
                  <a href="mailto:info@suyatra.com.np">
                    info@suyatra.com.np
                  </a>
                  <br />
                  <br />
                </p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  The Voucher binding contract will come into force at the time
                  we send out our confirmation email, and until then we shall be
                  under no liability to you whatsoever.
                </p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  The name of the leading passenger should be correctly given at
                  the time of booking and will appear on the voucher. The
                  voucher is not transferable and changes cannot be made at a
                  later date.
                </p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  An order can include two or more different tour bookings. This
                  website will not stop you from making incorrect orders such as
                  booking two tours in different places for the same dates, or a
                  tour in one place and show tickets in another. If you make an
                  error in this kind of double booking, we are unable to give
                  you a refund.
                </p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  It is essential that you print this voucher and present it
                  upon commencement of your activity or tour. Valid photo ID is
                  required for the lead passenger and must match the traveler's
                  name as indicated on the voucher as well as the supplier
                  records. First and last names are sufficient.
                </p>
              </li>
            </ul>
            <p dir="ltr" className="heading">
              2. Cancellation Policy:
            </p>
            <ul>
              <li dir="ltr">
                <p dir="ltr">
                  Each product has its own cancellation policies as per
                  suppliers products and terms and conditions. Please review the
                  terms and conditions for each product before booking.
                </p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  If you reject (cancel) the activity, the cancellation
                  conditions stated in the product description as well as on
                  your voucher apply. SuYatra Tech Pvt. Ltd. advises the user to
                  carefully read the information in the product description
                </p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  If you want to cancel your booking manually, kindly check your
                  booking confirmation email. There are contact details of the
                  service provider and you can contact them directly or you are
                  welcome to contact our customer support center.
                </p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  The Supplier reserves the right to request higher, specific
                  compensation instead of the aforementioned flat-rate fees, if
                  the Supplier can prove that it has incurred much higher
                  expenses than the respectively applicable flat-rate fee. In
                  this case the Supplier is obliged to provide specific figures
                  and evidence of the compensation requested, taking into
                  account the saved expenses and any other use of the services.
                </p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  The Supplier can cancel the activity on the agreed date
                  without observing a period of notice, if weather conditions,
                  official measures, strikes or other unforeseeable or
                  unavoidable external conditions (in particular force majeure)
                  make the execution of the activity impossible, make it
                  considerably more difficult or endanger it. In this case the
                  paid price is refunded.
                </p>
              </li>
            </ul>
            <p dir="ltr" className="heading">
              3. Payment:
            </p>
            <ul>
              <li dir="ltr">
                <p dir="ltr">Credit cards accepted: MasterCard, Visa Card.</p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  We also accept local bank wire transfers, eSewa, Fonepay,
                  Connect IPS, Khalti and more all the Nepal’s local digital
                  Payment Gateways.
                </p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  Full payment by credit card is required to make a Booking.
                </p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  SuYatra Tech Pvt. Ltd. does not charge a service fee for
                  processing credit card payments.
                </p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  In the event we are unable to confirm your booking we shall
                  not process your payment.
                </p>
              </li>
            </ul>
            <p dir="ltr" className="heading">
              4. Supplier Payments
            </p>
            <p dir="ltr">
              After a customer has utilized a service, the supplier account
              dashboard provides a PAYOUT tool for requesting payouts. For a
              brief period, we will issue payouts once the customer has used the
              services.
            </p>
            <p className="my-2">Our standard payout schedule is as follows:</p>
            <p dir="ltr">
              Payments will be made via bank transfer or Nepal’s local digital
              Payment Gateways. Payments generally become due and payable by the
              fifth business day of the month following your tour’s departure
              date. For instance, if a tour or hotel departs on 1st January
              2024, payment will be due to the Operator by the fifth business
              day of the following month, which would be 7th February 2024.
            </p>
            <p dir="ltr">
              Bank transfers will be processed by one or more subsidiaries of
              Suyatra Tech Pvt. Ltd., depending on the location of the
              suppliers. This may result in multiple bank transfers for each
              payout. Given the high volume of transactions we process each
              month, we have established automated workflows and structured
              processes. Currently, we are performing electronic payments, and
              due to our internal procedures, it is not possible to consolidate
              all payments into a single transaction.
            </p>
            <p dir="ltr">
              We will cover the bank fees associated with these transfers,
              except for any fees charged by intermediary banks or your bank.
              These fees will be your responsibility. We are also pleased to
              make payments via credit card and Nepal’s local digital Payment
              Gateways.
            </p>
            <p dir="ltr" className="heading">
              5. Prices
            </p>
            <p dir="ltr">
              Prices are quoted per person or per room, unless otherwise
              specified. Prices are based on local tariffs. Price quotations are
              subject to change without notice until a booking has been
              confirmed. All prices should be set by the service providers.
            </p>
            <p dir="ltr" className="heading">
              6. Not Included:
            </p>
            <ul>
              <li dir="ltr">
                <p dir="ltr">
                  Any applicable park fees, passenger facility charges, etc. are
                  somewhat included but are all mentioned by suppliers.
                </p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  Tips/gratuities to tour directors, drivers, and local guides;
                  baggage and personal insurance; beverages and food not listed
                  under inclusions in the travel product (these items will be
                  billed to you before leaving the activities/hotel/restaurant);
                  and all other items of a personal nature. Please review the
                  product description details provided by each supplier and each
                  product before making your bookings.
                </p>
              </li>
            </ul>
            <p dir="ltr" className="heading">
              7. Travel Insurance:
            </p>
            <ul>
              <li dir="ltr">
                <p dir="ltr">
                  We strongly recommend purchasing a comprehensive travel
                  insurance policy at the time of booking. Bookings might have
                  NOSHOW due to unforeseen problems, and in this case, travel
                  insurance may cover your travel fees.
                </p>
              </li>
            </ul>
            <p dir="ltr" className="heading">
              8. Booking Arrangements:
            </p>
            <ul>
              <li dir="ltr">
                <p dir="ltr">
                  For all booking arrangements, the individual making the
                  booking is considered to have accepted the booking conditions
                  on behalf of all persons named in the booking. The contract is
                  governed by and subject to the Terms &amp; Conditions. Any
                  changes to these Terms and Conditions must be made in writing
                  and authorized by Suyatra Tech Pvt. Ltd.
                </p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  Vouchers for each booking segment will be provided by Suyatra
                  Tech Pvt. Ltd.
                </p>
              </li>
            </ul>
            <p dir="ltr" className="heading">
              9. Responsibility:
            </p>
            <ul>
              <li dir="ltr">
                <p dir="ltr">
                  SuYatra Tech Pvt. Ltd does not operate or control the
                  activities, services, or goods booked through this site. By
                  using this site, you agree that SuYatra Tech Pvt. Ltd is not
                  liable for any losses or injuries you may incur as a result of
                  your booking or purchasing tickets, or any other services or
                  goods through SuYatra Tech Pvt. Ltd. Please ensure to review
                  all terms and conditions before making any bookings.
                </p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  The name and address of the relevant supplier can be found on
                  your voucher. The voucher governs your relationship with the
                  supplier. This means that any decisions on Supplier terms and
                  conditions, cancellations, exchanges, and refunds will be
                  determined by the supplier.
                </p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  Suppliers who agree to these terms acknowledge that Suyatra
                  Tech Pvt. Ltd., its partners, agents, and suppliers, are not
                  liable for any injury, damage, loss, accident, delay,
                  liability, or expense to person or property due to any act of
                  default by any hotel, carrier, or other company or person
                  providing services included in the tours. Furthermore, Suyatra
                  Tech Pvt. Ltd., its partners, agents, and suppliers accept no
                  responsibility for any sickness, pilferage, labor disputes,
                  machinery breakdown, government restraints, acts of war and
                  terrorism, weather conditions, defect in any vehicle of
                  transportation, or any other causes beyond their control.
                  Suyatra Tech Pvt. Ltd. reserves the right to cancel, change,
                  or substitute any service and to decline to accept or retain
                  any tour members at any time for any reason, including,
                  without limitation, an insufficient number of participants.
                  The sole responsibility of any airline used in any tour is
                  limited to that set out in the passenger contract evidenced by
                  the ticket. All travel documents/vouchers are issued subject
                  to the terms and conditions specified by Suyatra Tech Pvt.
                  Ltd., its agents, and suppliers.
                </p>
              </li>
            </ul>
            <p dir="ltr" className="heading">
              10. Availability, Errors and Inaccuracies
            </p>
            <p dir="ltr">
              We continually update product and service offerings on the
              Service. However, we may experience delays in updating information
              on the Service and in our advertising on other websites. The
              information found on the Service may contain errors or
              inaccuracies and may not be complete or current. Products or
              services may be mispriced, described inaccurately, or unavailable
              on the Service, and we cannot guarantee the accuracy or
              completeness of any information found on the Service. We therefore
              reserve the right to change or update information and to correct
              errors, inaccuracies, or omissions at any time without prior
              notice.
            </p>
            <p dir="ltr" className="heading">
              11. Links To Other Web Sites
            </p>
            <p>
              Our Service may contain links to third-party websites or services
              that are not owned or controlled by Suyatra Tech Pvt. Ltd. Suyatra
              Tech Pvt. Ltd. has no control over, and assumes no responsibility
              for, the content, privacy policies, or practices of any third-
              party websites or services. We do not warrant the offerings of any
              of these entities or their websites.
            </p>
            <br />
            <p>
              You acknowledge and agree that Suyatra Tech Pvt. Ltd. shall not be
              responsible or liable, directly or indirectly, for any damage or
              loss caused or alleged to be caused by or in connection with the
              use of or reliance on any such content, goods, or services
              available on or through any such third-party websites or services.
            </p>
            <br />
            <p>
              We strongly advise you to read the terms and conditions and
              privacy policies of any third-party websites or services that you
              visit.
            </p>
            <p dir="ltr" className="heading">
              12. Governing Law
            </p>
            <p dir="ltr">
              This Agreement is governed by the laws of Nepal. By using the
              SuYatra Online Travel Marketplace platform, you expressly agree to
              the exclusive jurisdiction of the courts of Nepal for any disputes
              or claims arising from or in connection with this website. Any
              unauthorized use of this website in jurisdictions that do not
              recognize all stipulations of these terms and conditions,
              including this paragraph, is strictly prohibited.
            </p>
            <p dir="ltr" className="heading">
              13. Company Name and Registered Access
            </p>
            <p dir="ltr">
              SuYatra Tech Pvt. Ltd. Uttar Dhoka, Lazimpat -2, Kathmandu, Nepal.
            </p>
            <p dir="ltr" className="heading">
              14. Updating Terms and Conditions
            </p>
            <p dir="ltr">
              The Company reserves the right to update and/or alter these terms
              and conditions at any time. It is the clients’ responsibility to
              familiarize themselves with them. The latest terms and conditions
              can always be found on the Company website
              <a href="http://www.suyatra.com.np/terms-condition">
                {" "}
                www.suyatra.com.np/terms-condition
              </a>{" "}
              and will supersede any previous versions.
            </p>
            <br />
            <p>June 2024</p>
          </div>
        </section>
        {/* <section className="mt-5">
          <div className="container">
            <h3 className="text-primary text-center mb-5">
              Terms and Conditions
            </h3>
            <div className="booking-confirmation">
              <h6 className="heading">1. Booking and Confirmation:</h6>
              <ul className="terms-condition-lists">
                <li>
                  Once you have selected the arrangements you would like, you
                  must complete the details on the website, including the
                  payment details.
                </li>
                <li>
                  Every booking is fully confirmed before you receive a final
                  confirmation and before your credit card is charged.
                </li>
                <li>
                  We will send you two emails within 24 hours, if not
                  immediately (Note: a Suyatra Tech Pvt. Ltd. "Welcome" email is
                  also sometimes sent to new customers):
                </li>
                <ul>
                  <li>
                    The First email will state that your "Suyatra Tech Pvt. Ltd.
                    Booking is Being Processed"; and
                  </li>
                  <li>
                    The Second email provides Confirmation of the booking with
                    the voucher information ( Suyatra Tech Pvt. Ltd. Booking has
                    been Confirmed). If we cannot confirm the booking, then the
                    second email will say that the booking cannot be confirmed.
                    If we are unable to accept your booking we shall not process
                    your payment.
                  </li>
                </ul>
                <li>
                  If you have not received your confirmation voucher within 24
                  hours of booking, please check your "Bulk Mail" or "Junk Mail"
                  folders of your email system. You may also "login" at any time
                  to www.suyatra.com.np to check your order. If you still do not
                  see your confirmation voucher, please contact us:
                  info@suyatra.com.np
                </li>
                <li>
                  The Voucher binding contract will come into force at the time
                  we send out our confirmation email, and until then we shall be
                  under no liability to you whatsoever.
                </li>
                <li>
                  The name of the leading passenger should be correctly given at
                  the time of booking and will appear on the voucher. The
                  voucher is not transferable and changes cannot be made at a
                  later date.
                </li>
                <li>
                  An order can include two or more different tour bookings. This
                  website will not stop you from making incorrect orders such as
                  booking two tours in different places for the same dates, or a
                  tour in one place and show tickets in another. If you error in
                  this kind of double booking, we are unable to give you a
                  refund.
                </li>
                <li>
                  It is essential that you print this voucher and present it
                  upon commencement of your activity or tour. Valid photo ID is
                  required for the lead passenger and must match the travelers
                  name as indicated on the voucher as well as the supplier
                  records. First and last names are sufficient.
                </li>
              </ul>
            </div>
            <div className="cancellation-policy">
              <h6 className="heading">2. Cancellation Policy:</h6>
              <ul>
                <li>
                  Each product has its own cancellation policy. Please review
                  the terms and conditions for each product before booking.
                </li>
                <li>
                  If you reject (cancel) the activity, the cancellation
                  conditions stated in the product description as well as on
                  your voucher apply. suYatra advises the user to carefully read
                  the information in the product description
                </li>
                <li>
                  Provided there are no deviating cancellation conditions in the
                  product description of your Supplier, the following
                  cancellation fees will be charged by the Supplier of the
                  tourist services:
                </li>
                <ul>
                  <li>
                    Up to 24 hours before the start of the activity: full refund
                  </li>
                  <li>
                    Less than 24 hours before the start of the activity or in
                    the event of a no-show: no refund
                  </li>
                </ul>
                <li>
                  You, or the user you have referred, are free to prove to the
                  Supplier that they have not incurred any damage, or
                  substantially less damage than the fee charged by the
                  Supplier.
                </li>
                <li>
                  The Supplier reserves the right to request higher, specific
                  compensation instead of the aforementioned flat-rate fees, if
                  the Supplier can prove that it has incurred much higher
                  expenses than the respectively applicable flat-rate fee. In
                  this case the Supplier is obliged to provide specific figures
                  and evidence of the compensation requested, taking into
                  account the saved expenses and any other use of the services.
                </li>
                <li>
                  The Supplier can cancel the activity on the agreed date
                  without observing a period of notice, if weather conditions,
                  official measures, strikes or other unforeseeable or
                  unavoidable external conditions (in particular force majeure)
                  make the execution of the activity impossible, make it
                  considerably more difficult or endanger it. In this case the
                  paid price is refunded.
                </li>
              </ul>
            </div>
            <div className="payment">
              <h6 className="heading">3. Payment:</h6>
              <ul>
                <li>Credit cards accepted: MasterCard, Visa Card.</li>
                <li>
                  Full payment by credit card is required to make a Booking.
                </li>
                <li>
                  Suyatra Tech Pvt. Ltd. does not charge a service fee for
                  processing credit card payments.
                </li>
                <li>
                  In the event we are unable to confirm your booking we shall
                  not process your payment.
                </li>
              </ul>
            </div>
            <div className="supplier-payments">
              <h6 className="heading">4. Supplier Payments</h6>
              <p>
                All the payment will be paid via bank transfer, payment will
                generally become due and payable by the fifth business day of
                the next calendar month after your tour’s departure date.
              </p>
              <p>
                For Example, if a tour departs on 01st January 2020, payment
                will be due to the Operator by the fifth business day of the
                following month. This would be 7th February 2020.
              </p>
              <p>
                Bank transfers will be processed by one or more of Suyatra Tech
                Pvt. Ltd.’s subsidiaries, depending on the location of the
                supplier. This may result in multiple bank transfers, each
                payout. As we process high volumes of transactions each month,
                it is necessary for us to set automated workflows and have
                structured processes. As such, we are currently performing
                electronic payments and for our internal procedures, it is not
                possible to consolidate all payments into one single
                transaction.
              </p>
              <p>
                We will cover the bank fees associated with these transfers,
                with the exception of any fees charged by intermediary banks or
                your bank. You will be required to cover these fees. Also we are
                happy to pay by credit card as well.
              </p>
            </div>
            <div className="prices">
              <h6 className="heading">5. Prices</h6>
              <p>
                Prices are per person, unless otherwise specified. Prices are
                based on the local tariffs. Price quotations are subject to
                change without notice until a booking has been confirmed.
              </p>
            </div>
            <div className="not-included">
              <h6 className="heading">6. Not Included:</h6>
              <ul>
                <li>
                  Any applicable park fees, passenger facility charges etc.
                </li>
                <li>
                  Tips/gratuities to tour directors, drivers, and local guides;
                  baggage and personal insurance; beverages and food not listed
                  under inclusions in the travel product (these items are billed
                  to you before leaving the hotel/restaurant); and all other
                  items of a personal nature.
                </li>
              </ul>
            </div>
            <div className="travel-insurance">
              <h6 className="heading">7. Travel Insurance:</h6>
              <ul>
                <li>
                  We strongly recommend that, at the time of the booking, a
                  comprehensive travel insurance policy be purchased.
                </li>
              </ul>
            </div>
            <div className="booking-arrangement">
              <h6 className="heading">8. Booking Arrangements:</h6>
              <ul>
                <li>
                  In all booking arrangements, the person making the booking
                  shall be deemed to have accepted the booking conditions on
                  behalf of the persons named in the booking. The contract will
                  be governed by and subject to the Terms & Conditions. No
                  variation to these Terms and Conditions shall be of any effect
                  unless made in writing by and with the authority of Suyatra
                  Tech Pvt. Ltd..
                </li>
                <li>
                  Suyatra Tech Pvt. Ltd. vouchers for each booking segment will
                  be provided.
                </li>
              </ul>
            </div>
            <div className="responsibility">
              <h6 className="heading">9. Responsibility:</h6>
              <ul>
                <li>
                  You will be able to find the name and address of the relevant
                  supplier on your voucher. The voucher governs the relationship
                  with you and the supplier. This will mean that outside these
                  terms and conditions, ultimately any decisions on Supplier
                  terms and conditions, cancellation, exchanges and refunds will
                  belong to the supplier.
                </li>
                <li>
                  Agreeing Suppliers acknowledge by their acceptance, that
                  suYatra , its partners, agents and suppliers, in making
                  arrangements for hotels, tours, transportation or any service
                  in connection with the itineraries of individual tour members
                  shall not be liable for injury, damage, loss, accident, delay
                  or irregularity, liability or expense to person or property
                  due to act of default by any hotel, carrier or other company
                  or person providing services included in the tours. Further,
                  Suyatra Tech Pvt. Ltd., its partners, agents and suppliers
                  accept no responsibility for any sickness, pilferage, labor
                  disputes, machinery breakdown, and government restrains, acts
                  of war and terrorism, weather conditions, defect in any
                  vehicle of transportation or for any misadventure or casualty
                  or any other causes beyond their control. Suyatra Tech Pvt.
                  Ltd. reserves the right to cancel, change or substitute any
                  service and to decline to accept or retain any tour members at
                  any time for any reason including, without limitation,
                  insufficient number of participants. The sole responsibility
                  of any airline used in any tour is limited to that set out in
                  the passenger contract evidenced by the ticket. All travel
                  documents/vouchers are issued subject to the terms and
                  conditions specified by Suyatra Tech Pvt. Ltd., its agents and
                  suppliers.
                </li>
              </ul>
            </div>
            <div className="availability-error-inaccuracies">
              <h6 className="heading">
                10. Availability, Errors and Inaccuracies
              </h6>
              <p>
                We are constantly updating product and service offerings on the
                Service. We may experience delays in updating information on the
                Service and in our advertising on other web sites. The
                information found on the Service may contain errors or
                inaccuracies and may not be complete or current. Products or
                services may be mispriced, described inaccurately, or
                unavailable on the Service and we cannot guarantee the accuracy
                or completeness of any information found on the Service.
                <br />
                We therefore reserve the right to change or update information
                and to correct errors, inaccuracies, or omissions at any time
                without prior notice.
              </p>
            </div>
            <div className="link-to-other-sites">
              <h6 className="heading">11. Links To Other Web Sites</h6>
              <p>
                Our Service may contain links to third party web sites or
                services that are not owned or controlled by SuYatra Tech Pvt.
                Ltd..
                <br />
                SuYatra Tech Pvt. Ltd. has no control over, and assumes no
                responsibility for the content, privacy policies, or practices
                of any third party websites or services. We do not warrant the
                offerings of any of these entities/individuals or their
                websites.
                <br />
                You acknowledge and agree that SuYatra Tech Pvt. Ltd. shall not
                be responsible or liable, directly or indirectly, for any damage
                or loss caused or alleged to be caused by or in connection with
               or reliance on any such content, goods or services
                available on or through any such third party web sites or
                services.
                <br />
                We strongly advise you to read the terms and conditions and
                privacy policies of any third party websites or services that
                you visit.
              </p>
            </div>
          </div>
        </section> */}
      </div>
      <Footer />
    </>
  );
};

export default TermsAndCondition;
