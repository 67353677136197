import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Card } from "react-bootstrap";

import Coupon from "../../../molecules/Coupon";
import EsewaPaymentForm from "./EsewaPaymentForm";
import KhaltiPaymentForm from "./KhaltiPaymentForm";
import { NicAsiaForm } from "./NicAsiaForm";
import { PaymentMethodsWallet } from "./PaymentMethodsWallet";
import { PAYMENT_METHOD_UNIQUE_KEY as PAYMENT_METHOD } from "../../../../_helpers/constants/payment-method/paymentType";
import { usePaymentMethods } from "../../../../hooks/usePaymentMethods";

const PaymentMethodWrapper = ({ price, bookingReference, isFree }) => {
  const { data: paymentMethods, isLoading, isError } = usePaymentMethods();
  const history = useHistory();
  const amount = price?.toFixed(2);
  const [selectedWallet, setSelectedWallet] = useState("");
  const handleSelection = (wallet) => {
    setSelectedWallet(wallet);
  };

  const walletName = paymentMethods?.payment_methods?.find(payment => payment.code === selectedWallet);

  return (
    <Card>
      <Card.Body>
        <h6>Pay Now</h6>
        <hr />
        <p className="small-text">
          By clicking "pay now" and completing a booking, you agree with the
          <Link to="/terms-condition" className="text-primary mx-2">
            terms & condition
          </Link>
          and the
          <Link to="/privacy-cookies" className="text-primary mx-2">
            privacy policy
          </Link>
          of this product.
        </p>
        <Coupon bookingReference={bookingReference} />
        {/* payment method lists */}
        {!isFree ? (
          <>
            <PaymentMethodsWallet
              selectedWallet={selectedWallet}
              handleSelection={handleSelection}
              paymentMethods={paymentMethods}
              isLoading={isLoading}
              isError={isError}
            />
            {selectedWallet !== "" && (
              <>
                <p className="small mt-4">
                  You will be redirected to your {walletName.name} account to
                  complete your payment:
                  <br />
                  1. Login to your {walletName.name} account using your{" "}
                  {walletName.name} ID and your Password
                  <br />
                  2. Ensure your {walletName.name} account is active and has
                  sufficient balance
                  <br />
                  3. Enter OTP (one time password) sent to your registered
                  mobile number ***Login with your {walletName.name} mobile and
                  password (not M Pin)***
                </p>
                {selectedWallet === PAYMENT_METHOD.key_esewa && (
                  <EsewaPaymentForm
                    amount={amount}
                    bookingReference={bookingReference}
                  />
                )}
                {selectedWallet === PAYMENT_METHOD.key_khalti && (
                  <KhaltiPaymentForm
                    amount={amount}
                    bookingReference={bookingReference}
                  />
                )}
                {selectedWallet === PAYMENT_METHOD.key_nicasia && (
                  <NicAsiaForm
                    bookingReference={bookingReference}
                    amount={amount}
                  />
                )}
              </>
            )}
          </>
        ) : (
          <input
            onClick={() =>
              history.push(
                `/payment-success-page?bookingReference=${bookingReference}`
              )
            }
            value="Finish your booking"
            type="submit"
            className="btn btn-outline-primary rounded-pill col-12 btn-sm my-4 font-weight-light"
          />
        )}
      </Card.Body>
    </Card>
  );
};

export default PaymentMethodWrapper;
