import { useMutation, useQueryClient } from "react-query"
import { toast } from "react-toastify";
import { imageServices } from "../services/api/image";

export const useUploadProfile = () => {
    const queryClient = useQueryClient();
    return useMutation(imageServices.uploadProfileImage , {
        onSuccess : () => {
            toast.success(`Image uploaded successfully`)
            queryClient.invalidateQueries(['user'])
        },
        onError : (error) => {
            toast.error(`${error.response.data.error.message}}`)
        }
    })
}