import React from "react";
import { formattedPrice } from "../../_helpers/formattedPrice";

const Price = ({offerPrice, unitPrice}) => {
  return offerPrice ? (
    <>
      <p className="strike-price mb-0">{formattedPrice(unitPrice)}</p>
      <span className="card-footer-amount">
        {formattedPrice(offerPrice)}
      </span>
    </>
  ) : (
    <span className="card-footer-amount">
      {formattedPrice(unitPrice)}
    </span>
  );
};

export default Price;
