import React, { useEffect } from "react";
import { Redirect, Switch } from "react-router-dom";
import { toast } from "react-toastify";

import { useAuth } from "../../../hooks/useAuth";

export function Logout() {
  const { setAuth } = useAuth();

  useEffect(() => {
    toast.success('User logged out successfully')
    localStorage.removeItem("session");
    setAuth('');
    document.location.reload();
  });

  return (
    <Switch>
      <Redirect exact to="/" />
    </Switch>
  );
}
