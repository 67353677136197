import { Container, Row } from "react-bootstrap";
import { useGetPackages } from "../../hooks/reactQueryCustomHook/package/useGetPackages";
import { mockData } from "../../utils/mock/data";

import MainHeading from "../atoms/MainHeading";
import PackageCard from "../molecules/cards/PackageCard";
import SkeletonCard from "../molecules/cards/SkeletonCard";
import Error from "../pages/Error";

const MostPopulatPlace = () => {
  const {
    data: popularPackages,
    isLoading,
    isError,
  } = useGetPackages("popular");

  if (isError) return <Error />;

  return (
    <section className="section">
      <Container>
        <MainHeading
          text={mockData.sectionHeader.popularPackages.title}
          para={mockData.sectionHeader.popularPackages.subTitle}
        />
        <Row>
          {isLoading ? (
            <SkeletonCard count={3} />
          ) : (
            popularPackages?.slice(0, 3)?.map((item) => (
              <div key={item.id} className="col-md-4">
                <PackageCard
                  title={item?.title}
                  image={item?.thumbnail?.url || item?.cover_image?.url}
                  rate={`Rs .${item?.unit_price}`}
                  displayButton={true}
                  displayFooter={true}
                  rating={true}
                  item={item}
                  website={item?.company?.company_details?.website.slice(0, 25)}
                  number={item?.company?.company_details?.company_phone}
                  alt={item?.thumbnail?.original_name}
                  link="package"
                  websiteLink={item?.company?.company_details?.website}
                >
                  {item.description?.substring(0, 120) ||
                    item?.additional_information?.substring(0, 100)}
                </PackageCard>
              </div>
            ))
            //  popularPackages?.length < 0 && (
            //   <div className="text-center font-weight-bold text-muted">
            //     No packages to show
            //   </div>
            // )
          )}
        </Row>
      </Container>
    </section>
  );
};

export default MostPopulatPlace;
