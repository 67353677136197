import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Card } from "react-bootstrap";

import { FallBackView } from "../FallBackView";
import Error from "../Error";
import CancelBooking from "../../modal/CancelBooking";
import BookingList from "./BookingList";
import { statusCheck } from "../../../_helpers/statusCheck";
import { useBooking } from "../../../hooks/useBooking";
import { categoryType } from "../../../utils/categoryType";
import BookingProductCard from "../../molecules/BookingProductCard";

import DashboardLayout from "../../../layout/DashboardLayout";
import { isBookingCancellable } from "../../../_helpers/bookingFunctions";

const ViewBookings = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { bookingReference } = useParams();
  const {
    data: singleBooking,
    isLoading,
    isError,
  } = useBooking(bookingReference);

  if (isLoading) return <FallBackView />;
  if (isError) return <Error />;

  const showCancelButton = isBookingCancellable(singleBooking);

  return (
    <DashboardLayout>
      <Card>
        <BookingProductCard
          data={singleBooking?.product}
          bookingDetail={singleBooking}
        />
        <Card.Body>
          <div className="d-flex justify-content-between mb-4">
            <div className="d-flex align-items-center">
              <h4>Booking Details</h4>
              <span className={`ml-2`}>
                {statusCheck(singleBooking?.status)}
              </span>
            </div>
            {showCancelButton && (
              <div>
                <button
                  onClick={handleShow}
                  className="btn btn-sm btn-outline-danger"
                >
                  Cancel Booking
                </button>
              </div>
            )}
          </div>
          <hr />
          <BookingList booking={singleBooking} CategoryType={categoryType} />
        </Card.Body>
      </Card>
      <CancelBooking
        show={show}
        setShow={setShow}
        handleShow={handleShow}
        refundableAmount={singleBooking?.refundable_policy?.refundable_amount}
        totalAmount={singleBooking?.refundable_policy?.user_paid_amount}
        bookingReference={bookingReference}
        handleClose={handleClose}
      />
    </DashboardLayout>
  );
};

export default ViewBookings;
