import React from "react";
import { BsPatchCheckFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import { useAuth } from "../../../../hooks/useAuth";

const PaymentSuccessDetails = ({bookingReference , remarks , isFree}) => {
  const { user } = useAuth();

  console.log(remarks  , isFree);

  return (
    <div className="payment-success my-5">
      <div className="payment-success-box">
        <div className="success-icon text-center pb-4">
          <BsPatchCheckFill size={100} className="text-success" />
        </div>
        <h3 className="font-weight-light pt-2">
          You have successfully created your booking.
        </h3>
        {remarks && (
        <p className="text-muted small">
          {remarks}
        </p>
        )}
        <p className="text-muted small">
          Your payment details has been send to email !! Please check your email
        </p>
        <div className="success-page-buttons">
        {
          bookingReference && (
          <Link
            to={
              user
                ? `/booking/${bookingReference}`
                : `/guest/view/${bookingReference}`
            }
            className="btn btn-outline-success rounded-pill px-4 mx-4"
          >
            Booking Details
          </Link>
            )
          }
          <Link to="/" className="btn btn-outline-primary rounded-pill px-4">
            Explore More
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccessDetails;
