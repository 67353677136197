import moment from "moment";
import axios from "../../setup/axios/SetupAxios";

/*********************************************
 * User Bookings Routes
 ********************************************/

const guestBooking = async (id) => {
  const { data } = await axios.get(`/bookings/${id}`)
  return data
};

const bookings = async ({ page , status }) => {
  const { data } = await axios({
    method: "GET",
    url: `/user/bookings?page=${page}&status=${status}`,
  });
  return data;
};

const getBooking = async (bookingReference) => {
  const { data } = await axios({
    method: 'GET',
    url: `/user/bookings/${bookingReference}`
  })
  return data
};

const bookPackage = async ({ packageSlug, count, bookingStartDate , bookingEndDate , ...value }) => {
  
  const additionalTravellers = value?.additional_traveller_details?.length > 0 ? value?.additional_traveller_details : null
  const response = await axios.post(
    `/packages/${packageSlug}/booking`,
    {
      quantity: count,
      lead_traveller_name: value.lead_traveller_name,
      lead_traveller_email: value.lead_traveller_email,
      lead_traveller_phone: value.lead_traveller_phone,
      note: value.note,
      additional_traveller_details: additionalTravellers,
      alternative_number: value.alternative_number,
      bookingStartDate,
      bookingEndDate
    },
  );
  return response.data;
};

const bookRoom = async ({ ...values }) => {
  const additionalTravellers = values?.additional_traveller_details?.length > 0 ? values?.additional_traveller_details : null
  const {
    lead_traveller_email,
    lead_traveller_name,
    lead_traveller_phone,
    note,
    rooms,
    startDate,
    endDate,
    alternative_number,
    adult,
    child
  }
    = values
  const starts_at = moment(startDate).format('YYYY-MM-DD')
  const ends_at = moment(endDate).format('YYYY-MM-DD')
  const child_count = parseInt(child)
  const adult_count = parseInt(adult)
  const response = await axios.post(
    `/rooms/${values.roomSlug}/booking`,
    {
      starts_at,
      ends_at,
      child_count,
      adult_count,
      quantity: Number(rooms),
      lead_traveller_email,
      lead_traveller_name,
      lead_traveller_phone,
      note,
      alternative_number,
      additional_traveller_details: additionalTravellers,
    },
  );
  return response.data;
};

const bookProperty = async ({ ...values }) => {
  const propertySlug = values.propertySlug
  const adultCount = values.adultCount
  const childCount = values.childCount
  const roomCount = values.roomCount
  const fromDate = values.startDate
  const toDate = values.endDate
  const {data} = await axios.get(`/properties/${propertySlug}/booking?adult=${adultCount}&child=${childCount}&room_count=${roomCount}&from=${fromDate}&to=${toDate}`);
  return data
}

const updateBookingAvailability = async (bookingReference) => {
  const response = await axios.get(`/booking/update-availability/${bookingReference}`);
  return response.data;
};

const checkBookingAvailability = async(bookingReference) => {
  const response = await axios.get(`/bookings/${bookingReference}/availability/check`)
  return response.data
};

const rebookProduct = async(bookingReference) => {
  const response = await axios.patch(`booking/rebook-availability/${bookingReference}`)
  return response.data
}

const cancelBooking = async (bookingReference) => {
  const response = await axios.patch(`/user/bookings/${bookingReference}/cancel`)
  return response.data
};

/*****************************************************
 * User Track Bookings Route
 *****************************************************/

const trackBooking = async (referenceId) => {
  const response = await axios.get(`/booking/track?booking_reference=${referenceId}`)
  return response.data
};

export const bookingServices = {
  guestBooking,
  bookings,
  getBooking,
  bookPackage,
  bookRoom,
  cancelBooking,
  trackBooking,
  updateBookingAvailability,
  checkBookingAvailability,
  rebookProduct,
  bookProperty
};