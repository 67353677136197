import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Skeleton } from "@mantine/core";

import MainHeading from "../atoms/MainHeading";
import FeatureBox from "../molecules/FeatureBox";
import "../../assets/styles/feature.css";
import { mockData } from "../../utils/mock/data";
import { useProperties } from "../../hooks/reactQueryCustomHook/property/useGetProperties";

const Feature = () => {
  const { data: featured, isLoading } = useProperties("featured");
  
  return (
    <section className="feature-place section bg-light">
      <Container>
        <MainHeading
          text={mockData.sectionHeader.featuredPlaces.title}
          para={mockData.sectionHeader.featuredPlaces.subTitle}
        />
        <Row>
        {isLoading ? (
             Array.from(new Array(6)).map((number , index) => (
              <Col md={4} key={index} className="mt-3">
              <Skeleton height={400} />
              </Col>
             ))
          ) : (
            featured.map((feature) => (
              <Col md={4}  key={feature.id}>
                <FeatureBox
                  key={feature.id}
                  location={feature.name}
                  count={feature.count}
                  img={feature.image}
                  title={feature.title}
                  type="properties"
                />
              </Col>
            ))
          )
          }
        </Row>
      </Container>
    </section>
  );
};

export default Feature;
