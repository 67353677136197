import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Col, Container, Row, Card } from "react-bootstrap";
import { Stepper } from "@mantine/core";

import { useAuth } from '../../../hooks/useAuth';
import { usePackageSearch } from "../../../hooks/usePackageSearch";

import Header from "../../organisms/Header";
import Footer from "../../molecules/Footer";
import CheckoutCard from "../../organisms/CheckoutCard";
import { FallBackView } from "../FallBackView";
import Error from "../Error";
import CheckoutForm from "../CheckoutForm";
import ProductInfo from "../checkoutpage/components/ProductInfo";
import useGetPackage from "../../../hooks/reactQueryCustomHook/package/useGetPackage";
import { useBookingReference } from "../../../hooks/useBookingReference";
import { useBookPackage } from "../../../hooks/useBookPackage";
import PaymentMethodWrapper from "../payment/components/PaymentMethodWrapper";
// import Payment from "../payment/components/Payment";

const Checkout = () => {
    const history = useHistory();
    const [active, setActive] = useState(0);
    const [bookingReference, setBookingReference] = useState(null);
    const { user } = useAuth();
    const { packageSearch } = usePackageSearch();
    const {handleBookingReference} = useBookingReference();
    const { packageSlug } = useParams();
    const { data, isLoading, isError } = useGetPackage(packageSlug);
    const mutation = useBookPackage();
    const { count, totalAmount } = packageSearch;
    const nextStep = () => setActive((current) => (current < 2 ? current + 1 : current));

    if (isLoading) return <FallBackView />;

    if (isError) return <Error />;

    const onFormSubmit = (value) => {
        mutation.mutate({ count, packageSlug, bookingStartDate:packageSearch?.bookingStartDate , bookingEndDate:packageSearch?.bookingEndDate,  ...value }, {
            onSuccess: (data) => {
                setBookingReference(data?.booking_reference)
                handleBookingReference(data?.booking_reference)
                history.push(`/payment/${data?.booking_reference}`)
                // nextStep();
            }
        })
    };

    return (
        <div className="dashboard checkout">
            <Header />
            <Container className="mb-5">
                <Row className="mt-4">
                    <Col md="8">
                        <ProductInfo
                            data={data} />
                        <Card>
                            <Card.Body>
                                <Stepper active={active} onStepClick={setActive} breakpoint="sm">
                                    <Stepper.Step label="First step" description="Booking details" allowStepSelect={active > 0}>
                                        <CheckoutForm
                                            onFormSubmit={onFormSubmit}
                                            isLoading={mutation.isLoading}
                                            price={data?.offer_price || data?.unit_price} />
                                    </Stepper.Step>
                                    <Stepper.Step label="Second step" description="Payment Details" allowStepSelect={active > 1}>
                                        <PaymentMethodWrapper
                                            price={Number(totalAmount)}
                                            user={user}
                                            bookingReference={bookingReference}
                                            nextStep={nextStep} />
                                    </Stepper.Step>
                                    <Stepper.Completed>
                                        Completed, click back button to get to previous step
                                    </Stepper.Completed>
                                </Stepper>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md="4">
                        <CheckoutCard
                            data={data}
                            count={count} />
                    </Col>
                </Row>
            </Container>
            <Footer />
        </div>
    );
};

export default Checkout;
