//modules
import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Card, Col, Row } from "react-bootstrap";
import { Stepper } from '@mantine/core';

//custom hooks
import { usePropertyQuery } from "../../../hooks/usePropertyQuery";
import { useAuth } from "../../../hooks/useAuth";
import { useBookRoom } from "../../../hooks/reactQueryCustomHook/booking/useBookRoom";
// import { useRoomCancellation } from "../../../hooks/reactQueryCustomHook/booking/useRoomCancellation";
import useGetRoom from "../../../hooks/reactQueryCustomHook/room/useGetRoom";

//components
import Header from "../../organisms/Header";
import Footer from "../../molecules/Footer";
import { FallBackView } from "../FallBackView";
import Error from "../Error";
import CheckoutForm from "../CheckoutForm";
import CheckOutSideBar from "../../organisms/CheckOutSideBar";
import ProductInfo from "../checkoutpage/components/ProductInfo";
import { useBookingReference } from "../../../hooks/useBookingReference";
import PaymentMethodWrapper from "../payment/components/PaymentMethodWrapper";
// import Timer from "../../atoms/Timer"

const Checkout = () => {
    const { user } = useAuth();
    const history = useHistory();
    const [bookingReference, setBookingReference] = useState(null);
    const {handleBookingReference} = useBookingReference();
    const [active, setActive] = useState(1);
    const { roomSlug, propertySlug } = useParams();
    const mutation = useBookRoom();
    // const cancelReservedRoomMutation = useRoomCancellation();
    const { searchProperty } = usePropertyQuery();
    const { startDate, endDate, rooms, adult, child, totalAmount } = searchProperty;
    const { data, isLoading, isError } = useGetRoom(roomSlug, propertySlug, startDate, endDate , rooms);

    const onFormSubmit = (value) => {
        mutation.mutate({ startDate, endDate, adult, child, rooms, roomSlug, ...value }, {
            onSuccess: (data) => {
                setBookingReference(data?.booking_reference)
                handleBookingReference(data?.booking_reference)
                history.push(`/payment/${data?.booking_reference}`)
                nextStep()
            }
        })
    };

    const nextStep = () => setActive((current) => (current < 3 ? current + 1 : current));

    if (isLoading) return <FallBackView />;
    if (isError) return <Error />;

    return (
        <div className="dashboard checkout">
            <Header />
            <div className="container mb-5">
                <Row className="my-2">
                    <Col md="8">
                        <ProductInfo data={data} />
                        <Card>
                            <Card.Body>
                                <Stepper active={active} onStepClick={setActive} breakpoint="sm">
                                    <Stepper.Step
                                        label="First step"
                                        description="Your selection"
                                        allowStepSelect={false}>
                                    </Stepper.Step>
                                    <Stepper.Step
                                        label="Second step"
                                        description="Booking details"
                                        allowStepSelect={active > 0}>
                                        <CheckoutForm
                                            onFormSubmit={onFormSubmit}
                                            isLoading={mutation.isLoading}
                                            nextStep={nextStep} />
                                    </Stepper.Step>
                                    <Stepper.Step
                                        label="Third step"
                                        description="Payment Details"
                                        allowStepSelect={active > 1}>
                                        {/* <Timer onTimeOut={cancelReservedRoomMutation} bookingReference={bookingReference} /> */}
                                        <PaymentMethodWrapper
                                            user={user}
                                            bookingReference={bookingReference}
                                            price={Number(totalAmount)} />
                                    </Stepper.Step>
                                    <Stepper.Completed>
                                        Completed, click back button to get to previous step
                                    </Stepper.Completed>
                                </Stepper>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md="4">
                        <CheckOutSideBar
                            searchProperty={searchProperty}
                            active={active}
                            data={data} />
                    </Col>
                </Row>
            </div>
            <Footer />
        </div>
    );
};

export default Checkout;
