import { LoadingOverlay } from "@mantine/core";
import React, { useEffect } from "react";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { useStringHook } from "../../../hooks/useStringHook";
import { paymentServices } from "../../../services/api/payment";

const PaymentVerificationPage = () => {
  const history = useHistory();
  const query = useStringHook();
  const referenceId = query.get("refId") || query.get("pidx");
  const orderId = query.get("oid") || query.get("purchase_order_id");
  const amount = query.get("amt") || query.get("amount");
  const data = query.get("data");
  const bookingReference = orderId?.substring(2);

  const { mutate, isLoading } = useMutation(
    paymentServices.khaltiVerification,
    {
      onSuccess: () => {
        history.push(
          `/payment-success-page?bookingReference=${bookingReference}`
        );
      },
      onError: () => {
        toast.error(`error while adding payment`);
        history.push(
          `/payment-error-page?bookingReference=${bookingReference}`
        );
      },
    }
  );

  const esewaPayment = useMutation(paymentServices.esewaVerification, {
    onSuccess: (data) => {
      console.log(data.data.booking_reference);
      history.push(
        `/payment-success-page?bookingReference=${data.data.booking_reference}`
      );
    },
    onError: () => {
      toast.error(`error while adding payment`);
      history.push(`/payment-error-page`);
    },
  });

  useEffect(() => {
    if (data) {
      esewaPayment.mutate(data);
    } else if (bookingReference) {
      mutate({ bookingReference, orderId, referenceId, amount });
    }
  }, [bookingReference, mutate, orderId, referenceId, amount]);

  return (
    <div
      style={{ width: 400, height: 400, position: "relative", margin: "auto" }}
    >
      <LoadingOverlay visible={isLoading || esewaPayment.isLoading} overlayBlur={2} />
      {/* ...other content */}
    </div>
  );
};

export default PaymentVerificationPage;
