//import modules
import { useState } from "react";
import { useParams } from "react-router-dom";
import { Card, Col, Container, Row } from "react-bootstrap";

//custom hooks
import useGetPackage from "../../../hooks/reactQueryCustomHook/package/useGetPackage";

//components
import Footer from "../../molecules/Footer";
import { FallBackView } from "../FallBackView";
import Header from "../../organisms/Header";
import BookPackage from "../../molecules/BookPackage";
import Error from "../Error";
import PackageActivities from "../../organisms/PackageActivities";
import PackageExperience from "../../organisms/PackageExperience";
import SearchBox from "./SearchBox";
import Description from "../../molecules/Description";
import ReviewsSection from "../../organisms/ReviewsSection";
import GallerySection from "../../molecules/GallerySection";

//styling
import "../../../assets/styles/listingDesc.css";
import { PackageDateCard } from "../../molecules/PackageDateCard";

const PackageDescription = () => {
  const [count, setCount] = useState(1);
  const { packageSlug } = useParams();
  const { isLoading, isError, error, data: singlePackage } = useGetPackage(packageSlug);

  if (isLoading) {
    return <FallBackView />
  };

  if (isError) {
    return <Error error={error} />
  };

  return (
    <div className="listing-details">
      <Header />
      <section className="ads-detail">
        <Container>
          <GallerySection
            images={singlePackage.files}
            coverImage={singlePackage.thumbnail.url}
            coverImageAlt={singlePackage.thumbnail.name}
            title={singlePackage.title}
            category={singlePackage.category}
            location={singlePackage.location.address}
            averageRating={singlePackage.average_rating}
            reviewsCount={singlePackage.reviews_count}
            type="package"
          />
          <Row>
            <Col xl="8" md="12">
              <Card>
                <Card.Body>
                  <Description description={singlePackage.description} title="package" />
                </Card.Body>
              </Card>
              <PackageActivities />
              <PackageExperience data={singlePackage} />
            </Col>
            <Col xl="4" md="12">
              {
                Boolean(singlePackage?.is_fixed_departure) ?
                  <PackageDateCard data={singlePackage} /> : null
              }
              <BookPackage data={singlePackage} packageSlug={packageSlug} />
              <SearchBox data={singlePackage} count={count} setCount={setCount} />
            </Col>
          </Row>
        </Container>
      </section>
      <ReviewsSection slug={packageSlug} type="packages" />
      {/* {user && <ReplyPackages singlePackage={singlePackage} slug={packageSlug} />} */}
      <Footer />
    </div>
  );
};

export default PackageDescription;