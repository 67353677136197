import React from 'react';
import {HiOutlineArrowNarrowUp} from "react-icons/all";

import '../../assets/styles/countVisitor.css';

const CountVisitor = ({ count, subTitle }) => {
    return (
        <div className="counter-box">
            <div className='d-flex align-items-center justify-content-center'>
            <h1 className='counter-title'>{count}</h1>
            <HiOutlineArrowNarrowUp className='text-white h1' />
            </div>
            <p className="text-white counter-para text-capitalize font-weight-bold">{subTitle}</p>
        </div>
    )
}

export default CountVisitor
