import axios from "../../setup/axios/SetupAxios";
import axiosReq from 'axios'

/***************************************************
 * Social Login
 * ****************************************** */
const googleLogin = async ({ profileObj, googleId, accessToken }) => {
    const { email, name, imageUrl } = profileObj
    const response = await axios.post(`/social/google/login`, {
        name: name,
        social_id: googleId,
        token: accessToken,
        email,
        avatar: imageUrl,
    })
    return response
};

const facebookLogin = async ({ profileObj, googleId, accessToken }) => {
    const { email, name, imageUrl } = profileObj
    const response = await axios.post(`/social/facebook/login`, {
        name: name,
        social_id: googleId,
        token: accessToken,
        email,
        avatar: imageUrl,
    })
    return response
};

/******************************************************
 * User Login
******************************************************/
const handleRegister = (
    { email,
        password,
        password_confirmation,
        Firstname,
        Lastname,
        phoneNumber,
        newsletterSubscription
    }
) => {
    return axios.post(`/user/register`, {
        email,
        password,
        password_confirmation,
        name: Firstname + " " + Lastname,
        phone: phoneNumber,
        transactional_mail: true,
        newsletter_subscription: newsletterSubscription
    });
};

const handleLogin = async ({ userName, password }) => {
    let email, phone;
    if (isNaN(userName)) {
        email = userName
    }
    else {
        phone = Number(userName)
    }
    const response = await axios.post(`/user/login`, {
        password,
        phone,
        email
    });
    return response
};

const forgetPassword = async (email) => {
    const response = await axios.post(`/forgot-password`, { email })
    return response
};

const resetPassword = async ({ token, email, password, password_confirmation }) => {
    await axios.post(`/reset-password`, { token, email, password, password_confirmation })
}

const changePassword = async ({ current_password, new_password, new_password_confirmation }) => {
    const response = await axios.post(`/password/change`, {
        current_password,
        new_password,
        new_password_confirmation
    })
    return response
};


/******************************************************
 * Impersonate User Login
******************************************************/

const impersonateLogin = async (token) => {
    const {data} =  await axiosReq.get(`${process.env.REACT_APP_API_URL}/impersonate`, {
        headers: { 'Authorization': `Bearer ${token}` }
    })
    return data
};

export const authenticationService = {
    googleLogin,
    facebookLogin,
    handleRegister,
    handleLogin,
    forgetPassword,
    changePassword,
    resetPassword,
    impersonateLogin
};