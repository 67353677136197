import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/bird.png";
import Header from "../organisms/Header";

const Error404 = () => {
  return (
    <>
      <Header />
      <div className="error-wrapper d-flex flex-column justify-content-center align-items-center vh-75">
        <div className="error-404">
          <img src={logo} alt="suyatra logo" />
        </div>
        <h4 className="fw-bold fs-1 my-3">Error 404 - Page Not Found</h4>
        <p>Sorry, the page you are looking for does not exist.</p>
        <p>You can return to the homepage.</p>
        <Link to="/" className="btn btn-outline-primary my-3">
          Go To Home
        </Link>
      </div>
    </>
  );
};

export default Error404;
