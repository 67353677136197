
export const useTotalAvailableRoom = (dailyAvailabilities) => {
    if(dailyAvailabilities.length !== 0){
    const min = dailyAvailabilities.reduce(function (prev, current) {
        return (prev.available_count < current.available_count) ? prev.available_count : current.available_count
    });

    let totalRooms = [];
    for (let i = 1; i <= min; i++) {
        totalRooms.push(i)
    };
    return {totalRooms}
}
else{
    return []
}
}
