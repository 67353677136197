import {
  createStyles,
  Image,
  Container,
  Title,
  Text
} from "@mantine/core";
import { Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import image from "../../../assets/images/credit-card.png";
import { useStringHook } from "../../../hooks/useStringHook";
import Footer from "../../molecules/Footer";
import Header from "../../organisms/Header";

const useStyles = createStyles((theme) => ({
  root: {
    paddingTop: 80,
    paddingBottom: 80,
  },

  title: {
    fontWeight: 900,
    fontSize: 34,
    marginBottom: theme.spacing.md,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    textTransform : "capitalize",

    [theme.fn.smallerThan("sm")]: {
      fontSize: 32,
    },
  },

  control: {
    [theme.fn.smallerThan("sm")]: {
      width: "100%",
    },
  },

  mobileImage: {
    [theme.fn.largerThan("sm")]: {
      display: "none",
    },
  },

  desktopImage: {
    width : '5rem',
    marginBottom : '1rem',
    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },
}));

const PaymentErrorPage = () => {
  const { classes } = useStyles();

  const query = useStringHook();

  const bookingReference = query.get("bookingReference")

  return (
    <>
      <Header />
      <Container className={classes.root}>
        <Row>
          <div className="col-md-10 mx-auto text-center">
            <div className="text-center d-flex justify-content-center">
          <Image src={image} className={classes.desktopImage} />
            </div>
            <Title className={classes.title}>
              Payment verification error...
            </Title>
            <Text color="dimmed" size="lg" className="text-justify">
              We are facing issues to verify your payment for booking{" "}
              <u>{bookingReference}</u>. We will try to re-verify your payment once
              again after 10 minutes and notify you with the status. Please go
              to your email for more detailed reason. In case your bank/wallet
              charges you the amount, click here and submit report to suYatra
              administrator. Alternatively you can contact us at the{" "}
              <Link className="text-primary" to="/contact-us">
                here
              </Link>
              .
            </Text>
          </div>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default PaymentErrorPage;
