// import moment from "moment";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Modal, Skeleton } from "@mantine/core";
import { CgDanger } from "react-icons/cg";
import { BiMap } from "react-icons/bi";

import Rating from "../atoms/Rating";
import CancelBooking from "../modal/CancelBooking";
import Reply from "./Reply";
import { statusCheck } from "../../_helpers/statusCheck";
import { BsCheckCircle } from "react-icons/bs";
import PayNow from "../atoms/PayNow";
import { isBookingCancellable } from "../../_helpers/bookingFunctions";
import { Card, Col, Row } from "react-bootstrap";
import { STATUS } from "../../_helpers/constants/statusType";

const BookingCard = ({ booking, type, review, isFetching }) => {
  const [reviews, setReviews] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const bookingReference = booking?.booking_reference;

  const showCancelButton = isBookingCancellable(booking);
  const productDetails = booking?.product;

  return (
    <>
      <Card className="horizontal-card mb-4">
        <Row className="no-gutters">
          <Col md="3" className="p-2 m-0">
            {isFetching ? (
              <Skeleton height={170} />
            ) : (
              <img
                className="bd-placeholder-img rounded"
                width="100%"
                height="100%"
                src={productDetails?.thumbnail?.url}
                alt={productDetails?.title}
                style={{ aspectRatio: "2 / 1" }}
              />
            )}
          </Col>
          <Col md="9">
            <Card.Body className="card-body p-3">
              <Card.Title>
                <span>
                  {isFetching ? (
                    <Skeleton
                      height={25}
                      width="40%"
                      radius={20}
                      className="mb-3"
                    />
                  ) : (
                    statusCheck(booking?.status)
                  )}
                </span>
                {isFetching ? (
                  <Skeleton height={20} radius="xl" />
                ) : (
                  <Link
                    to={
                      productDetails?.property_name
                        ? `/property/${productDetails.property_slug}`
                        : `/package/${productDetails.slug}`
                    }
                    className="mt-5"
                  >
                    <h5 className="mb-0 mt-2 text-capitalize font-weight-light">
                      {productDetails?.property_name &&
                        `${productDetails?.property_name} /`}{" "}
                      {productDetails?.title}
                    </h5>
                    <div className="float-right">
                      <div className="text-right">
                        {isFetching ? (
                          <Skeleton height={15} />
                        ) : (
                          <>
                            <Rating star={productDetails?.average_rating} />
                            <br />
                            <span className="small font-weight-light ml-3 text-muted">
                              <>({productDetails?.reviews_count} Reviews)</>
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                  </Link>
                )}
              </Card.Title>
              <div className="card-description text-left mb-2">
                <span className="fw-600">
                  {isFetching ? (
                    <Skeleton height={20} width="30%" />
                  ) : (
                    <>
                      {type.toLowerCase() === "package" ? (
                        <>
                          <BiMap className="mr-1" />
                          {productDetails?.location.address.city ||
                            productDetails?.location.address.country}
                        </>
                      ) : (
                        productDetails?.type
                      )}
                    </>
                  )}
                </span>
              </div>
              <div className="booking-date-time fw-600 mb-2">
                {isFetching ? (
                  <Skeleton height={15} />
                ) : (
                  <>
                    {type.toLowerCase() === "package" ? (
                      <span>Start Date :- {booking.starts_at}</span>
                    ) : (
                      <>
                        <span className="mb-1">
                          Check in :- {booking.starts_at}
                        </span>
                        <span className="ml-4">
                          Check out :- {booking.ends_at}
                        </span>
                      </>
                    )}
                  </>
                )}
              </div>
              {isFetching ? (
                <></>
              ) : (
                !booking.is_reserved && booking.status === STATUS.PAYMENT_PENDING &&(
                  <p className="text-danger small">
                    <CgDanger className="mr-1" />
                    Your booking has expired!! Please book again.
                  </p>
                )
              )}
              {isFetching ? (
                <Skeleton height={15} className="mb-2" />
              ) : (
                booking.is_completed && (
                  <span className="text-success">
                    <BsCheckCircle className="mr-1" /> Booking Completed
                  </span>
                )
              )}
              <div className="float-right">
                <h6>
                  {isFetching ? <Skeleton /> : `NPR ${booking?.total_amount}`}
                </h6>
              </div>
              {isFetching ? (
                <Skeleton height={20} />
              ) : (
                <div className="d-flex justify-content-end w-100">
                  {booking.is_completed && (
                    <button
                      className="btn btn-outline-info btn-sm mr-3"
                      onClick={() => setReviews(true)}
                    >
                      Review
                    </button>
                  )}
                  {showCancelButton && (
                    <button
                      className="btn btn-outline-danger btn-sm mr-3"
                      onClick={() => setShow(true)}
                    >
                      Cancel
                    </button>
                  )}
                  <PayNow
                    isAvailability = {booking.has_availability}
                    isReserved={booking.is_reserved}
                    createdAt={booking.created_at}
                    bookingReference={booking.booking_reference}
                    isPaid={booking.is_paid}
                    status={booking.status}
                  />
                  <Link to={`/booking/${booking?.booking_reference}`}>
                    <button className="btn btn-info btn-sm">
                      View Details
                    </button>
                  </Link>
                </div>
              )}
            </Card.Body>
          </Col>
        </Row>
      </Card>
      {/* cancel booking modal*/}
      <CancelBooking
        show={show}
        setShow={setShow}
        handleShow={handleShow}
        bookingReference={bookingReference}
        handleClose={handleClose}
        refundableAmount={booking?.refundable_policy?.refundable_amount}
        totalAmount={booking?.refundable_policy?.user_paid_amount}
      ></CancelBooking>
      {/* review modal*/}
      <Modal opened={reviews} onClose={() => setReviews(false)} centered>
        <Reply slug={productDetails?.slug} type={type?.toLowerCase()} handleClose={handleClose} />
      </Modal>
    </>
  );
};

export default BookingCard;
