import React from 'react'
import PropTypes from 'prop-types';

const ChatList = ({ variant, title, date, para, alt, img }) => {
    return (
        <div className={`chat-list ${variant}`}>
            <div className="chat-people">
                <div className="chat-img">
                    <img src={img} alt={alt ? alt : 'imagess'} />
                </div>
                <div className="chat-ib">
                    <h5>{title}</h5>
                    <p className="fw-light">{date}</p>
                    <p>{para}</p>
                </div>
            </div>
        </div>
    )
}

export default ChatList


ChatList.propTypes = {
    title: PropTypes.string,
    variant: PropTypes.string,
    date: PropTypes.any,
    para: PropTypes.string,
    alt : PropTypes.string,
    img :PropTypes.string
}
