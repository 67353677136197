import React from "react";

const NoResults = (props) => {
  const { title, paragraph , className } = props;
  return (
    <div className={`empty-results mt-4 ${className}`}>
      <h2 className="font-weight-bold">{title}</h2>
      <p className="text-muted">{paragraph}</p>
    </div>
  );
};

export default NoResults;

NoResults.defaultProps = {
  title: "No results",
  paragraph: "We couldn't find any results.",
};
