import React from "react";
import { Container } from "react-bootstrap";
import Footer from "../../molecules/Footer";

import Header from "../../organisms/Header";

const PrivacyDisclaimer = () => {
  return (
    <>
      <div className="privacy-disclaimer-page">
        <Header />
        <Container>
          <div className="my-5">
            <h4 className="main-title text-center text-primary">
              User Agreement and Disclaimer
            </h4>
            <p>
              Welcome to <a href="https://suyatra.com.np">suyatra.com.np</a>. Please carefully review our Privacy
              Disclaimer and User Agreement before engaging with our services.
              By accessing and using suyatra.com.np, you consent to comply with
              the following terms.
            </p>
            <section>
              <h6 className="heading">Copyright, Trademark, and Permissions</h6>
              <p>
                The content featured on suyatra.com.np is protected under
                Nepal’s copyright laws as a collective work or compilation and
                remains the intellectual property of SuYatra Tech Pvt. Ltd., its
                affiliates, or third parties who have provided content.
                Third-party contributions are acknowledged as the property of
                their respective owners. Unauthorized reproduction,
                distribution, modification, or exploitation of any content is
                prohibited. Content may be downloaded for personal,
                non-commercial use only, and must retain all copyright notices.
                Any other use, including storage, is expressly forbidden without
                prior permission.
              </p>
            </section>
            <section>
              <h6 className="heading">Disclaimer of Liability</h6>
              <p>
                SuYatra provides access to information and materials as a
                service. However, we do not assume responsibility for any
                dissatisfaction regarding services chosen based on this
                information. All content provided by our partners is accepted
                “as is,” with the understanding that it is owned by them and
                deemed appropriate for publication.
              </p>
              <p>
                SuYatra, along with its affiliates, advisors, licensees, and
                employees, disclaims responsibility for the accuracy of
                information supplied by our partners and will not be liable for
                any loss, damage, injury, or expense that may arise from the use
                of or reliance on this information.
              </p>
            </section>
            <section className="third-party-websites">
              <h6 className="heading">Third Party Websites Links</h6>
              <p>
                <a href="https://suyatra.com.np">suyatra.com.np</a>
                contains links to external websites. We do not endorse or
                guarantee the accuracy, authenticity, reliability, or
                completeness of any information found through these links. They
                are provided solely for your convenience.
              </p>
            </section>
            <section className="revision">
              <h6 className="heading">Revision to Terms</h6>
              <p>
                The terms and conditions of suyatra.com.np are subject to change
                without notice. Your continued use of the site signifies your
                acceptance of any revised terms.
              </p>
            </section>
            <section className="privacy">
              <h6 className="heading">Privacy Assurance</h6>
              <p>
                We are committed to protecting your privacy. Information
                collected will be used exclusively for the purposes stated and
                will not be shared with third parties. We ensure that all data
                is handled in accordance with its intended use.
              </p>
            </section>
            <section>
              <h6 className="heading">Reporting Inappropriate Content</h6>
              <p>
                Should you come across any concerning content on suyatra.com.np,
                please reach out to us via our customer support contact page.
              </p>
            </section>
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyDisclaimer;
