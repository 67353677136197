import * as Yup from "yup";

const heroSchema = Yup.object().shape({
    // search: Yup.string().required("search is required"),
  });
  var date = new Date();
  var end = date.setDate(date.getDate() + 1)
  const initialValues = {
    search: "",
    endDate: new Date(end),
    startDate: new Date(),
    count: ''
  };

  export {heroSchema , initialValues}