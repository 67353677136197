import React, { useState } from "react";

import Header from "../../organisms/Header";
import Footer from "../../molecules/Footer";
import "../../../assets/styles/paymentSuccess.css";
import { useStringHook } from "../../../hooks/useStringHook";
import { FiMail } from "react-icons/fi";
import { useQuery } from "react-query";
import { useUnsubscribe } from "../../../hooks/useUnsubscribe";
import { subscriptionsServices } from "../../../services/api/subscriptions";
import { FallBackView } from "../FallBackView";
import { BsPatchCheckFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import Error from "../Error";

const SubscriptionPage = () => {
  const query = useStringHook();
  const email = query.get("email") ?? "";
  const { data, isLoading , isError } = useQuery(["news-letter"], () =>
    subscriptionsServices.checkNewsLetterSubscriptions(email)
  );
  const [transactionMail, setTransactionMail] = useState(false);
  const [marketingMail, setMarketingMail] = useState(false);
  const mutation = useUnsubscribe();
  const handleSubmit = (values) => {
    mutation.mutate({ email, transactionMail, marketingMail });
  };

  if (isLoading) return <FallBackView />;
  if(isError) return <Error  />

  const disabled = !transactionMail && !marketingMail;

  return (
    <div className="payment-success-page w-100">
      <Header />
      <div className="payment-success my-5">
        {mutation.isSuccess ? (
          <div>
            <div className="success-icon text-center pb-4">
              <BsPatchCheckFill size={100} className="text-success" />
            </div>
            <h5 className="font-weight-light pt-2">
              Unsubscribe Request Completed
            </h5>
            <Link
                className="btn btn-outline-primary w-100 rounded-pill mt-3"
                to="/"
              >
                Explore more
              </Link>
            <button
                className="btn btn-outline-danger w-100 rounded-pill mt-3"
                onClick={() => window.close()}
              >
                Cancel
              </button>
          </div>
        ) : (
          <div className="">
            <div className="success-icon text-center pb-2">
              <FiMail size={50} className="text-info" />
            </div>
            <h6 className="font-weight-light pt-2">
              Communication Preferences
            </h6>
            <p className="text-muted small">
              Your email address :{" "}
              <span className="font-weight-bold">
                {email}
              </span>
            </p>
            <div>
              <p>
                Would you really like to unsubscribe and miss all the flash
                deals in the future?{" "}
              </p>
              <div className="text-left">
                {data.is_newsletter_subscribed && (
                  <div>
                    <label className="small-text">
                      <input
                        type="checkbox"
                        className="mr-2"
                        value={marketingMail}
                        onChange={() => setMarketingMail((prev) => !prev)}
                      />
                      Yes, I want to unsubscribe from all marketing emails
                    </label>
                  </div>
                )}
                {data.is_transactional_mail && (
                  <div>
                    <label className="small-text ml-2">
                      <input
                        type="checkbox"
                        value={transactionMail}
                        className="mr-2"
                        onChange={() => setTransactionMail((prev) => !prev)}
                      />
                      <span className="small">
                        I want to unsubscribe from transactional emails too and
                        know the risk.
                      </span>
                    </label>
                  </div>
                )}
              </div>
              <button
                type="submit"
                className="btn btn-primary w-100 rounded-pill mt-3"
                onClick={handleSubmit}
                disabled={disabled || mutation.isLoading}
              >
                Submit
              </button>
              <button
                className="btn btn-outline-danger w-100 rounded-pill mt-3"
                onClick={() => window.close()}
              >
                Cancel
              </button>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default SubscriptionPage;
