import { useMutation } from "react-query";
// import { toast } from "react-toastify";

import { paymentServices } from "../../../../services/api/payment";

export const NicAsiaForm = ({ amount, bookingReference }) => {
  //firing api or sideeffect to api / suyatra's backend
  const nicAsiaInitiate = useMutation(paymentServices.paymentWithNicAsiaBank, {
    onSuccess: (data) => {
      const path = `${process.env.REACT_APP_NICASIA_UAT_URL}/pay`;
      function post(path, data) {
        var form = document.createElement("form");
        form.setAttribute("method", "POST");
        form.setAttribute("action", path);
        form.setAttribute("enctype", "multipart/form-data");
        for (var key in data) {
          var hiddenField = document.createElement("input");
          hiddenField.setAttribute("type", "hidden");
          hiddenField.setAttribute("name", key);
          hiddenField.setAttribute("value", data[key]);
          form.appendChild(hiddenField);
        }
        document.body.appendChild(form);
        form.submit();
      }
      if (data) {
        post(path, data.data);
      }
    },
  });

  //mutating api or sideeffect to api / suyatra's backend
  const handlePayment = () => {
    nicAsiaInitiate.mutate({ amount, bookingReference });
  };

  return (
    <>
      <button
            type="submit"
            className="btn btn-outline-primary rounded-pill col-12 btn-lg my-4 font-weight-bold"
            onClick={handlePayment}
            disabled={nicAsiaInitiate.isLoading}
            >
            {!nicAsiaInitiate.isLoading && (
                <span className="indicator-label">
                      Pay Now
                </span>
            )}
            {nicAsiaInitiate.isLoading && (
                <span
                    className="indicator-progress"
                    style={{ display: "block" }}
                >
                    Please wait...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
            )}
      </button>
    </>
  );
};
