import moment from "moment";

import { formatDate } from "../../utils/formatdate";

const startDate =  moment(new Date()).add(1, "days");
const endDate = moment(startDate).add(1, "days");

export const searchParams = (location , locationInfo , campaignCode) => {
    const params = {
        startDate: formatDate(startDate),
        endDate: formatDate(endDate),
        search: location || '',
        adult: 2,
        child: 0,
        rooms: 1,
        latitude: locationInfo?.lat || '',
        longitude: locationInfo?.long || '',
        address: location || '',
        searchBy: "city",
        campaignCode : campaignCode ?? ''

    }
    return new URLSearchParams(params).toString();
};
