import axios from "../../setup/axios/SetupAxios";

/*********************************************
 * news-letter Routes
 ********************************************/

const subscribeNewsletter = async () => {
  const { data } = await axios.patch(`/user/newsletter/subscribe`);
  return data;
};

const postNewsletter = async (email) => {
  const { data } = await axios.post(`/newsletter/subscribe`, {
    email,
  });
  return data;
};

const unsubscribeNewsletter = async () => {
  const response = await axios.patch(`/user/newsletter/unsubscribe`);
  return response;
};

export const newsletterServices = {
  subscribeNewsletter,
  postNewsletter,
  unsubscribeNewsletter,
};
