import React from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { paymentServices } from "../../../../services/api/payment";

const KhaltiPaymentForm = ({ amount, bookingReference }) => {
  const mutation = useMutation(paymentServices.paymentWithKhalti);

  const handlePayment = () => {
    const returnUrl = `${process.env.REACT_APP_BASE_URL}/payment-verification-page`;
    mutation.mutate({
      amount: Number(amount * 100),
      bookingReference,
      returnUrl,
    } , {
      onSuccess : (data) => {
       window.location.replace(data?.data?.payment_url);
      },
      onError : (error) => {
        toast.error(`Please try agian later`)
      }
    });
    // try {
    //   const response = await fetch(
    //     `${process.env.REACT_APP_KHALTI_UAT_URL}/epayment/initiate/`,
    //     {
    //       method: "POST",
    //       headers: {
    //         "Content-Type": "application/json",
    //         "Authorization": `Key ${process.env.REACT_APP_KHALTI_CONFIG_KEY}`,
    //       },
    //       body: JSON.stringify({
    //         amount: parseInt(amount * 100),
    //         purchase_order_id: `${PAYMENT_METHOD_UNIQUE_KEY.key_khalti}${bookingReference}`,
    //         purchase_order_name: "Booking app",
    //         return_url: `${process.env.REACT_APP_BASE_URL}/payment-success-page`,
    //         website_url: `${process.env.REACT_APP_BASE_URL}`,
    //       })
    //     }
    //   );

    //   const data = await response.json();
    //   window.location.replace(data.payment_url);
    // } catch (error) {
    //   // console.error(error);
    //   toast.error(`Please try again later`)
    // }
  };

  return (
      <button
            type="submit"
            className="btn btn-outline-primary rounded-pill col-12 btn-lg my-4 font-weight-bold"
            onClick={handlePayment}
            disabled={mutation.isLoading}
            >
            {!mutation.isLoading && (
                <span className="indicator-label">
                      Pay Now
                </span>
            )}
            {mutation.isLoading && (
                <span
                    className="indicator-progress"
                    style={{ display: "block" }}
                >
                    Please wait...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
            )}
      </button>
  );
};

export default KhaltiPaymentForm;
