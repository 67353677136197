import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import { Navbar as MainNavbar } from "react-bootstrap";
import { Nav, Container } from "react-bootstrap";
import { Group, Menu } from "@mantine/core";
import { Drawer } from "@mantine/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";

import logo from "../../assets/images/SuyatraLogo.png";
import { useAuth } from "../../hooks/useAuth";
import "../../assets/styles/navbar.css";
import { links } from "../../utils/mock/navbarLinks";
import { UserButton } from "../../_helpers/UserButton";
import { useWindowSize } from "../../hooks/useWindowSize";

const Navbar = ({ role }) => {
  const { user } = useAuth();
  const [opened, setOpened] = useState(false);
  const { width } = useWindowSize();

  const isMobileMenu = width <= 992;

  return (
    <MainNavbar collapseOnSelect expand="lg">
      <Container>
        <MainNavbar.Brand>
          <NavLink to="/" exact={true}>
            <img src={logo} alt="Company Logo" />
          </NavLink>
        </MainNavbar.Brand>
        {isMobileMenu ? (
          // open mobile(min width 992px) menu drawer
          <>
            <Drawer
              opened={opened}
              onClose={() => setOpened(false)}
              title="Menu"
              position="right"
              color="#000"
              padding="xl"
            >
              <Nav className="ml-auto">
                {links?.map((item) => (
                  <NavLink
                    key={item.id}
                    to={`${item?.to}`}
                    className="nav-link"
                    exact
                  >
                    {item?.title}
                  </NavLink>
                ))}
                {isMobileMenu && (
                  <>
                    <NavLink to={`/about`} className="nav-link" exact>
                      About Us
                    </NavLink>
                    <NavLink to={`contact-us`} className="nav-link" exact>
                      Contact Us
                    </NavLink>
                    <NavLink to={`/terms-condition`} className="nav-link" exact>
                      Terms and Conditions
                    </NavLink>
                  </>
                )}
                {role === "supplier" ? (
                  <>
                    <a
                      rel="noreferrer"
                      href={`${process.env.REACT_APP_SUPPLIER_URL}`}
                      target="_blank"
                      className="btn btn-md-outline-primary px-4 text-white small"
                    >
                      Become A Supplier
                    </a>
                  </>
                ) : (
                  <>
                    {!user ? (
                      <>
                        <li className="m-2">
                          <Link
                            to="/user/login"
                            className="btn btn-primary px-4 text-md-white small"
                          >
                            <FontAwesomeIcon icon={faLock} className="pr-1 " />
                            Login/Register
                          </Link>
                        </li>
                        <li className="nav-item ml-2">
                          <Link
                            to="/supplier/home"
                            className="btn btn-primary px-4 text-md-white small"
                          >
                            Become A Supplier
                          </Link>
                        </li>
                      </>
                    ) : (
                      <Group position="center">
                        <Menu
                          withArrow
                          placement="center"
                          control={
                            <UserButton image={user?.profile_image?.url} />
                          }
                        >
                          <Link to="/profile" className="w-100">
                            <Menu.Item>My Profile</Menu.Item>
                          </Link>
                          <Link to="/bookings" className="w-100">
                            <Menu.Item>My Bookings</Menu.Item>
                          </Link>
                          <Link to="/user/logout">
                            <Menu.Item>Logout</Menu.Item>
                          </Link>
                        </Menu>
                      </Group>
                    )}
                  </>
                )}
              </Nav>
            </Drawer>
            <div className="d-flex">
              <button
                onClick={() => setOpened(true)}
                className="btn btn-primary"
              >
                <MainNavbar.Toggle aria-controls="responsive-navbar-nav" />
              </button>
            </div>
          </>
        ) : (
          // open desktop menu drawer
          <>
            <MainNavbar.Toggle aria-controls="responsive-navbar-nav" />
            <MainNavbar.Collapse id="responsive-navbar-nav">
              <Nav className="ml-auto">
                {links?.map((item) => (
                  <NavLink
                    key={item.id}
                    to={`${item?.to}`}
                    className="nav-link"
                    exact
                  >
                    {item?.title}
                  </NavLink>
                ))}
                {role === "supplier" ? (
                  <>
                    <a
                      rel="noreferrer"
                      href={`${process.env.REACT_APP_SUPPLIER_URL}`}
                      target="_blank"
                      className="btn btn-outline-primary px-4 text-white small"
                    >
                      Become A Supplier
                    </a>
                  </>
                ) : (
                  <>
                    {!user ? (
                      <>
                        <li className="nav-item ml-2">
                          <Link
                            to="/supplier/home"
                            className="btn btn-outline-primary px-4 text-white"
                          >
                            Become A Supplier
                          </Link>
                        </li>
                        <li className="m-2">
                          <Link
                            to="/user/login"
                            className="btn btn-outline-primary px-4 text-white nav-login"
                          >
                            <FontAwesomeIcon icon={faLock} className="pr-2" />
                            Login/Register
                          </Link>
                        </li>
                      </>
                    ) : (
                      <Group position="center">
                        <Menu
                          withArrow
                          placement="center"
                          control={
                            <UserButton image={user?.profile_image?.url} />
                          }
                        >
                          <Link to="/profile" className="w-100">
                            <Menu.Item>My Profile</Menu.Item>
                          </Link>
                          <Link to="/bookings" className="w-100">
                            <Menu.Item>My Bookings</Menu.Item>
                          </Link>
                          <Link to="/user/logout">
                            <Menu.Item>Logout</Menu.Item>
                          </Link>
                        </Menu>
                      </Group>
                    )}
                  </>
                )}
              </Nav>
            </MainNavbar.Collapse>
          </>
        )}
      </Container>
    </MainNavbar>
  );
};

export default Navbar;
