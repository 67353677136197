import React from 'react'

const Counter = (props) => {
    const { setCount, count } = props
    const decrement = () => {
        if (count === props.totalCount) {
            return
        }
        setCount(prevCount => prevCount - 1)
    }
    return (
        <div className='d-flex align-items-center justify-content-between m-2'>
            <div className='text-capitalize'>
                <button className='pr-1 count'>{count}<span className='pl-2'>{props.name}</span></button>
            </div>
            <div className='counter-wrapper ml-4'>
                <button className='counter-button' onClick={decrement}>
                    <span>-</span>
                </button>
                <button className='count mx-1'>
                    {count}
                </button>
                <button
                    className='counter-button'
                    onClick={() => setCount(prevCount => prevCount + 1)}
                    disabled={count === 50}>
                    <span>+</span>
                </button>
            </div>
        </div>
    )
}

export default Counter
