import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { DatePicker } from '@mantine/dates';
import dayjs from 'dayjs';

import { usePackageSearch } from "../../hooks/usePackageSearch";
import "../../assets/styles/bookList.css";
import DescriptionTitle from "../atoms/DescriptionTitle";
import { isPackageAvailable } from "../../_helpers/isPackageAvailable";
import { CgDanger } from "react-icons/cg";

const BookPackage = ({ data, packageSlug }) => {
  const [value, setValue] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const { handlePackageSearch } = usePackageSearch();
  const history = useHistory();
  const [count, setCount] = useState(1);

  const bookingStartDate = Boolean(data?.is_fixed_departure) ? data?.start_date_time : value;
  const bookingEndDate = Boolean(data?.is_fixed_departure) ? data?.end_date_time : endDate;

  console.log({ bookingStartDate, bookingEndDate });

  const onFormSubmit = (e) => {
    e.preventDefault();
    const totalAmount = count * data?.offer_price || count * data?.unit_price;
    handlePackageSearch({ destination: data?.destination, count, totalAmount, bookingStartDate, bookingEndDate });
    history.push({
      pathname: `/checkout/${packageSlug}`,
    });
  };
  const decrement = () => {
    if (count > 1) {
      setCount(count - 1);
    }
  };
  const increment = () => {
    setCount(count + 1);
  };
  const packageAvailable = isPackageAvailable(data?.cutoff_date_time);

  const handleEndDate = (e) => {
    console.log({e});
    if (e) {
      const newStartDate = dayjs(e);
      setValue(newStartDate.toDate());
      
      if (data?.days) {
        const newEndDate = newStartDate.add(data?.days, 'day').toDate();
        setEndDate(newEndDate);
      }
    } else {
      setValue(null);
      setEndDate(null);
    }
  };
  return (
    <section className="book-list">
      <div className="card">
        <div className="card-body">
          <DescriptionTitle title="Buy this package" />
          <hr />
          <form className="w-100  pb-10">
            {
              Boolean(data?.is_fixed_departure) === false ? (
                <>
                  <DatePicker label="Select Tour Start Date"
                    minDate={dayjs(new Date(data?.cutoff_date_time)).toDate()}
                    placeholder="Select Tour Start Date" required value={value} onChange={(e) => handleEndDate(e)} />
                  <DatePicker label="Select Tour End Date"
                    disabled
                    mt={'md'}
                    placeholder="Tour End Date" required value={endDate} onChange={setEndDate} />
                </>
              )
                : null
            }
            <div className="cart-counter">
              <p className="mb-0 mr-4">
                <FontAwesomeIcon icon={faUserPlus} className="mr-2" />
                Persons
              </p>
              <button
                type="button"
                onClick={decrement}
                style={{ cursor: "pointer" }}
              >
                <span>-</span>
              </button>
              <button type="button">
                <span className="border number">{count}</span>
              </button>
              <button
                type="button"
                onClick={increment}
                style={{ cursor: "pointer" }}
                disabled={count === data?.free_seats}
              >
                <span>+</span>
              </button>
            </div>
            <p>
              Price (Per Person):
              <span className="float-md-end ml-2">
                {data?.offer_price ? (
                  <>
                    <span className="strike-price mb-0 px-2">
                      (Rs. {data.unit_price})
                    </span>
                    <span className="card-footer-amount">
                      Rs. {data?.offer_price}
                    </span>
                  </>
                ) : (
                  <span className="card-footer-amount">
                    Rs. {data.unit_price}
                  </span>
                )}
                {/* Rs. {data?.offer_price || data?.unit_price} */}
              </span>
            </p>
            <p className="font-weight-bold">
              Total Cost :
              <span className="float-md-end ml-2">
                {data?.offer_price ? (
                  <span>Rs. {(count * data?.offer_price).toFixed(2)}</span>
                ) : (
                  <span>Rs. {(count * data?.unit_price).toFixed(2)}</span>
                )}
              </span>
            </p>
            <hr />
            {(data.is_fixed_departure && !packageAvailable) ? (
              <p className="text-danger">
                <CgDanger className="mr-2" />
                Sorry this package is not available now.
              </p>
            ) : null}
            {(Boolean(data.is_fixed_departure) === false && !value && !endDate) ? (
              <p className="text-danger">
                <CgDanger className="mr-2" />
                Please select Start date
              </p>
            ) : null}
            {(Boolean(data.is_fixed_departure) === false) ? (
              <button
                type="submit"
                id="kt_sign_in_submit"
                className="btn btn-md p-2 btn-primary w-100 my-3"
                onClick={(e) => onFormSubmit(e)}
                disabled={!value}
              >
                <span className="indicator-label">Book Now</span>
              </button>
            ) : <button
              type="submit"
              id="kt_sign_in_submit"
              className="btn btn-md p-2 btn-primary w-100 my-3"
              onClick={(e) => onFormSubmit(e)}
              disabled={!packageAvailable}
            >
              <span className="indicator-label">Book Now</span>
            </button>}
          </form>
        </div>
      </div>
    </section>
  );
};

export default BookPackage;
