import React from "react";
import { createMarkup } from "../../_helpers/createMarkup";

const DangerouslySetHtml = (props) => {
  const { contents, className } = props;
  return (
    <div
      className={`${className}`}
      dangerouslySetInnerHTML={createMarkup(contents)}
    ></div>
  );
};

export default DangerouslySetHtml;
