import React, { useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { Form, Formik } from "formik";
import { Card } from "react-bootstrap";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { usePackageSearch } from "../../../hooks/usePackageSearch";

import { packageSchema } from "../../validation/PackageSearch";
import SearchLocationInput from "../../SearchLocationInput";
import CounterBox from "../../molecules/CounterBox";
import DescriptionTitle from "../../atoms/DescriptionTitle";

const SearchBox = ({
  data,
  count,
  setCount,
  search,
  longitude,
  latitude,
  address,
}) => {
  const { handlePackageSearch } = usePackageSearch();

  const history = useHistory();

  const [location, setLocation] = useState("");

  const [query, setQuery] = useState(address);

  const initialValues = {
    search: search || data?.destination,
  };

  const onFormSubmit = (value) => {
    if (location) {
      latitude = location.geometry.location.lat();
      address = location.name;
      longitude = location.geometry.location.lng();
    }
    history.push({
      pathname: `/package/search`,
      state: {
        search: value.search || data?.destination,
        count,
        latitude,
        longitude,
        address,
      },
    });
    handlePackageSearch({
      destination: value.search,
      count,
      latitude,
      longitude,
      address,
    });
  };

  return (
    <Card style={{ position: "static" }}>
      <Card.Body>
        <Formik
          validationSchema={packageSchema}
          initialValues={initialValues}
          onSubmit={onFormSubmit}
        >
          {() => (
            <Form>
              <DescriptionTitle title="Search" />
              <hr />
              <label className="mb-0">Destination:</label>
              <SearchLocationInput
                query={query}
                setQuery={setQuery}
                setLocation={setLocation}
                placeholder="Where are you going?"
                />
              <label className="mt-3">Travellers:</label>
              <CounterBox count={count} setCount={setCount} type="package" />
              <button
                type="submit"
                className="btn btn-primary btn-lg justify-content-center rounded-0 d-flex align-items-center col-12 mt-3"
              >
                <FontAwesomeIcon icon={faSearch} />
                <span className="mx-2">Search</span>
              </button>
            </Form>
          )}
        </Formik>
      </Card.Body>
    </Card>
  );
};

export default SearchBox;

SearchBox.propType = {
  count: PropTypes.number.isRequired,
  setCount: PropTypes.func.isRequired,
};
