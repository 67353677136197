import React, { useRef } from 'react'
import Slider from 'react-slick'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltLeft, faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";

const RoomGallery = ({ singleRoom }) => {

    const slider = useRef(null);

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
    };

    return (
        <>
            <Slider ref={slider} {...settings}>
                {
                    singleRoom?.files?.map((item) => (
                        <div key={item.id}>
                            <img alt={item.name || "room images"} src={item.url} className="room-image" style={{ aspectRatio: '2 / 1' }} />
                        </div>
                    ))
                }
            </Slider>
            <div style={{ textAlign: "right" }}>
                <button className="btn btn-white" onClick={() => slider.current.slickPrev()}>
                    Prev
                    <FontAwesomeIcon icon={faLongArrowAltLeft} className="text-primary ml-3" />
                </button>
                <button className="btn btn-white" onClick={() => slider.current.slickNext()}>
                    <FontAwesomeIcon icon={faLongArrowAltRight} className="text-primary mr-3" />
                    Next
                </button>
            </div>
        </>
    )
}

export default RoomGallery