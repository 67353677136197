import { RotateLoader } from "react-spinners";

export const FallBackView = () => {
  return (
    <div className="d-flex justify-content-center align-items-center vh-100">
      {/* <div className="spinner-grow text-primary" role="status">
        <span className="sr-only">Loading...</span>
      </div> */}
      <RotateLoader color={"#AC77EF"} />
    </div>
  );
};
