import * as Yup from 'yup'
import { regression } from '../../utils/regressions';

const profileSchema = Yup.object().shape({
    Firstname: Yup.string()
        .min(3, "Minimum 3 characters")
        .max(50, "Maximum 50 characters")
        .required("First name is required"),
    Lastname: Yup.string()
        .min(3, "Minimum 3 characters")
        .max(50, "Maximum 50 characters")
        .required("Last name is required"),
    address: Yup.string().notRequired().nullable()
        .min(3, "Minimum 3 characters")
        .max(50, "Maximum 50 characters"),
    // state: Yup.string().required("State is required"),
    phone: Yup.string()
        .required("Phone number is required").nullable()
        .matches(regression.phoneRegression, 'Phone number is not valid')
        .min(10, "Phone number must be of 10 digits")
        .max(10, "Phone number must be of 10 digits"),
    // country: Yup.string().nullable()
    //     .required("country is required"),
    DOB: Yup.date().notRequired().nullable(),
    // profileImage: Yup.mixed()
    //     .required('Profile Image is required'),
    // coverImage: Yup.mixed()
    //     .notRequired()
});

export { profileSchema }