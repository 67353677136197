import React from "react";
import { Row, Col } from "react-bootstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";

import DashboardSideBar from "../../molecules/DashboardSideBar";
import Header from "../../organisms/Header";
import Footer from "../../molecules/Footer";
import UserProfile from "../../molecules/UserProfile";
import { FallBackView } from "../FallBackView";
import Error from "../Error";
import { profileSchema } from "../../validation/EditProfile";
import useGetUser from "../../../hooks/reactQueryCustomHook/user/useGetUser";
import { useEditProfile } from "../../../hooks/reactQueryCustomHook/user/useEditProfile";
import SubmitButton from "../../atoms/SubmitButton";

import "../../../assets/styles/dashboardEditProfile.css";

const DashboardEditProfile = () => {
  const { data, isLoading: loadings, isError: error } = useGetUser();
  const { isLoading, mutate } = useEditProfile();

  const onFormSubmit = async (value) => {
    mutate({ ...value });
  };

  if (loadings) return <FallBackView />;
  if (error) return <Error />;

  var firstName = data?.name.split(" ").slice(0, -1).join(" ");
  var lastName = data?.name.split(" ").slice(-1).join(" ");

  const initialValues = {
    Firstname: firstName,
    Lastname: lastName,
    address: data?.profile?.address,
    state: data?.profile?.state,
    phone: data?.profile?.phone,
    country: data?.profile?.country,
    DOB: data?.profile?.date_of_birth,
  };

  return (
    <div className="dashboard edit-profile">
      <Header />
      <div className="container mb-5">
        <div className="row">
          <div className="col-md-3">
            <DashboardSideBar data={data} active="active" />
          </div>
          <div className="col-md-9">
            <UserProfile data={data} />
            <div className="card">
              <div className="card-body">
                <Formik
                  validationSchema={profileSchema}
                  initialValues={initialValues}
                  onSubmit={onFormSubmit}
                >
                  {() => (
                    <Form className="">
                      <Row className="mb-3">
                        <div className="form-group col-md-6">
                          <label className="required" htmFor="FirstName">
                            First Name
                          </label>
                          <Field
                            type="text"
                            name="Firstname"
                            id="Firstname"
                            className="form-control"
                            placeholder="First Name"
                          />
                          <div className="text-danger">
                            <ErrorMessage name="Firstname" />
                          </div>
                        </div>
                        <div className="form-group col-md-6">
                          <label className="required" htmlFor="Lastname">
                            Last Name
                          </label>
                          <Field
                            placeholder="Last Name"
                            type="text"
                            className="form-control"
                            name="Lastname"
                            id="Lastname"
                            autoComplete="off"
                          />
                          <div className="text-danger">
                            <ErrorMessage name="Lastname" />
                          </div>
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <div className="form-group col-md-6" as={Col}>
                          <label htmlFor="address">
                            Address
                          </label>
                          <Field
                            placeholder="Enter Your Address"
                            type="text"
                            className="form-control"
                            name="address"
                            id="address"
                            autoComplete="off"
                          />
                          <div className="text-danger">
                            <ErrorMessage name="address" />
                          </div>
                        </div>
                        <div className="form-group col-md-6">
                          <label htmlFor="state">
                            State
                          </label>
                          <Field
                            as="select"
                            name="state"
                            className="form-control"
                            id="state"
                          >
                            <option value="">Choose</option>
                            <option value="1">Province No. 1 </option>
                            <option value="2">Province No. 2 </option>
                            <option value="Bagmati">Bagmati</option>
                            <option value="Gandaki">Gandaki</option>
                            <option value="Lumbini">Lumbini</option>
                            <option value="Karnali">Karnali</option>
                            <option value="Lumbini">Lumbini</option>
                            <option value="Sudurpashchim">Sudurpashchim</option>
                          </Field>
                          <div className="text-danger">
                            <ErrorMessage name="state" />
                          </div>
                        </div>
                      </Row>
                      <Row>
                        <div className="form-group col-md-6 mb-3">
                          <label className="required">Phone</label>
                          <Field
                            placeholder="Enter Your Phone Number"
                            type="number"
                            className="form-control"
                            name="phone"
                            id="phone"
                            autoComplete="off"
                            required
                          />
                          <div className="text-danger">
                            <ErrorMessage name="phone" />
                          </div>
                        </div>
                        <div className="form-group col-md-6 mb-3">
                          <label htmFor="country">
                            Country
                          </label>
                          <Field
                            as="select"
                            name="country"
                            id="country"
                            className="form-control"
                          >
                            <option value="Nepal">Nepal</option>
                          </Field>
                          <div className="text-danger">
                            <ErrorMessage name="country" />
                          </div>
                        </div>
                      </Row>
                      <Row>
                        <div className="mb-3 col-md-6">
                          <label>Date Of Birth</label>
                          <Field
                            max={new Date().toISOString().split("T")[0]}
                            placeholder="Enter Your Date of Birth"
                            type="date"
                            name="DOB"
                            className="form-control"
                            autoComplete="off"
                          />
                          <div className="text-danger">
                            <ErrorMessage name="DOB" />
                          </div>
                        </div>
                      </Row>
                      {/* <ImageUpload
                        name="profileImage"
                        LabelName="Upload Profile Image"
                        image={data?.profile_image}
                      >
                      </ImageUpload> */}
                      <SubmitButton
                        disabled={isLoading}
                        isLoading={isLoading}
                        title="Submit"
                      />
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default DashboardEditProfile;
