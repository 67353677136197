import React from "react";
// import ReactStarRating from "react-star-ratings-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

import "../../assets/styles/ratingBox.css";
import DescriptionTitle from "../atoms/DescriptionTitle";

const RatingBox = (props) => {
  const startCount = props?.review[0]?.star_count;
  const averageCount = props?.review[0]?.average_rating;
  return (
    <div className="card">
      <div className="card-body">
        <DescriptionTitle title="Rating and reviews" />
        <hr />
        <div className="row justify-content-left d-flex align-items-center">
          <div className="col-md-2">
            <div className="rating-box mx-auto btn btn-primary btn-lg w-100">
              <h1 className="mt-0  font-weight-bold">
                {averageCount.toFixed(2) || 0}
              </h1>
              <p className="">out of 5</p>
              <div className="ripple-container"></div>
            </div>
          </div>
          <div className="col-md-7 offset-md-2">
            <div className="rating-bar justify-content-center mr-auto">
              <table>
                <tbody>
                  <tr>
                    <td className="rating-label">Excellent</td>
                    <td className="rating-bar">
                      <div className="rating">
                        {/* <ReactStarRating
                          numberOfStar={5}
                          numberOfSelectedStar={2}
                          colorFilledStar="red"
                          colorEmptyStar="black"
                          starSize="20px"
                          spaceBetweenStar="10px"
                          disableOnSelect={false}
                          onSelectStar={(val) => {
                          }}
                        /> */}
                        <FontAwesomeIcon
                          icon={faStar}
                          className="text-warning"
                        />
                        <FontAwesomeIcon
                          icon={faStar}
                          className="text-warning"
                        />
                        <FontAwesomeIcon
                          icon={faStar}
                          className="text-warning"
                        />
                        <FontAwesomeIcon
                          icon={faStar}
                          className="text-warning"
                        />
                        <FontAwesomeIcon
                          icon={faStar}
                          className="text-warning"
                        />
                      </div>
                    </td>
                    <td className="text-right">{startCount?.fifth_star}</td>
                  </tr>
                  <tr>
                    <td className="rating-label">Good</td>
                    <td className="rating-bar">
                      <div className="rating">
                        <FontAwesomeIcon
                          icon={faStar}
                          className="text-warning"
                        />
                        <FontAwesomeIcon
                          icon={faStar}
                          className="text-warning"
                        />
                        <FontAwesomeIcon
                          icon={faStar}
                          className="text-warning"
                        />
                        <FontAwesomeIcon
                          icon={faStar}
                          className="text-warning"
                        />
                      </div>
                    </td>
                    <td className="text-right">{startCount?.fourth_star}</td>
                  </tr>
                  <tr>
                    <td className="rating-label">Average</td>
                    <td className="rating-bar">
                      <div className="rating">
                        <FontAwesomeIcon
                          icon={faStar}
                          className="text-warning"
                        />
                        <FontAwesomeIcon
                          icon={faStar}
                          className="text-warning"
                        />
                        <FontAwesomeIcon
                          icon={faStar}
                          className="text-warning"
                        />
                      </div>
                    </td>
                    <td className="text-right">{startCount?.third_star}</td>
                  </tr>
                  <tr>
                    <td className="rating-label">Poor</td>
                    <td className="rating-bar">
                      <div className="rating">
                        <FontAwesomeIcon
                          icon={faStar}
                          className="text-warning"
                        />
                        <FontAwesomeIcon
                          icon={faStar}
                          className="text-warning"
                        />
                      </div>
                    </td>
                    <td className="text-right">{startCount?.second_star}</td>
                  </tr>
                  <tr>
                    <td className="rating-label">Terrible</td>
                    <td className="rating-bar">
                      <div className="rating">
                        <FontAwesomeIcon
                          icon={faStar}
                          className="text-warning"
                        />
                      </div>
                    </td>
                    <td className="text-right">{startCount?.first_star}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RatingBox;
