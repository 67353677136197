import { useMutation } from "react-query";
import { toast } from "react-toastify";

import { authenticationService } from "../services/api/authentication";

export const useHandleLogin = () => {
  return useMutation(authenticationService.handleLogin, {
    onSuccess: () => {
      toast.success('User logged in successfully')
    },
    onError: (err) => {
      toast.error(`${err?.response?.data?.error?.message || "Please try again later."}`)
    }
  });
}