//modules
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Card, Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";

//components
import Header from "../../organisms/Header";
import Footer from "../../molecules/Footer";
import { FallBackView } from "../FallBackView";
import Error from "../Error";
import PropertyFacilites from "../../organisms/PropertyFacilites";
import CompanyInformation from "../../organisms/CompanyInformation";
import StickySearchHeader from "../../organisms/StickySearchHeader";
import Description from "../../molecules/Description";
import HealthMeasureSection from "../../organisms/HealthMeasureSection";
import RoomDescriptions from "../../organisms/RoomDescriptions";
import { useGetProperty } from "../../../hooks/reactQueryCustomHook/property/useGetProperty";
import GallerySection from "../../molecules/GallerySection";

//styling
import "../../../assets/styles/listingDesc.css";

const PropertyDescription = () => {

  const [show, setShow] = useState(false);
  const { propertySlug } = useParams();
  const {
    isLoading,
    isError,
    data,
  } = useGetProperty(propertySlug);
  useEffect(() => {
    if (window.screen.width <= 768) {
      setShow(false)
    }
    else {
      setShow(true)
    }
  }, []);

  if (isLoading) {
    return <FallBackView />;
  };
  if (isError) {
    return <Error />;
  };

  const property = data;
  const company = data?.company;

  return (
    <div className="listing-details">
      <Header />
      <StickySearchHeader />
      <section className="ads-detail pt-0">
        <Container>
          <GallerySection
            images={property.files}
            coverImage={property.cover_image?.url}
            coverImageAlt={property.cover_image?.name}
            title={property.title}
            brandName={property.brand_name}
            location={property?.location?.address}
            averageRating={property.average_rating}
            reviewsCount={property.reviews_count}
            city={property?.city}
          />
          {
            show ?
              <Row>
                <Col xl="8" md="12">
                  <Card>
                    <Card.Body>
                      <Description
                        description={property.description}
                      />
                    </Card.Body>
                  </Card>
                  <section id="overview">
                    <PropertyFacilites data={property} />
                  </section>
                </Col>
                <Col xl="4" md="12">
                  <CompanyInformation
                    data={property}
                    title={company?.name || property?.title}
                    city={company?.company_details?.city || property?.city}
                    number={company?.company_details?.contact_person_phone || property?.phone_number}
                    floors={property?.number_of_floors}
                    rooms={property?.number_of_rooms}
                    bars={property?.number_of_bars}
                  />
                </Col>
              </Row>
              :
              <>
                {!show && (
                  <div>
                    <button onClick={() => setShow(true)} className="btn btn-primary mx-auto">
                      <FontAwesomeIcon icon={faArrowDown} className="px-2" />
                      Show more details
                    </button>
                  </div>
                )
                }
              </>
          }
        </Container>
      </section>
      <RoomDescriptions propertySlug={propertySlug} />
      <Container>
        <section className="mb-5" id="safety">
          <HealthMeasureSection />
        </section>
      </Container>
      <Footer />
    </div>
  );
};

export default PropertyDescription;