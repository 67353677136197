import React from "react";
import { Link, useHistory } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";

import { bookingServices } from "../../services/api/bookings";
import SubmitButton from "./SubmitButton";
import { STATUS } from "../../_helpers/constants/statusType";

const PayNow = (props) => {
  const { isPaid, bookingReference, isReserved, isAvailability, status } =
    props;
  const history = useHistory();
  const queryClient = useQueryClient();
  const goToLink = `/payment/${bookingReference}`;
  const { mutate: rebook, isLoading } = useMutation(
    bookingServices.rebookProduct,
    {
      onSuccess: () => {
        toast.success("Booking reserved for 10 minutes!");
        history.push(goToLink);
        queryClient.invalidateQueries(["booking", bookingReference]);
      },
      onError: (error) => {
        toast.error(
          `${error.response.data.error.message}` ||
            "Server error!! please try again later"
        );
      },
    }
  );

  const reBookFnc = () => {
    rebook(bookingReference);
  };

  return (
    <div className="paynow-wrapper">
      {!isPaid &&
        status === STATUS.PAYMENT_PENDING &&
        isAvailability &&
        (!isReserved ? (
          <SubmitButton
            isLoading={isLoading}
            title="Book again"
            onClick={reBookFnc}
            className="btn-sm mr-3"
          />
        ) : (
          <Link to={goToLink} className="btn btn-success btn-sm mr-3">
            Pay Now
          </Link>
        ))}
    </div>
  );
};

export default PayNow;
