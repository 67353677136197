//modules
import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import moment from 'moment';

//reusable functions
import { differenceDate } from '../../utils/formatdate';
import { usePropertyQuery } from '../../hooks/usePropertyQuery';
import { useTotalAvailableRoom } from '../../hooks/useTotalAvailableRoom';

const CheckOutSideBar = ({ data, active }) => {
    //handling property state with custom hooks
    const { searchProperty, handlePropertySearch } = usePropertyQuery();
    const [roomCount, setRoomCount] = useState(searchProperty.rooms);
    const {totalRooms} = useTotalAvailableRoom(data.daily_availabilities);
    //initializing total date difference
    const totalDifference = differenceDate(searchProperty.startDate, searchProperty.endDate);

    const totalAmount = totalDifference * roomCount * (data?.offer_price ? Number(data.offer_price) : Number(data?.room_rate))

    //rooms selected state handlling
    const handleRoomSelected = (e) => {
        setRoomCount(e.target.value)
    };

    React.useEffect(() => {
        handlePropertySearch({
            ...searchProperty, rooms: roomCount, totalAmount: totalAmount
        })
        // eslint-disable-next-line
    }, [roomCount])

    return (
        <>
            <Card>
                <Card.Body>
                    <div className="cart">
                        <div>
                            <h6 className="mb-3">Your booking details</h6>
                            <hr />
                            <div className="row">
                                <div className="col-md-6">
                                    <p>Check-In</p>
                                    <span>
                                        {moment(searchProperty.startDate).format('YYYY-MM-DD')}
                                    </span>
                                </div>
                                <div className="col-md-6">
                                    <p>Check-Out</p>
                                    <span>
                                        {moment(searchProperty.endDate).format('YYYY-MM-DD')}
                                    </span>
                                </div>
                            </div>
                            <br />
                            <p>Count :</p>
                            <div className="d-flex align-items-center justify-content-between pb-3">
                                <p className="m-0">Rooms:
                                </p>
                                <select value={roomCount} onChange={handleRoomSelected} className="ps-2 w-50 border-0" disabled={active === 2}>
                                    {
                                        totalRooms.map((item, index) => (
                                            <option key={index}>{item}</option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div className="row border-top pt-3">
                                <div className="col-md-6">
                                    <p>Adult: {searchProperty.adult}</p>
                                </div>
                                <div className="col-md-6">
                                    <p>Child: {searchProperty.child}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Card>
            <Card>
                <Card.Body>
                    <h6 className="mb-3">Price Details</h6>
                    <hr />
                    <p>Room Rate :
                        <span className="float-right">NPR {data?.offer_price * totalDifference || data?.room_rate * totalDifference}
                            <span className='small ml-1'>({totalDifference} night)</span>
                        </span>
                    </p>
                    <p>Total Rooms :
                        <span className="float-right">{Number(roomCount)}</span>
                    </p>
                    <p className="font-weight-bold">
                        Total Price:
                        <span className="float-right">
                            NPR {totalAmount}
                        </span>
                    </p>
                </Card.Body>
            </Card>
        </>
    )
}

export default CheckOutSideBar