import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import PropTypes from "prop-types";

import { useBlogs } from "../../hooks/reactQueryCustomHook/blog/useBlogs";
import MainHeading from "../atoms/MainHeading";
import BlogCard from "../molecules/cards/BlogCard";
import Error from "../pages/Error";
import { mockData } from "../../utils/mock/data";
import SkeletonCard from "../molecules/cards/SkeletonCard";
import DangerouslySetHtml from "../atoms/DangerouslySetHtml";

const BlogSection = () => {
  const { data: blogs, isLoading, isError } = useBlogs();

  if (isError) return <Error />;

  return (
    <section className="section blog-page">
      <Container>
        <MainHeading
          text={mockData.sectionHeader.blog.title}
          para={mockData.sectionHeader.blog.subTitle}
        />
        <Row>
          {isLoading ? (
            <SkeletonCard width="100%" count={3} />
          ) : (
            blogs?.data?.slice(0, 3)?.map((blog) => (
              <Col md={4} className="d-flex align-items-stretch" key={blog.id}>
                <Link to={`/blog/${blog?.slug}`}>
                  <BlogCard
                    image={blog.files[0]?.url}
                    title={blog.title}
                    rating={false}
                    link="blog"
                    blog={blog}
                  > 
                    <DangerouslySetHtml
                      className="card-content text-muted font-weight-light"
                      contents={`${blog.body.slice(0, 70)} ...`}
                    />
                    {/* <span className="text-primary mx-2">Read more..</span> */}
                    {/* <p className="footer-box">
                      <span className="font-weight-light small">
                        By {blog?.users?.name}, <br />{" "}
                        {moment(blog?.created_at).fromNow()}
                      </span>
                    </p> */}
                  </BlogCard>
                </Link>
              </Col>
            ))
          )}
        </Row>
      </Container>
    </section>
  );
};

export default BlogSection;

BlogSection.propTypes = {
  img: PropTypes.string,
};
