import React from "react";
import { Col, Container, Row, Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

import Header from "../organisms/Header";
import Footer from "../molecules/Footer";

import "../../assets/styles/aboutPage.css";

import image1 from "../../assets/images/landing.png";

import image2 from "../../assets/images/why-choose-us/about-us-page-image2.jpg";
import { mockData } from "../../utils/mock/data";

const AboutUS = () => {
  return (
    <div className="about-page bg-light">
      <Header />
      <div className="header-section">
        <Container>
          <Col md={12} className="header-text">
            <div className="main-title text-center mb-3">
              <h4 className="section-title h2 font-weight-bold mb-3">
                About <span className="text-primary">SuYatra</span>
              </h4>
            </div>
            <p className="section-paragraph text-light mt-0">
              SuYatra, headquartered in Kathmandu, is a dynamic online travel
              marketplace offering an extensive array of travel services. We
              provide everything from holiday packages to hotel reservations and
              flight tickets, catering to all travel needs across Nepal. Our
              platform showcases special offers, weekend getaways, and provides
              easy local payment solutions, all priced in Nepalese Rupee (NPR).
            </p>
          </Col>
        </Container>
      </div>
      <div className="container my-5">
        <Row className="align-items-center mt-5">
          <Col md={5} className="order-md-1 order-2">
            <img
              src={image2}
              alt="about-us-banner"
              className="w-100 about-us-bannerimage"
              style={{ objectFit: "cover" }}
            />
          </Col>
          <Col md={7} className="order-md-2 order-1">
            <section className="py-4">
              <div className="main-title text-center mb-3">
                <h4 className="section-title h2 font-weight-bold">
                  More About <span className="text-primary">SuYatra</span>
                </h4>
              </div>
              <p className="section-paragraph text-muted">
                We at SuYatra, amalgamate hotel listings, holiday packages, and
                flight ticket prices from a wide spectrum of travel agencies,
                hotel chains, and airline companies. Our objective is to offer a
                comprehensive, one-stop solution for travelers. With our
                platform&#39;s intuitive interface and our far-reaching
                marketing strategies, we have established ourselves as a
                valuable asset for travelers across Nepal. We are committed to
                streamlining travel planning and delivering a seamless user
                experience.
              </p>
              <p className="section-paragraph text-muted">
                SuYatra serves not only as a marketplace for travelers but also
                as a platform for growth for hoteliers, travel agencies, and
                airlines. We assist businesses in expanding their reach while
                ensuring travelers secure the best deals on hotels, holiday
                packages, and tickets. It is accessible via website and mobile
                app for iOS and Android. “ Abako Yatra, SuYatra Sanga” अबको
                यात्रा सुयात्रा संग
              </p>
            </section>
          </Col>
        </Row>
        <Row className="align-items-center mt-5 ">
          <Col md={7} className=" order-md-1 order-2">
            <section className="py-4">
              <div className="main-title text-center mb-3">
                <h4 className="section-title h2 font-weight-bold">
                  Our Mission
                </h4>
              </div>
              <p className="section-paragraph text-muted">
                &quot;We are dedicated to empowering and inspiring travelers
                within Nepal by offering a reliable and user-friendly platform
                for discovering and securing a wide range of domestic travel
                experiences. Our objective is to highlight and celebrate Nepal’s
                abundant cultural heritage and breathtaking natural landscapes,
                while actively contributing to local economies and endorsing
                sustainable tourism practices. We are committed to simplifying
                the travel planning process, making it accessible, cost-
                effective, and pleasurable. We guarantee superior service
                quality to ensure our customers enjoy unforgettable journeys
                across the diverse terrains of Nepal.”
              </p>
            </section>
          </Col>
          <Col md={5} className="order-md-2 order-1">
            <img
              src={image1}
              alt="about-us-banner"
              className="w-100 about-us-bannerimage"
              style={{ objectFit: "cover" }}
            />
          </Col>
        </Row>
        <div className="main-title text-center mb-3">
          <h4 className="section-title h2 font-weight-bold mt-5 ">
            Core Values
          </h4>
        </div>

        <Row>
          {mockData.coreValues.map((item) => (
            <Col md={4}>
              <Card style={{ height: "85%" }}>
                <Card.Body>
                  <Row>
                    <div className="p-2">
                      <div className="d-flex">
                        <div className="icon mr-2">
                          {typeof item.icon === "function" ? (
                            <item.icon
                              style={{ fontSize: "24px" }}
                              className={`text-${item.color}`}
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon={item.icon}
                              style={{ fontSize: "24px" }}
                              className={`text-${item.color}`}
                            />
                          )}
                        </div>
                        <div>
                          <p className={`text-${item.color} text-capitalize`}>
                            {item.title}
                          </p>
                          <ul>
                            <li className="text-muted d-flex mb-2">
                              <FontAwesomeIcon
                                icon={faCheckCircle}
                                className="pr-2 text-success"
                              />
                              {item.paragraphOne}
                            </li>
                            <li className="text-muted d-flex mb-2">
                              <FontAwesomeIcon
                                icon={faCheckCircle}
                                className="pr-2 text-success"
                              />
                              {item.paragraphTwo}
                            </li>
                            {item.paragraphThree && (
                              <li className="text-muted d-flex mb-2">
                                <FontAwesomeIcon
                                  icon={faCheckCircle}
                                  className="pr-2 text-success"
                                />
                                {item.paragraphThree}
                              </li>
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
      {/* <section className="featured-icon-box section">
        <div className="container">
          <div className="main-title text-center">
            <h4 className="about-title">
              Why <span className="text-primary">Choose Us</span>
            </h4>
          </div>
          <div className="row text-center mt-5">
            {feature.map((item) => (
              <div className="col-md-3" key={item.id}>
                <IconBox icon={item.icon} title={item.title} />
              </div>
            ))}
          </div>
        </div>
      </section> */}
      {/* <section className="team section">
        <div className="container">
          <div className="main-title text-center ">
            <h5 className="about-title">
              Our <span className="text-primary">Team</span>
            </h5>
          </div>
          <div className="row text-center mt-5">
            {team.map((item) => (
              <div className="col-md-4" key={item.id}>
                <PlainCard
                  img={item.img}
                  cardVariant="border-0 shadow-none"
                  title={item.title}
                  description={item.para}
                />
              </div>
            ))}
          </div>
        </div>
      </section> */}
      {/* <div className="container my-4">
        <section className="user-testinomials">
          <div className="main-title text-center ">
            <h4 className="about-title">
              Featured <span className="text-primary">Testimonials</span>
            </h4>
          </div>
          <div className="review-wrapper">
            <div className="text-center my-5">
              <span className="text-primary">Load More...</span>
            </div>
          </div>
        </section>
      </div> */}
      <Footer />
    </div>
  );
};

export default AboutUS;
