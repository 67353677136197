import { Modal } from "@mantine/core";
import React from "react";

const MantineCustomModal = (props) => {
    const {title , show , setShow , children} = props;

  return (
    <Modal
      opened={show}
      onClose={() => setShow(false)}
      size="lg"
      title={title}
    >
        {children}
    </Modal>
  );
};

export default MantineCustomModal;
