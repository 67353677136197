import moment from "moment";
import { Card } from "react-bootstrap";
import { AiOutlineClockCircle } from "react-icons/ai";
import { categoryType } from "../../../../utils/categoryType";
import { differenceDate } from "../../../../utils/formatdate";

const productType = (booking) => {
  return categoryType(booking).toLowerCase();
};

export const PaymentPriceDetailCard = ({ booking }) => {
  return (
    <div className="payment-price-detail">
      <Card>
        <Card.Body>
          <div className="d-flex justify-content-between">
            <div>Total amount :</div>
            <div>
              <h4>NPR {booking.total_amount}</h4>
            </div>
          </div>
          <hr />
          {productType(booking) === "room" ? (
            <>
              <div className="d-flex justify-content-between">
                <div>
                  {booking.quantity} room x{" "}
                  {differenceDate(booking.starts_at, booking.ends_at)} night :
                </div>
                <div>NPR {booking.total_amount}</div>
              </div>
              <div className="d-flex justify-content-between">
                <div>Discounts :</div>
                <div>
                  {booking?.applied_discount
                    ? `NPR ${booking?.applied_discount?.discounted_amount}`
                    : "--"}
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="d-flex justify-content-between">
                <div>{booking.quantity} quantity :</div>
                <div>
                  NPR{" "}
                  {booking?.applied_discount?.booking_amount_before_discount ||
                    booking?.total_amount}
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div>Discounts :</div>
                <div>
                  {booking?.applied_discount
                    ? `NPR ${booking?.applied_discount?.discounted_amount}`
                    : "--"}
                </div>
              </div>
              <p className="d-flex align-items-center justify-content-end small-text">
                <AiOutlineClockCircle className="mr-2" />
                {moment(booking?.starts_at).format("LLL")}
              </p>
            </>
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

export const PaymentProductDetails = ({ booking }) => {
  const product = booking.product;
  if (categoryType(booking).toLowerCase() === "room") {
    return (
      <div className="payment-product-details">
        <Card>
          <Card.Body>
            <h6>{product.property_name}</h6>
            <p className="h6 small font-weight-light">{product?.title}</p>
            <div className="bg-light p-2 small">
              <span>
                {product.is_breakfast_included ? (
                  <>Breakfast included</>
                ) : (
                  <>No meals included</>
                )}
              </span>
              <br />
              <span>{`${product.total_beds} beds`}</span>
              <br />
              <span>{`Max . occupancy: ${product.max_allowed_guests} guests / ${product.max_allowed_childrens} childrens`}</span>
            </div>
            <div className="d-flex justify-content-between mt-4">
              <div>
                {moment(booking?.starts_at).format("LL")}
                <br />
                <span className="small">Check-in</span>
              </div>
              <div>
                {moment(booking?.ends_at).format("LL")}
                <br />
                <span className="small">Check-out</span>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
    );
  } else if (categoryType(booking).toLowerCase() === "package") {
    return (
      <div className="payment-product-details">
        <Card>
          <Card.Body>
            <h6>{product.title}</h6>
            <hr />
            <p className="small text-justify">
              {product.description.slice(0, 400)} ..
            </p>
          </Card.Body>
        </Card>
      </div>
    );
  }
  return 1;
};
