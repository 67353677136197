import React from "react";
import { useQuery } from "react-query";

import Footer from "../../molecules/Footer";
import CampaignBanner from "../../organisms/CampaignBanner";
import PackageHeadingSection from "../../organisms/PackageHeadingSection";
import { FallBackView } from "../FallBackView";
import MostPopularPlace from "../../organisms/MostPopularPlace";
import BlogSection from "../../organisms/BlogSection";
import FeaturedPackages from "../../organisms/FeaturedPackages";
import { Banner } from "../../organisms/Banner";
import { Visitor } from "../../organisms/Visitor";
import BestDeals from "../../organisms/BestDeals";
import { packageServices } from "../../../services/api/package";
import { NewsLetter } from "../../organisms/NewsLetter";

const PackageHomePage = () => {
  const { data: hotDeals, isLoading: isHotDealsLoading } = useQuery(
    "beat-deals",
    packageServices.getHotPackageDeals
  );

  return (
    <div className="packageHomePage">
      <PackageHeadingSection />
      <Banner />
      {/* <CampaignBanner type="package" /> */}
      {isHotDealsLoading ? (
        <FallBackView />
      ) : (
        <BestDeals data={hotDeals} type="package" />
      )}
      <MostPopularPlace />
      <Visitor type="package" />
      <FeaturedPackages />
      <BlogSection />
      <NewsLetter />
      <Footer />
    </div>
  );
};

export default PackageHomePage;
