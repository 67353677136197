import React from "react";
import { Alert } from "@mantine/core";
import { IoAlertCircleOutline } from "react-icons/io5";

import { bookingExpiry } from "../../_helpers/isBookingExpired";

const Timer = (props) => {
  const { bookingCreatedDate , bookingUpdatedDate , bookingReserved} = props;

  const {bookingExpiryDate} = bookingExpiry(bookingCreatedDate , bookingUpdatedDate);

  return (
    <div className="App">
        <Alert
          icon={<IoAlertCircleOutline size={16} />}
          title="Payment Information!"
          color="red"
        >
          {!bookingReserved ? (
            <p>Your booking is expired ! Please book again</p>
          ) : (
            <p>
              Your booking will be expired on{" "}
              <span className="fw-bold">{bookingExpiryDate}</span>
            </p>
          )}
        </Alert>
    </div>
  );
};

export default Timer;
