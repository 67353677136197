import { useState } from "react";

export const useOrientation = () => {
    const [grid, setGrid] = useState("vertical");

    const clickOrientation = () => {
        if (grid === "vertical") {
          setGrid("horizontal");
        } else if (grid === "horizontal") {
          setGrid("vertical");
        }
      };

      return {grid , clickOrientation}
}