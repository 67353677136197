import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

// import Rating from "../../atoms/Rating";
import "../../../assets/styles/card.css";
// import { formattedPrice } from "../../../_helpers/formattedPrice";
import Price from "../../atoms/Price";

const PackageCard = (props) => {
  const {
    image,
    title,
    children,
    alt,
    displayButton,
    displayFooter,
    // rating,
    item,
    link,
  } = props;
  return (
    <div className="card">
      <Link to={`/${link}/${item?.slug}`}>
        <div className="card-img">
          <img src={image} alt={alt ? alt : "image"} />
        </div>
        <div className="card-body p-3">
          <div className="card-title">
            <h4 className="mb-0 text-capitalize">{title?.slice(0, 23)}..</h4>
            {/* <div className="d-flex align-items-center my-2">
              {rating && <Rating star={item?.average_rating} />}
              <div className="text-muted font-weight-light pl-3">
                ({item?.reviews_count} Reviews)
              </div>
            </div> */}
          </div>
          <div className="card-description text-muted">{children}</div>
        </div>
      </Link>
      {displayFooter && (
        <div className="card-footer bg-transparent py-3 d-flex justify-content-between align-items-center">
          <div className="card-link text-primary">
            <span className="card-footer-price">
              <Price
                offerPrice={item.offer_price}
                unitPrice={item.unit_price}
              />
            </span>
          </div>
          {displayButton && (
            <div className="card-footer-button">
              <Link className="btn btn-primary" to={`/${link}/${item?.slug}`}>
                Buy Now
              </Link>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default PackageCard;

PackageCard.propTypes = {
  alt: PropTypes.string,
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  location: PropTypes.string.isRequired,
  websiteLink: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};

PackageCard.defaultProps = {
  websiteLink: "",
  location: "",
};
