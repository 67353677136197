import React from 'react';
import { Route } from 'react-router-dom';

const PublicRoutes = ({ component: Component, isAuthenticated , ...rest }) => (
    <>
    <Route {...rest} render={props => (<Component {...props} />
    )} />
    </>
)

export default PublicRoutes
