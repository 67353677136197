import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faSearch,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { Formik, Form } from "formik";
import { Container } from "react-bootstrap";
import { Modal, Button, Group } from "@mantine/core";

import { usePackageSearch } from "../../hooks/usePackageSearch";
import Navbar from "../molecules/Navbar";
import { packageSchema } from "../validation/PackageSearch";
import Counter from "../atoms/Counter";
import SearchLocationInput from "../SearchLocationInput";
import data from "../../utils/mock/data.json";
import { useWindowSize } from "../../hooks/useWindowSize";

const PackageHeadingSection = () => {
  const history = useHistory();
  const { handlePackageSearch } = usePackageSearch();
  const { width } = useWindowSize();

  const [location, setLocation] = useState("");
  const [query, setQuery] = useState("");
  const [opened, setOpened] = useState(false);
  const [count, setCount] = useState(1);

  const onFormSubmit = (value) => {
    const latitude = location.geometry.location.lat();
    const address = location.name;
    const longitude = location.geometry.location.lng();
    handlePackageSearch({
      destination: value.search,
      count,
      latitude,
      longitude,
      address,
    });
    history.push({
      pathname: `/package/search`,
      state: { search: value.search, count, latitude, longitude, address },
    });
  };

  const initialValues = {
    destination: "",
  };

  const isMobileMenu = width <= 992;

  return (
    <section className="header">
      <Navbar title=".Travel" />
      <div className="hero text-center">
        <Container>
          <h1 className="hero-title mb-4">{data.packageHeader.title}</h1>
          <h4 className="hero-subtitle mb-4">{data.packageHeader.subTitle}</h4>
          <Formik
            validationSchema={packageSchema}
            initialValues={initialValues}
            onSubmit={onFormSubmit}
          >
            {() => (
              <Form className="row">
                <div className="package-header">
                  <div
                    className="w-50"
                    style={{ borderRight: "1px dashed #000" }}
                  >
                    <div className="d-flex search-hero">
                      <FontAwesomeIcon icon={faSearch} className="location header-icon" />
                      <SearchLocationInput
                        query={query}
                        setQuery={setQuery}
                        setLocation={setLocation}
                        placeholder="Where are you going?"
                      />
                    </div>
                  </div>
                  <div className="w-50">
                    <Modal
                      opened={opened}
                      onClose={() => setOpened(false)}
                      title="Travellers :"
                      centered
                    >
                      <Counter
                        name="Count"
                        totalCount={1}
                        count={count}
                        setCount={setCount}
                      />
                    </Modal>

                    <Group position="center">
                      <Button
                        onClick={() => setOpened(true)}
                        className="btn btn-white w-100 btn-header-package"
                      >
                        <FontAwesomeIcon icon={faUsers} className="mr-2" />
                        Travellers : {count}
                        <FontAwesomeIcon icon={faCaretDown} className="ml-5" />
                      </Button>
                    </Group>
                  </div>
                </div>
                <div className={`d-flex justify-content-between align-items-center ${isMobileMenu && 'm-auto w-50'}`}>
                  {isMobileMenu ? (
                    <button
                      type="submit"
                      className="btn btn-primary btn-md rounded-pill d-flex align-items-center col-12 justify-content-center py-3 mt-3"
                    >
                      <FontAwesomeIcon icon={faSearch} />
                      <span className="mx-2">Search</span>
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="btn btn-primary btn-md rounded-0 d-flex align-items-center"
                    >
                      <FontAwesomeIcon icon={faSearch} />
                      <span className="mx-2">Search</span>
                    </button>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </Container>
      </div>
    </section>
  );
};

export default PackageHeadingSection;
