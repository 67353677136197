import React from "react";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import { Col, Row } from "react-bootstrap";

import { checkoutSchema } from "../validation/Checkout";
import "../../assets/styles/checkOut.css";
import { useAuth } from "../../hooks/useAuth";
import SubmitButton from "../atoms/SubmitButton";

const CheckoutForm = ({ onFormSubmit, isLoading }) => {
  const { user } = useAuth();
  const initialValues = {
    lead_traveller_name: user?.name || "",
    lead_traveller_email: user?.email || "",
    lead_traveller_phone: user?.profile?.phone || "",
    note: "",
    additional_traveller_details: [],
  };
  return (
    <>
      <h6 className="mt-3 h6">Enter your details</h6>
      <hr />
      <Formik
        validationSchema={checkoutSchema}
        initialValues={initialValues}
        onSubmit={onFormSubmit}
      >
        {(props) => (
          <Form>
            <div className="checkout-form">
              <Row>
                <Col md="12" className="mb-3">
                  <label htmlFor="lead_traveller_name" className="required">
                    Full name
                  </label>
                  <Field
                    type="text"
                    className="form-control"
                    name="lead_traveller_name"
                    id="lead_traveller_name"
                    required
                  />
                  <div className="text-danger">
                    <ErrorMessage name="lead_traveller_name" />
                  </div>
                </Col>
                <Col md="12">
                  <label htmlFor="lead_traveller_email" className="required">
                    Email
                  </label>
                  <Field
                    type="email"
                    className="form-control"
                    name="lead_traveller_email"
                    required
                    id="lead_traveller_email"
                  />
                  <div className="text-danger">
                    <ErrorMessage name="lead_traveller_email" />
                  </div>
                </Col>
                <Col md="6" className="mt-3">
                  <label htmlFor="lead_traveller_phone" className="required">
                    Mobile number{" "}
                    <span className="text-muted small">(primary)</span>
                  </label>
                  <Field
                    type="number"
                    className="form-control"
                    name="lead_traveller_phone"
                    id="lead_traveller_phone"
                    required
                  />
                  <div className="text-danger">
                    <ErrorMessage name="lead_traveller_phone" />
                  </div>
                </Col>
                <Col md="6" className="mt-3">
                  <label htmlFor="alternative_number">
                    Alternative mobile number{" "}
                    <span className="text-muted small">(optional)</span>
                  </label>
                  <Field
                    type="number"
                    className="form-control"
                    name="alternative_number"
                    id="alternative_number"
                  />
                  <div className="text-danger">
                    <ErrorMessage name="alternative_number" />
                  </div>
                </Col>
              </Row>
              <Row className="my-4">
                <Col md="12">
                  <label htmlFor="note">
                    Special requests{" "}
                    <span className="text-muted small">(optional)</span>
                  </label>
                  <Field
                    as="textarea"
                    rows={3}
                    className="form-control"
                    name="note"
                    id="note"
                  />
                  <div className="text-danger">
                    <ErrorMessage name="note" />
                  </div>
                </Col>
              </Row>
              <label>
                Other traveller{" "}
                <span className="text-muted small">(optional)</span>
              </label>
              <div className="form-group special-request">
                <FieldArray name="additional_traveller_details">
                  {(fieldArrayProps) => {
                    const { push, remove, form } = fieldArrayProps;
                    const { values } = form;
                    const { additional_traveller_details } = values;
                    return (
                      <>
                        {additional_traveller_details.map((friend, index) => (
                          <div key={index}>
                            <Row>
                              <Col lg={4}>
                                <div className="form-group">
                                  <label>Name</label>
                                  <Field
                                    type="name"
                                    className="form-control"
                                    name={`additional_traveller_details.[${index}].name`}
                                  />
                                  <div className="text-danger">
                                    <ErrorMessage
                                      name={`additional_traveller_details.[${index}].name`}
                                    />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <button
                              type="button"
                              onClick={() => remove(index)}
                              className="btn btn-outline-danger rounded-pill btn-sm mb-2"
                            >
                              Cancel
                            </button>
                          </div>
                        ))}
                        <button
                          type="button"
                          onClick={() => push({ name: "" })}
                          className="mutiple-field-add fw-600 btn btn-outline-primary btn-sm rounded-pill"
                        >
                          + Add New Guests
                        </button>
                      </>
                    );
                  }}
                </FieldArray>
                <div className="text-danger">
                  <ErrorMessage name="inclusions" />
                </div>
              </div>
              <br />
              <SubmitButton
                className="btn btn-md p-2 btn-primary rounded-pill w-100 my-3"
                title="Proceed now"
                isLoading={isLoading}
              />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default CheckoutForm;
