import * as Yup from 'yup'
import { regression } from '../../utils/regressions';

const checkoutSchema = Yup.object().shape({
    lead_traveller_name: Yup.string()
        .min(3, "Minimum 3 characters")
        .max(255, "Maximum 255 Characters")
        .required("Please provide the full name"),
    lead_traveller_email: Yup.string()
        .email('Please provide a valid email address')
        .required("Email is required"),
    lead_traveller_phone: Yup.string()
        .min(10, "Phone number is not valid")
        .max(10, "Phone number is not valid")
        .matches(regression.phoneRegression, 'Please provide a valid phone number')
        .required('Phone Number is required'),
    alternative_number: Yup.string()
        .min(10, "Phone number is not valid")
        .max(10, "Phone number is not valid")
        .matches(regression.phoneRegression, 'Phone number is not valid')
        .nullable(),
    note: Yup.string()
        .min(3, "Minimum 3 characters")
        .max(500, "Maximum 500 characters")
        .notRequired(),
    additional_traveller_details: Yup.array()
        .of(
            Yup.object().shape({
                name: Yup.string()
                    .min(3 , 'Minimum 3 characters')
                    .max(255)
                    .required()
                    .label('Name')
            })
        )
        .nullable()
});

export { checkoutSchema }