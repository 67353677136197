import React from "react";
import { useInfiniteQuery, useQuery } from "react-query";
import moment from "moment";

import { userServices } from "../../../services/api/userApi";
import { ReviewService } from "../../../services/api/review";
import ReviewCard from "../../molecules/ReviewCard";
import { FallBackView } from "../FallBackView";

import DashboardLayout from "../../../layout/DashboardLayout";

const DashboardReview = () => {
  const { data: user, isLoading } = useQuery("user", userServices.user);
  const {
    data,
    isLoading: isReviewLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery("reviews", ReviewService.getReviews, {
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.next_page_url) return lastPage.current_page + 1;
      return false;
    },
  });

  if (isLoading || isReviewLoading) {
    return <FallBackView />;
  }

  return (
    <DashboardLayout>
      <div className="card">
        <div className="card-body">
          <div className="card-title">
            <h5 className="card-title">Reviews section</h5>
          </div>
        </div>
      </div>
      {data?.pages?.map((page) =>
        page?.data?.length > 0 ? (
          page?.data?.map((review) => (
            <div className="card" key={review?.id}>
              <div className="card-body">
                <ReviewCard
                  rating={review?.rating}
                  date={moment(review?.created_at).format("YYYY-MM-DD")}
                  para={review?.review}
                  img={user?.profile_image?.url}
                >
                  <h5 className="card-title mb-3">{user?.name}</h5>
                  <p className="font-weight-light">{review.commentable.property_name || review.commentable.title}</p>
                </ReviewCard>
              </div>
            </div>
          ))
        ) : (
          <div className="card">
            <div className="card-body">
              <h4 className="text-center">No Review Yet</h4>
            </div>
          </div>
        )
      )}
      {hasNextPage && (
        <div className="text-center">
          {/* <Link className="text-primary">Load more..</Link> */}
          <button
            onClick={fetchNextPage}
            disabled={!hasNextPage || isFetchingNextPage}
            className="text-primary border-0"
          >
            {!isFetchingNextPage && (
              <span className="indicator-label">load more..</span>
            )}
            {isFetchingNextPage && (
              <span className="indicator-progress" style={{ display: "block" }}>
                Please wait...
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
        </div>
      )}
    </DashboardLayout>
  );
};

export default DashboardReview;
