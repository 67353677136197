import { useQuery } from "react-query";
import { avaibilityService } from "../services/api/avaibility";

export const usePackageAvaibility = ({
  where,
  total,
  sortType,
  latitude,
  longitude,
  address,
  isOffer,
  campaignCode,
  page,
}) => {
  return useQuery(
    [
      "packages",
      {
        where,
        total,
        sortType,
        latitude,
        longitude,
        address,
        isOffer,
        campaignCode,
        page,
      },
    ],
    () =>
      avaibilityService.packages({
        where,
        total,
        sortType,
        latitude,
        longitude,
        address,
        isOffer,
        campaignCode,
        page,
      })
  );
};
