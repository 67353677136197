import React from 'react';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { Form, Field, ErrorMessage, Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import Navbar from '../../molecules/Navbar';
import { authenticationService } from '../../../services/api/authentication';
import { useStringHook } from '../../../hooks/useStringHook';
import { regression } from '../../../utils/regressions';

const initValues = {
    password: "",
    password_confirmation: ""
};

const passwordSchema = Yup.object().shape({
    password: Yup.string()
        .min(8, "Minimum 8 characters")
        .max(255, "Maximum 255 characters")
        .matches(regression.passwordRegx, "Password must be at least 8 characters long including at least one UPPERCASE, one lowercase, one number and one special character")
        .required("Password is required"),
    password_confirmation: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Password is required"),
});

const ResetPassword = () => {

    const query = useStringHook();

    const history = useHistory();

    const token = query.get('token');

    const email = query.get('email');

    const { mutate, isLoading } = useMutation(authenticationService.resetPassword, {
        onSuccess: () => {
            toast.success('Password has been reset')
            history.push("/user/login")
        },
        onError: (err) => {
            toast.error(`${err?.response?.data?.error?.message}`)
        }
    });

    const handleSubmit = (values) => {
        const { password, password_confirmation } = values
        mutate({token, email, password, password_confirmation})
    };

    return (
        <div className='login'>
            <Navbar title=".Travel" />
            <div className='login-wrapper'>
                <h6 className="text-light">Find Your Account
                </h6>
                <hr />
                <Formik initialValues={initValues} validationSchema={passwordSchema} onSubmit={handleSubmit}>
                    <Form>
                        <Field
                            name="password"
                            type="password"
                            className="form-control"
                            placeholder="Enter your password" />
                        <div className='text-danger'>
                            <ErrorMessage name="password" />
                        </div>
                        <Field
                            name="password_confirmation"
                            type="password"
                            className="form-control mt-3"
                            placeholder="Enter your confirm password" />
                        <div className='text-danger'>
                            <ErrorMessage name="password_confirmation" />
                        </div>
                        <button
                            type="submit"
                            id="kt_sign_in_submit"
                            className="btn btn-md p-2 btn-primary w-100 my-3"
                            disabled={isLoading}
                        >
                            {!isLoading && <span className="indicator-label">Continue</span>}
                            {isLoading && (
                                <span className="indicator-progress" style={{ display: "block" }}>
                                    Please wait...
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            )}
                        </button>
                    </Form>
                </Formik>
            </div>
        </div>
    )
}

export default ResetPassword