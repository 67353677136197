import React from "react";
import { Form } from "react-bootstrap";
import { useQueryClient, useMutation } from "react-query";
import { useForm } from "react-hook-form";
import { toast } from 'react-toastify';

import { postReview } from "../../services/api";

const Reply = ({ slug , type , handleClose }) => {

    const queryClient = useQueryClient();

    const {
        register,
        handleSubmit,
        reset
    } = useForm();

    const { mutate, isLoading } =
        useMutation(postReview, {
            onSuccess: () => {
                queryClient.invalidateQueries()
                toast.success('Review has been posted')
                handleClose()
            },
            onError: () => {
                toast.error('please try again later')
            }
        });

    const onFormSubmit = (formData) => {
        mutate({ ...formData, slug , type}, {
            onSuccess: () => {
                reset()
            }
        })
    };

    return (
        <section className="comment-reply">
            <h4>Leave a review</h4>
            <Form onSubmit={handleSubmit(onFormSubmit)}>
                <Form.Group
                    className="mb-3"
                // controlId="exampleForm.ControlTextarea1"
                >
                    <Form.Control
                        as="textarea"
                        rows={4}
                        minLength={3}
                        maxLength={255}
                        placeholder="Comment *"
                        {...register("review", { required: "Name is required" })}
                        required
                    />
                </Form.Group>
                <Form.Group
                    className="mb-3"
                // controlId="exampleForm.ControlTextarea1"
                >
                    <select {...register("rating")} className="form-control" required>
                        <option value="">Rating</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                    </select>
                </Form.Group>
                <button type="submit" className="btn btn-primary" disabled={isLoading}>
                    {!isLoading && <span className="indicator-label">Post Reply </span>}
                    {isLoading && (
                        <span className="indicator-progress" style={{ display: "block" }}>
                            Please wait...
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                    )}
                </button>
            </Form>
        </section>
    );
};

export default Reply;
