import React from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";

// import { useAuth } from "../../hooks/useAuth";
import { userServices } from "../../services/api/userApi";
import "../../assets/styles/userSidebar.css";
import { FallBackView } from "../pages/FallBackView";
import UserAvatar from "./UserAvatar";

const DashboardSideBar = () => {
  const { data: user, isLoading } = useQuery("user", userServices.user);
  if (isLoading) return <FallBackView />;
  const links = [
    {
      title: "Profile",
      links: "/profile",
    },
    {
      title: "Bookings",
      links: "/bookings",
    },
    // {
    //   title: "Inbox",
    //   links: "/inbox",
    // },
    {
      title: "Reviews",
      links: "/reviews",
    },
    {
      title: "Password",
      links: "/settings",
    },
  ];

  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title">My Dashboard</h5>
        <hr />
        <UserAvatar name={user?.name} createdAt={user?.created_at} profileImage={user?.profile_image?.url} />
      </div>
      <div className="user-items-wrapper">
        <ul>
          {links?.map((item) => (
            <li key={item.title} className={`user-list`} ey={item.title}>
              <Link className="user-items-links" to={item.links}>
                {item.title}
              </Link>
            </li>
          ))}
          <li className={`user-list`}>
            <Link to="/user/logout" className="user-items-links">
              Logout
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default DashboardSideBar;
