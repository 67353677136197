import React from 'react';
import moment from 'moment';
import { Container, Card } from 'react-bootstrap';
import {useInfiniteQuery} from 'react-query'

import RatingBox from '../molecules/RatingBox';
import ReviewCard from '../molecules/ReviewCard';
import { FallBackView } from '../pages/FallBackView';
import { ReviewService } from '../../services/api/review';
import DescriptionTitle from '../atoms/DescriptionTitle';

const ReviewsSection = ({ slug, type }) => {

    const {
        data: review,
        isLoading,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
    } = useInfiniteQuery(['reviews', slug], () => ReviewService.getReview({ slug, type }), {
        getNextPageParam: (lastPage) => {
            if (lastPage.next_page_url) return lastPage.current_page + 1;
            return false;
        },
    })

    if (isLoading) {
        return <FallBackView />
    };

    return (
        <>
            <section className="rating-reviews">
                <Container>
                    <RatingBox review={review?.pages?.map((page) => page)} slug={slug} />
                </Container>
            </section>
            <section className="reviews mb-5">
                <Container>
                    <Card>
                        <Card.Body>
                            <DescriptionTitle title="Reviews" />
                            <hr />
                            {
                                <>
                                    {review?.pages?.map((page) => (
                                        page?.reviews?.data?.length > 0 ?
                                            page?.reviews?.data?.map((item) => (
                                                <ReviewCard
                                                    rating={item?.rating}
                                                    key={item?.id}
                                                    item={item}
                                                    date={(moment(item?.created_at).format('YYYY-MM-DD'))}
                                                    para={item.review}
                                                    img={item?.users?.profile_image?.url}>
                                                    {item?.users?.name || "Not provided"}
                                                </ReviewCard>
                                            ))
                                            :
                                            <div className="text-center my-5">
                                                <span>No reviews yet</span>
                                            </div>
                                    ))}
                                </>
                            }
                            {
                                hasNextPage &&
                                <div className="text-center">
                                    <button onClick={fetchNextPage} disabled={!hasNextPage || isFetchingNextPage} className="text-primary border-0">
                                        {!isFetchingNextPage && <span className="indicator-label">load more..</span>}
                                        {isFetchingNextPage && (
                                            <span className="indicator-progress" style={{ display: "block" }}>
                                                Please wait...
                                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                            </span>
                                        )}
                                    </button>
                                </div>
                            }
                        </Card.Body>
                    </Card>
                </Container>
            </section>
        </>
    )
}

export default ReviewsSection