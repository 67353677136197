
import { faChevronCircleDown, faChevronCircleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react'
import DescriptionTitle from '../atoms/DescriptionTitle';

const Description = (props) => {
    const [isReadMore, setIsReadMore] = useState(true);
    const { description, title } = props
    return (
        <div className="description" id="about">
            <DescriptionTitle title={`About this ${title}`} />
            <hr />
            <p className="text-justify">
                {
                    isReadMore ? description?.slice(0, 150) : description
                }
                <span className='show-more-wrapper text-center small pt-2'>
                    {
                        description?.length > 150 &&
                        <span
                            onClick={() => setIsReadMore(prev => !prev)}
                            className="text-primary text-justify ml-2"
                            style={{ cursor: 'pointer' }}>
                            {isReadMore ? <>Show More<FontAwesomeIcon className='px-2' icon={faChevronCircleDown} /></> : <>Show Less<FontAwesomeIcon className='px-2' icon={faChevronCircleUp} /></>}
                        </span>
                    }
                </span>
            </p>
        </div>
    )
}

Description.defaultProps = {
    title: 'property'
}

export default Description

