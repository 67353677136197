import React, { useEffect, useState } from "react";
import { Card, Container } from "react-bootstrap";
import SearchBarField from "../organisms/SearchBarField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";

import { useWindowSize } from "../../hooks/useWindowSize";

const StickySearchHeader = (props) => {
  const { width } = useWindowSize();
  const isMobile = width <= 768;
  const [showStickyBar, setShowStickyBar] = useState(false);
  const [showSearchBar, setShowSearchBar] = useState(isMobile ? false : true);
  const changeBackground = () => {
    if (window.scrollY >= 150) {
      setShowStickyBar(true);
    } else {
      setShowStickyBar(false);
    }
  };

  useEffect(() => {
    changeBackground();
    window.addEventListener("scroll", changeBackground);
    return () => {
      window.removeEventListener("scroll", changeBackground);
    };
  }, []);

  return (
    <>
      {showSearchBar && (
        <div
          className={`${showStickyBar ? "search-box-top" : ""} sticky-top`}
          style={{ zIndex: 100 }}
        >
          <Container>
            <Card className="shadow" style={{ overflow: "visible" }}>
              <SearchBarField refetch={props.refetch} />
            </Card>
          </Container>
        </div>
      )}
      {/* mobile search icon */}
      {isMobile && (
        <div className="mobile-icon">
        <div className="mobile-search-icon" onClick={() => setShowSearchBar(prev => !prev)}>
          {
            showSearchBar ?
            <FontAwesomeIcon icon={faTimes} />
            :
            <FontAwesomeIcon icon={faSearch} />
          }
        </div>
        </div>
      )}
    </>
  );
};

export default StickySearchHeader;
