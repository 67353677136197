import { useQueryClient , useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { userServices } from "../../../services/api/userApi";
import { useAuth } from "../../useAuth";

export const useEditProfile = () => {
    
    const { token, setAuth } = useAuth();

    const history = useHistory();

    const queryClient = useQueryClient();

    return useMutation(userServices.createProfile, {
        onSuccess: (data) => {
            const session = { token, user: data?.data }
            setAuth(session);
            history.push('/profile')
            queryClient.invalidateQueries('user')
            toast.success(`Profile updated sucessfully`)
        },
        onError: (error) => {
            toast.error(`${error?.response?.data?.error?.message}`)
        },
    })
};
