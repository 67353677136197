import * as Yup from 'yup';
import { regression } from '../../utils/regressions';

const initialValues = {
  userName: "",
  password: "",
};

const loginSchema = Yup.object().shape({
  // userName: Yup.string()
  //   .email("Wrong email format")
  //   .min(3, "Minimum 3 symbols")
  //   .max(255, "Maximum 255 symbols")
  //   .required("Email is required"),
  password: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(255, "Maximum 255 symbols")
    .required("Password is required"),
});

const registerSchema = Yup.object().shape({
  Firstname: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("First Name is required"),
  Lastname: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("LastName is required"),
  email: Yup.string()
    .email("Wrong email format")
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Email is required"),
  password: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .matches(regression.passwordRegx, "Password must be at least 8 characters long including at least one UPPERCASE, one lowercase, one number and one special character")
    .required("Password is required"),
  password_confirmation: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Password is required"),
  terms: Yup.boolean().oneOf([true], 'Terms and condition should be accepted'),
  phoneNumber: Yup.string()
  .min(10, "Phone number is not valid")
  .max(10, "Phone number is not valid")
  .matches(regression.phoneRegression, 'Phone number is not valid')
  .required('Phone Number is required'),
});

const initValues = {
  email: "",
  Firstname: "",
  Lastname: "",
  password: "",
  password_confirmation: "",
  phoneNumber: '',
  terms: false
};

export { initialValues, loginSchema, initValues, registerSchema }