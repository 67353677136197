import React from "react";
import { Row } from "react-bootstrap";
import moment from "moment";

const BookingList = ({ booking, CategoryType }) => {
  const {
    price,
    quantity,
    currency,
    lead_traveller_email,
    lead_traveller_name,
    booking_reference,
    coupon
  } = booking;

  const additional_Travellers =
    booking?.additional_traveller_details.length > 0
      ? booking?.additional_traveller_details?.map((item) => (
          <li>{item?.name}</li>
        ))
      : "-";

  const data = [
    {
      id: 11,
      title: "Reference Code :",
      data: booking_reference || "-",
    },
    {
      id: 12,
      title: `${CategoryType(booking) === "Package" ? 'Package'  : 'Property' } Type :`,
      data: CategoryType(booking),
    },
    {
      id: 1,
      title: `${CategoryType(booking)} Name :`,
      data: booking?.product?.title,
    },
    {
      id: 2,
      title: "Lead Traveller Name: :",
      data: lead_traveller_name,
    },
    {
      id: 3,
      title: "Lead Traveller Email :",
      data: lead_traveller_email,
    },
    {
      id: 4,
      title: "Currency :",
      data: currency,
    },
    {
      id: 5,
      title: "Price: ",
      data: `Rs. ${price}`,
      class: "font-weight-bold",
    },
    {
      id: 6,
      title: "Total Quantity :",
      data: quantity,
    },
      {
        id: 12,
        title: "Discount Amount :",
        data: coupon ? `Rs. ${coupon?.discounted_amount} (Coupon Name : ${coupon?.coupon_name})` : '-'  ,
      },
    {
      id: 7,
      title: "Total Cost :",
      data: `Rs. ${booking?.total_amount}`,
      class: "font-weight-bold",
    },
    {
      id: 8,
      title: `${CategoryType(booking) === "Package" ? 'Start Date' : 'Check-in time'}`,
      data: moment(
        booking?.product?.start_date_time || booking?.starts_at
      ).format("YYYY-MM-DD"),
    },
    {
      id: 8,
      title: `${CategoryType(booking) === "Package" ? 'End Date' : 'Check-out time'}`,
      data: moment(
        booking?.product?.end_date_time || booking?.ends_at
      ).format("YYYY-MM-DD"),
    },
    {
      id: 9,
      title: "Note :",
      data: booking?.note || "-",
    },
    {
      id: 10,
      title: "Additional Travellers Name",
      data: additional_Travellers,
    },
    booking.is_completed && {
      id: 10,
      title: "Booking Completed",
      data: 'true',
    },
  ];

  return data?.map((item, index) => (
    <Row className="my-2" key={index}>
      <label className="col-lg-4 text-muted">{item?.title}</label>
      <div className="col-lg-8">
        <span className={`fw-bolder fs-6 text-dark ${item?.class}`}>
          {item?.data}
        </span>
      </div>
    </Row>
  ));
};

export default BookingList;
