//modules
import React from "react";
import { ReactQueryDevtools } from "react-query/devtools";
import { QueryClient, QueryClientProvider } from "react-query";
import { ToastContainer } from "react-toastify";
import { MantineProvider } from "@mantine/core";
import ReactGA from "react-ga4";

//font-family
import "@vetixy/circular-std";

//components
import { SearchPropertyContext } from "./context/SearchPropertyContext";
import { PackagesSearchContext } from "./context/PackageSearchContext";
import { OnlineStatusProvider } from "./context/OnlineStatusContext";
import { BookingReferenceProvider } from "./context/BookingReference";

//styling
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/slick-carousel/slick/slick.css";
import "../node_modules/slick-carousel/slick/slick-theme.css";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";

import Routes from "./routes/Routes";
import { ProvideAuth } from "./context/ProvideAuth";
import ScrollToTop from "./_helpers/ScrollTop";

// react query initialization with some default values
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: 2,
    },
  },
});

ReactGA.initialize(process.env.REACT_APP_API_GA_MEASUREMENT_ID);

function App() {
  return (
    <MantineProvider>
      <ProvideAuth>
        <SearchPropertyContext>
          <PackagesSearchContext>
            <BookingReferenceProvider>
              <OnlineStatusProvider>
                <QueryClientProvider client={queryClient}>
                  <ScrollToTop>
                    <Routes />
                  </ScrollToTop>
                  <ToastContainer />
                  <ReactQueryDevtools initialIsOpen={false} />
                </QueryClientProvider>
              </OnlineStatusProvider>
            </BookingReferenceProvider>
          </PackagesSearchContext>
        </SearchPropertyContext>
      </ProvideAuth>
    </MantineProvider>
  );
}

export default App;
