import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Skeleton } from "@mantine/core";

import MainHeading from "../atoms/MainHeading";
import FeatureBox from "../molecules/FeatureBox";
import "../../assets/styles/feature.css";
import { mockData } from "../../utils/mock/data";
import { useGetPackages } from "../../hooks/reactQueryCustomHook/package/useGetPackages";
import Error from "../pages/Error";

const FeaturedPackages = (props) => {
  const {
    data: featuredPackages,
    isLoading,
    isError,
  } = useGetPackages("featured");

  if(isError){
    return <Error />;
  }

  return (
    <section className="feature-place section bg-light">
      <Container>
        <MainHeading
          text={mockData.sectionHeader.featuredPlaces.title}
          para={mockData.sectionHeader.featuredPlaces.subTitle}
        />
        <Row>
          {isLoading
            ? Array.from(new Array(6)).map((number , index) => (
                <Col md={4} key={index} className="mt-3">
                  <Skeleton height={400} />
                </Col>
              ))
            : featuredPackages?.map((feature) => (
                <div className="col-xl-4 col-lg-4 col-md-12" key={feature.id}>
                  <FeatureBox
                    key={feature.id}
                    location={feature.name}
                    count={feature.count}
                    img={feature.image}
                    title={feature.title}
                    type="packages"
                  />
                </div>
              ))}
        </Row>
      </Container>
    </section>
  );
};

export default FeaturedPackages;
