import React from 'react';
import { Container } from 'react-bootstrap';

import Navbar from "../molecules/Navbar";

const SupplierHeadingSection = () => {

    return (
        <section className="header">
            <Navbar title=".Travel" role="supplier" />
            <div className="hero text-center">
                <Container>
                    <h1 className="hero-title mb-4">
                        Your Business Can Make
                        An Impact In Their Experience
                    </h1>
                    <h3 className="hero-subtitle mb-4">
                             Coordinated. Connected. Committed.
                    </h3>
                </Container>
            </div >
        </section >
    )
}

export default SupplierHeadingSection