import { faServicestack } from '@fortawesome/free-brands-svg-icons';
import {
  faShip,
  faHiking,
  faSkating,
  faAirFreshener,
  faBath,
  faBed,
  faBuilding,
  faCar,
  faDumbbell,
  faHotel,
  faParking,
  faPlane,
  faSmoking,
  faSpa,
  faSwimmingPool,
  faWifi,
  faMonument,
  faCampground,
  faParachuteBox
} from '@fortawesome/free-solid-svg-icons';

const ICONS = {
  'spa': faSpa,
  'bar': faHotel,
  'fitness centre': faDumbbell,
  'swimming pool': faSwimmingPool,
  'houseKeeping': faBuilding,
  'free wifi': faWifi,
  'airport shuttle': faPlane,
  'laundry service': faServicestack,
  'private chauffeur': faPlane,
  'steam and sauna': faBath,
  'front desk': faHotel,
  'restaurent': faHotel,
  'free parking': faParking,
  'car rental': faCar,
  'family rooms': faBed,
  'designated smoking area': faSmoking,
  'air conditioning': faAirFreshener,
  'bungee jumping': faPlane,
  'rafting & kayaking': faSkating,
  'hotel pickup and drop-off': faHotel,
  'boat ride': faShip,
  'trekking': faHiking,
  'museum tours' : faMonument,
  'camping': faCampground,
  'paragliding': faParachuteBox
}

export default ICONS
