import axios from "../../setup/axios/SetupAxios"

const unSuscribe = async({email , transactionMail , marketingMail}) => {
    return await axios.patch(`newsletter/unsubscribe?email=${email}` , {
        transactional_mail : `${transactionMail}` ,
        marketing_mail : `${marketingMail}`,
    })
};

const checkNewsLetterSubscriptions = async(email) => {
     const {data} = await axios.get(`/newsletter/status?email=${email}`)
     return data
};

export const subscriptionsServices = {
    unSuscribe,
    checkNewsLetterSubscriptions
};