import React, { useState } from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUtensils } from "@fortawesome/free-solid-svg-icons";

import { usePropertyQuery } from "../../hooks/usePropertyQuery";
import { useTotalAvailableRoom } from "../../hooks/useTotalAvailableRoom";
import { useStringHook } from "../../hooks/useStringHook";
import { IoAlertCircleOutline } from "react-icons/io5";

const BookRoom = ({ singleRoom, propertySlug }) => {
  const { totalRooms } = useTotalAvailableRoom(singleRoom.daily_availabilities);
  const { handlePropertySearch } = usePropertyQuery();
  const history = useHistory();
  const query = useStringHook();
  const search = query.get("search");
  const startDate = query.get("startDate");
  const endDate = query.get("endDate");
  const adultCount = query.get("adult");
  const childCount = query.get("child");
  const [rooms, setRooms] = useState(1);

  const handleRoom = (e) => {
    setRooms(e.target.value);
  };

  const diff = moment(endDate).diff(moment(startDate), "days");
  const totalAmount = singleRoom.offer_price
    ? singleRoom?.offer_price * rooms * diff
    : singleRoom?.room_rate * rooms * diff;

  const bookRoom = () => {
    handlePropertySearch({
      search,
      startDate,
      endDate,
      adult: adultCount,
      child: childCount,
      rooms: rooms,
      totalAmount: totalAmount.toFixed(2),
    });
    history.push({
      pathname: `/checkout/property/${propertySlug}/room/${singleRoom?.slug}`,
    });
  };

  return (
    <div className="d-md-flex justify-content-between align-items-center">
      <p>
        <span className="mb-4">Starts from</span>
        <br />
        {singleRoom?.offer_price ? (
          <span className="mb-5">
            <strike className="h5">
              Rs .{(singleRoom?.room_rate * rooms * diff).toFixed(2)}
            </strike>
            <br />
            <br />
            <span className="h2">
              NPR {(singleRoom?.offer_price * rooms * diff).toFixed(2)}{" "}
            </span>{" "}
            <span>Per night</span>
          </span>
        ) : (
          <>
            <span className="h2">
              NPR {(singleRoom?.room_rate * rooms * diff).toFixed(2)}{" "}
            </span>{" "}
            <span>Per night</span>
          </>
        )}
        {singleRoom.is_breakfast_included && (
          <p className="text-success small pt-2">
            <FontAwesomeIcon
              icon={faUtensils}
              className="text-success mr-2 small"
            />
            Breakfast included
          </p>
        )}
      </p>
      {totalRooms && (
        <div>
          Total Rooms:
          <select
            value={rooms}
            onChange={handleRoom}
            className="form-control w-100"
          >
            {totalRooms.map((item, index) => (
              <option key={index}>{item}</option>
            ))}
          </select>
        </div>
      )}
      <button
        type="submit"
        className="btn btn-sm btn-primary mt-2 px-5"
        onClick={() => bookRoom()}
        disabled={!singleRoom.is_room_available}
      >
        <span className="indicator-label">
          {singleRoom.is_room_available ? (
            "Book Now"
          ) : (
            <>
              <IoAlertCircleOutline /> Not available
            </>
          )}
        </span>
      </button>
      {/* <button onClick={() => bookRoom()} className="btn btn-primary mt-2 px-5">Book Now</button> */}
    </div>
  );
};

export default BookRoom;
