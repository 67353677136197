import { BiMap } from "react-icons/bi";
import React from "react";

import Gallery from "react-grid-gallery";

// import StarRatings from "react-star-ratings";

const GallerySection = (props) => {
  const {
    images,
    coverImage,
    coverImageAlt,
    title,
    brandName,
    location,
    // averageRating,
    // reviewsCount,
    city,
  } = props;

  const IMAGES = images.map((item) => {
    return {
      src: item.url,
      thumbnail: item.url,
      thumbnailWidth: 300,
      thumbnailHeight: 174,
      rowHeight: 175,
    };
  });

  return (
    <section className="gallery-section">
      <div className="card">
        <div className="row align-items-stretch">
          <div className="col-md-5 px-0 pr-1">
            <img
              alt={coverImageAlt}
              src={coverImage}
              className="w-100 h-100"
              style={{ objectFit: "cover" }}
            />
          </div>
          <div className="col-md-7 px-0">
            <Gallery images={IMAGES} maxRows={2} />
          </div>
        </div>
        <div className="card-body">
          <div className="ads-main-desc">
            <span className="badge badge-info badge-pill px-2 mb-2">
              {props.type === "package" ? "Package" : "Property"}
            </span>
            <div className="d-md-flex justify-content-between">
              <h4 className="py-2 text-capitalize">{title}</h4>
              <ul className="ads-desc-items d-md-flex align-items-center my-0">
                {/* <li>
                  <div className="rating">
                    <StarRatings
                      rating={averageRating}
                      starDimension="15px"
                      starSpac
                      ing="1px"
                      starRatedColor="#ffc107"
                      starEmptyColor="lightgrey"
                    />
                    <span className="pl-2">({averageRating} / 5)</span>
                    <span className="text-primary pl-3">
                      {reviewsCount} reviews
                    </span>
                  </div>
                </li> */}
                <li></li>
              </ul>
            </div>
            <ul className="ads-desc-items d-flex align-items-center">
              <li className="text-capitalize mr-3">
                <p>
                  {props.brandName ? "Brand Name" : "Category"}:{" "}
                  {props.brandName ? brandName : props.category}{" "}
                </p>
              </li>
              {city && (
                <li>
                  <p>
                    <span>
                      <BiMap className="mb-1 mr-1" />
                    </span>
                    {city + ' , '}  {location?.country || "Nepal"}
                  </p>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GallerySection;
