import ViewBookings from '../components/pages/dashboard/DashboardBooking';
import DashboardBookings from '../components/pages/dashboard/DashboardBookings';
import DashboardEditProfile from '../components/pages/dashboard/DashboardEditProfile';
import DashboardInbox from '../components/pages/dashboard/DashboardInbox';
import DashboardReview from '../components/pages/dashboard/DashboardReview';
import DashboardSettings from '../components/pages/dashboard/DashboardSettings';
import {DashboardUserDetails} from '../components/pages/dashboard/DashboardUserDetails';
import EditBookings from '../components/pages/dashboard/EditBookings';

export const privateRoutes = [
    // {
    //   path: '/offers',
    //   component: DashboardOffers,
    //   id: 1
    // },
    {
        path: '/profile',
        component: DashboardUserDetails,
        id: 2
    },
    {
        path: '/edit-profile',
        component: DashboardEditProfile,
        id: 3
    },
    {
        path: '/bookings',
        component: DashboardBookings,
        id: 4
    },
    {
        path: '/booking/:bookingReference',
        component: ViewBookings,
        id: 5
    },
    {
        path: '/edit/:bookingReference',
        component: EditBookings,
        id: 6
    },
    {
        path: '/reviews',
        component: DashboardReview,
        id: 7
    },
    {
        path: '/settings',
        component: DashboardSettings,
        id: 8
    },
    {
        path: '/inbox',
        component: DashboardInbox,
        id: 9
    }
];
