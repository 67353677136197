import React from "react";
import Navbar from "../molecules/Navbar";
import Hero from "../molecules/Hero";
import PropTypes from 'prop-types';

import "../../assets/styles/header.css";

const Header = ({ displayHero }) => {
  return (
    <section className="header">
      <Navbar title=".Travel" />
      {displayHero && <Hero />}
    </section>
  );
};

export default Header;

Header.defaultProps = {
  displayHero: false
}

Header.propTypes = {
  displayHero: PropTypes.bool,
}