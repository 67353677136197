//modules
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

//components
import { FallBackView } from "./FallBackView";
import Error from "./Error";
import RoomFacilities from "../organisms/RoomFacilities";
import ICONS from "../../_helpers/icons";
import Rating from "../atoms/Rating";
import RoomGallery from "../molecules/RoomGallery";
import Description from "../molecules/Description";
import BookRoom from "../molecules/BookRoom";
import "../../assets/styles/listingDesc.css";

//api from custom hook
import useGetRoom from "../../hooks/reactQueryCustomHook/room/useGetRoom";

const ListingDescription = ({ id, propertySlug , startDate , endDate , rooms }) => {

  const {
    isLoading,
    isError,
    data: singleRoom,
  } = useGetRoom(id, propertySlug , startDate , endDate , rooms);

  if (isLoading) return <FallBackView />;

  if (isError) return <Error />;

  return (
    <section className="ads-detail w-100" key={id}>
      <Container>
        <h5 className="mb-3">Room Images</h5>
        <Row>
          <Col md={8}>
            <RoomGallery singleRoom={singleRoom} />
            <BookRoom propertySlug={propertySlug} singleRoom={singleRoom} />
            <hr />
            <RoomFacilities data={singleRoom} />
            <Description
              title="room"
              description={singleRoom.description}
            />
          </Col>
          <Col md={4}>
            <div className="ads-main-desc pl-md-5">
              <Row>
                <Col>
                  <div className="pb-2">
                    <span className='badge badge-info badge-pill px-2 mb-2'>{singleRoom?.type}</span>
                    <h6 className="my-2 font-weight-bold text-capitalize">{singleRoom?.title}</h6>
                    <ul className="ads-desc-items">
                      <li className="mr-2 text-capitalize">
                        <span className="mr-2">Category:</span>
                        {singleRoom?.category}
                      </li>
                      <li className="mt-2">
                        Ratings:
                        <Rating star={singleRoom?.average_rating} />
                      </li>
                    </ul>
                  </div>
                  <hr />
                  <div className="pb-2">
                    <h6>Overview</h6>
                    <p className="font-weight-light mt-4">{singleRoom?.type}</p>
                    <hr />
                  </div>
                  <div className="pb-2">
                    <h6>Room Amenities</h6>
                    <ul className="mx-2">
                      <Row>
                        {
                          singleRoom?.inclusions?.map((item, index) => {
                            return (
                              <Col md={12} key={index}>
                                <li key={index} className="pr-3 py-1">
                                  <FontAwesomeIcon icon={ICONS[item.toLowerCase()] || faCheckCircle}
                                    className="text-dark mr-2" />
                                  {item}
                                </li>
                              </Col>
                            )
                          })
                        }
                      </Row>
                    </ul>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ListingDescription;
