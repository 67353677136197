import React from "react";
import { Link } from "react-router-dom";
import { formatDate } from "../../utils/formatdate";

const Posts = (props) => {
  const { item } = props;
  return (
    <Link to={`/blog/${item.slug}`}>
      <div className="post-list d-flex mb-3">
        <img
          className="thumbnail"
          src={item.files[0].url}
          alt="Meet the Steve Jobs of the Travel Industry"
          decoding="async"
          loading="lazy"
        />
        <div className="thumb-list-caption pl-3">
          <h3 className="thumb-list-title">
            <a>{item.title}</a>
          </h3>
          <p className="thumb-list-date">{formatDate(item.created_at)}</p>
        </div>
      </div>
    </Link>
  );
};

export default Posts;
