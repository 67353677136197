import axios from "../../setup/axios/SetupAxios";

/**
 * User Package Routes
 */

 export const getPackages = async (type) => {
    const { data } = await axios({
      method: "GET",
      url: `/packages?type=${type}`
    });
    return data;
  };
  
  export const getPackage = async ({ queryKey }) => {
    const [, packageSlug] = queryKey;
    const { data } = await axios.get(`/packages/${packageSlug}`);
    return data;
  };
  
  export const getPopularPackages = async () => {
    return axios.get(`/packages?type=popular`)
  };
  
  export const getFeaturedPackage = async () => {
    return await axios.get(`/packages?type=featured`);
  };
  
  export const getHotPackageDeals = async () => {
    const { data } = await axios.get(`/packages?type=hot_deals`)
    return data
  };

  export const packageServices = {
    getPackages , getPackage  , getPopularPackages , getFeaturedPackage , getHotPackageDeals
  };
  