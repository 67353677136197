import { Col, Container, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import { Skeleton } from "@mantine/core";

import { useVisitors } from "../../hooks/useVisitors";
import Error from "../pages/Error";
import CountVisitor from "./CountVisitor";

export const Visitor = ({ type }) => {
  const { data: visitor, isLoading, isError } = useVisitors(type);

  if (isError) return <Error />;

  return (
        isLoading ? <Skeleton width= "100%" height={300} /> : (
            <section className="counter-visitor section">
              <Container>
                <Row>
                  <Col md={4}>
                    {isLoading ? (
                      <Skeleton height={150} />
                    ) : (
                      <CountVisitor count={visitor?.data.user_count} subTitle="Users" />
                    )}
                  </Col>
                  <Col md={4}>
                    {isLoading ? (
                      <Skeleton height={150} />
                    ) : (
                      <CountVisitor
                        isLoading={isLoading}
                        count={visitor?.data.product_count}
                        subTitle={type}
                      />
                    )}
                  </Col>
                  <Col md={4}>
                    {isLoading ? (
                      <Skeleton height={150} />
                    ) : (
                      <CountVisitor
                        count={visitor?.data.booking_count}
                        subTitle="Bookings"
                      />
                    )}
                  </Col>
                </Row>
              </Container>
            </section>
        )
  );
};

Visitor.defaultProps = {
  type: "property",
};

Visitor.propTypes = {
  name: PropTypes.string,
};
