import React, { useState } from "react";
import { faCalendarAlt, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Formik, Form } from "formik";
import { useHistory } from "react-router-dom";
import { DateRangePicker } from '@mantine/dates';
import moment from 'moment'

import { usePropertyQuery } from "../../hooks/usePropertyQuery";
import { heroSchema, initialValues } from '../validation/HeroValidation'
import "../../assets/styles/hero.css";
import { formatDate } from "../../utils/formatdate";
import SearchLocationInput from "../SearchLocationInput";
import CounterBox from "./CounterBox";
import data from '../../utils/mock/data.json'

const Hero = () => {
  const history = useHistory();
  const { handlePropertySearch } = usePropertyQuery();
  const [location, setLocation] = useState({});
  const [query, setQuery] = useState("");
  const [date, setDate] = useState([
    moment().add(1, "days").toDate(),
    moment().add(2, "days").toDate(),
  ]);
  const [adult, setAdult] = useState(2);
  const [child, setChild] = useState(0);
  const [rooms, setRooms] = useState(1);

  const onFormSubmit = (value) => {
    const lat = location.geometry.location.lat();
    const address = location.name;
    const long = location.geometry.location.lng();
    const searchBy = location.types.includes("lodging") ? "hotel" : "city";
    const searchParams = {
      search: value.search,
      startDate: formatDate(date[0]),
      endDate: formatDate(date[1]),
      adult,
      child,
      rooms,
      latitude: lat,
      longitude: long,
      address,
      searchBy
    };
    handlePropertySearch(searchParams);
    const searchQuery = new URLSearchParams(searchParams).toString();
    history.push({
      pathname: `/search/property/listing`,
      search: searchQuery,
    })
  };

  return (
    <div className="hero">
      <div className="container"> 
        <h1 className="hero-title text-capitalize text-left">
          {data.propertyHeader.title}
        </h1>
        <h4 className="hero-subtitle mb-4 text-center">
        {data.propertyHeader.subTitle}
        </h4>
        <Formik
          validationSchema={heroSchema}
          initialValues={initialValues}
          onSubmit={onFormSubmit}
        >
          {() => (
            <Form className="d-md-flex">
              <div className="headerSearch row">
                <div className="headerSearchItem col-md-4">
                  <FontAwesomeIcon icon={faSearch} className="headerIcon" />
                  {/* <PropertyDropdown location={location} setLocation={setLocation} /> */}
                  <SearchLocationInput query={query} setQuery={setQuery} setLocation={setLocation} placeholder="Enter a place / city or hotels." />
                </div>
                <div className="headerSearchItem col-md-4">
                  <FontAwesomeIcon icon={faCalendarAlt} className="headerIcon" />
                  <DateRangePicker
                    required={true}
                    placeholder="Check-in - Check-out"
                    value={date}
                    onChange={setDate}
                    minDate={new Date()}
                    amountOfMonths={2}
                    className="w-100"
                  />
                </div>
                <div className="headerSearchItem col-md-3">
                  <CounterBox 
                    adult={adult} 
                    child={child} 
                    setAdult={setAdult} 
                    setChild={setChild} 
                    rooms={rooms} 
                    setRooms={setRooms} 
                    className="border-0"
                    type='hotel' />
                </div>
              </div>
              <div className="float-md-right">
                <button type="submit" className="headerBtn">
                  Search Now
                </button>
              </div>
            </Form>
          )}
        </Formik>
        {/* <div className="hero-alert col-md-9">
          <p className="alert-title mb-0">
            <IoAlertCircleOutline className="mr-3" />
            Get the advice you need. Check the latest COVID-19 restrictions before you travel.
            <Link to="/" className="text-white pl-2">Learn more</Link>
          </p>
        </div> */}
      </div>
    </div>
  );
};

export default Hero;


//to be done later

// import React, { useState } from "react";
// import { faCalendarAlt, faSearch } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { Formik, Form } from "formik";
// import { useHistory } from "react-router-dom";
// import { DateRangePicker } from "@mantine/dates";
// import moment from "moment";

// import PropertyDropdown from "../../components/searchInput/PropertyDropdown";
// import { usePropertyQuery } from "../../hooks/usePropertyQuery";
// import { heroSchema, initialValues } from "../validation/HeroValidation";
// import "../../assets/styles/hero.css";
// import { formatDate } from "../../utils/formatdate";
// import CounterBox from "./CounterBox";
// import data from "../../utils/mock/data.json";
// import { splitUrl } from "../../utils/splitUrl";

// const Hero = () => {
//   const history = useHistory();
//   const { handlePropertySearch } = usePropertyQuery();
//   const [location, setLocation] = useState({});
//   const [url, setUrl] = useState("");
//   const [date, setDate] = useState([
//     moment().add(1, "days").toDate(),
//     moment().add(2, "days").toDate(),
//   ]);
//   const [adult, setAdult] = useState(2);
//   const [child, setChild] = useState(0);
//   const [rooms, setRooms] = useState(1);

//   const onFormSubmit = (value) => {
//     let type = splitUrl(url);
//     const lat = location?.geometry?.location.lat || "";
//     const address = location?.name || type?.address;
//     const long = location?.geometry?.location.lng || "";
//     const searchBy = type ? (type?.searchBy === "city" ? "city" : "hotel") : "";
//     const searchParams = {
//       search: value.search,
//       startDate: formatDate(date[0]),
//       endDate: formatDate(date[1]),
//       adult,
//       child,
//       rooms,
//       latitude: lat,
//       longitude: long,
//       address,
//       searchBy,
//       placeId : location?.place_id
//     };
//     handlePropertySearch(searchParams);
//     const searchQuery = new URLSearchParams(searchParams).toString();
//     history.push({
//       pathname:
//         type?.searchBy === "city" || type?.searchBy === "property"
//           ? url
//           : `/search/property/listing`,
//       search: searchQuery,
//     });
//   };

//   return (
//     <div className="hero">
//       <div className="container">
//         <h1 className="hero-title text-capitalize text-left">
//           {data.propertyHeader.title}
//         </h1>
//         <h4 className="hero-subtitle mb-4 text-center">
//           {data.propertyHeader.subTitle}
//         </h4>
//         <Formik
//           validationSchema={heroSchema}
//           initialValues={initialValues}
//           onSubmit={onFormSubmit}
//         >
//           {() => (
//             <Form className="d-md-flex">
//               <div className="headerSearch row">
//                 <div className="headerSearchItem col-md-4">
//                   <FontAwesomeIcon icon={faSearch} className="headerIcon" />
//                   {/* <PropertyDropdown
//                     location={location}
//                     setLocation={setLocation}
//                     setUrl={setUrl}
//                     style={{
//                         input: { border: "none"  },
//                     }}
//                   /> */}
//                   <SearchLocationInput query={query} setQuery={setQuery} setLocation={setLocation} placeholder="Enter a place / city or hotels." />
//                 </div>
//                 <div className="headerSearchItem col-md-4">
//                   <FontAwesomeIcon
//                     icon={faCalendarAlt}
//                     className="headerIcon"
//                   />
//                   <DateRangePicker
//                     required={true}
//                     placeholder="Check-in - Check-out"
//                     value={date}
//                     onChange={setDate}
//                     minDate={new Date()}
//                     amountOfMonths={2}
//                     className="w-100"
//                   />
//                 </div>
//                 <div className="headerSearchItem col-md-3">
//                   <CounterBox
//                     adult={adult}
//                     child={child}
//                     setAdult={setAdult}
//                     setChild={setChild}
//                     rooms={rooms}
//                     setRooms={setRooms}
//                     className="border-0"
//                     type="hotel"
//                   />
//                 </div>
//               </div>
//               <div className="float-md-right">
//                 <button type="submit" className="headerBtn">
//                   Search Now
//                 </button>
//               </div>
//             </Form>
//           )}
//         </Formik>
//         {/* <div className="hero-alert col-md-9">
//           <p className="alert-title mb-0">
//             <IoAlertCircleOutline className="mr-3" />
//             Get the advice you need. Check the latest COVID-19 restrictions before you travel.
//             <Link to="/" className="text-white pl-2">Learn more</Link>
//           </p>
//         </div> */}
//       </div>
//     </div>
//   );
// };

// export default Hero;

