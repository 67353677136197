import React, { useState } from "react";
import { Group, Modal } from "@mantine/core";
import { Link, useLocation } from "react-router-dom";

// import SocialLinks from '../atoms/SocialLinks';
import "../../assets/styles/userProfile.css";
import { useUploadProfile } from "../../hooks/useUploadProfile";
import { convertToBase64 } from "../../utils/convertToBase64";
import SubmitButton from "../atoms/SubmitButton";
import { useAuth } from "../../hooks/useAuth";

const UserProfile = ({ data }) => {
  const {token , setAuth}= useAuth()
  const [opened, setOpened] = useState(false);
  const location = useLocation();
  const [errorMessage, setErrorMessage] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState("");
  const [image, setImage] = useState("");
  const hiddenFileInput = React.useRef(null);
  const imageUploadMutation = useUploadProfile();
  const handleClose = () => {
    setOpened(false);
    setImagePreviewUrl("");
    setErrorMessage('')
  };
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  const handleChange = async (event) => {
    const fileUploaded = event.target.files[0];
    // props.handleFile(fileUploaded);
    if (fileUploaded?.size / 1024 / 1024 < 2) {
      setErrorMessage('')
      const base64 = await convertToBase64(fileUploaded);
      setImage(base64);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreviewUrl(reader.result);
      };
      reader.readAsDataURL(fileUploaded);
    } else {
      setErrorMessage("Image must be less than 2 MB");
    }
  };
  const onUploadImage = () => {
    imageUploadMutation.mutate(image, {
      onSuccess: (data) => {
        setImagePreviewUrl("");
        setOpened(false);
        setErrorMessage("")
        const session = { token, user: data?.data }
        setAuth(session);
      },
    });
  };
  return (
    <div className="card">
      {/* <div
        className="bg-pattern"
        style={{
          backgroundImage: `url(${data?.cover_image?.url})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="user-images">
          <div className="row">
            <div className="col-lg-12 col-12">
              <div
                className="user-image-desc text-center"
                onClick={() => setOpened(true)}
              >
                <div className="user-img mb-2">
                  <img
                    src={data?.profile_image?.url || defaultUserAvatar}
                    alt={data?.profile_image?.name}
                    className="img-fluid"
                  />
                  <FontAwesomeIcon icon={faCamera} />
                </div>
                <div className="user-info">
                  <h4 className="text-white mb-0 font-weight-bold">
                    {data?.name}
                  </h4>
                  {data?.created_at && (
                    <p className="text-white my-1">
                      Member Since {moment(data?.created_at).fromNow()}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="py-2">
        <ul className="nav user-profile-nav">
          <li className="nav-item">
            <Link className={`nav-link ` +  (location.pathname === '/profile' && 'active text-primary')} to="/profile">
              My Profile
            </Link>
          </li>
          {/* <li className="nav-item">
            <Link className={`nav-link ` + (location.pathname === '/edit-profile' && 'active text-primary')} to="/edit-profile">
              Edit Profile
            </Link>
          </li> */}
        </ul>
      </div>
      <Modal
        opened={opened}
        onClose={handleClose}
        size="md"
        title="Update profile picture"
      >
        <div className="border-top my-4"></div>
        <button onClick={handleClick} className="btn btn-outline-primary w-100">
          + Upload a file
        </button>
        <input
          type="file"
          ref={hiddenFileInput}
          onChange={handleChange}
          style={{ display: "none" }}
          accept="image/png , image/jpg"
        />
        {imagePreviewUrl && (
          <>
            <div className="text-center my-4">
              <img
                src={imagePreviewUrl}
                alt="profile preview"
                style={{ width: "150px", height: "150px" }}
                className="rounded-circle mx-auto"
              />
            </div>
            <Group position="right">
              <SubmitButton
                isLoading={imageUploadMutation.isLoading}
                title="Save"
                className="btn btn-primary ml-auto w-50"
                onClick={onUploadImage}
              />
            </Group>
          </>
        )}
        {errorMessage && (
          <div className="text-danger">Image must be of 2mb or less</div>
        )}
      </Modal>
    </div>
  );
};

export default UserProfile;
