import React from 'react'

const DescriptionTitle = (props) => {
  const {title} = props
  const styling = {
    color : "#7100FF"
  }
  return (
    <h6 className={`font-weight-bold text-capitalize`} style={styling}>{title}</h6>
  )
}

export default DescriptionTitle