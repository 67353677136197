import moment from "moment"

export const formatDate = (date) => {
    return moment(new Date(date)).format('YYYY/MM/DD')
}

export function formatDateTime(date) {
  return moment(new Date(date), 'dd MMM yyyy HH:mm');
}

export function differenceDate(startDate , endDate){
  return moment(endDate).diff(moment(startDate) , 'days')
}

export function dateFromNow(date){
  return moment(date).fromNow()
} 