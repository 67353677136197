import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGift } from "@fortawesome/free-solid-svg-icons";

import { Link } from "react-router-dom";
import { searchParams } from "../../_helpers/initialValues/searchParams";

const FeatureBox = (props) => {
  const location = props.location.toLowerCase();
  const propertyParams = searchParams(location)
  
  return (
    <Link
      to={{
        pathname: `${
          props.type === "properties"
            ? `/city/${props?.location}`
            : "/package/search"
        }`,
        search: `${
          props.type === "properties"
            ? `?${propertyParams}`
            : ""
        }`,
        state: props.type === "packages" && {
          search: location,
          count: 1,
          // latitude: lat,
          // longitude: long,
          address: location,
        },
      }}
    >
      <div className="feature-box card card-plain">
        <img src={props.img} className="card-img-top" alt={props.img} />
        <div
          className="card-img-overlay feature-overlay-contents"
          style={{ bottom: 0 }}
        >
          <div className="location-desc">
            <h5 className="mb-0 text-capitalize">{props.location}</h5>
            <span className="text-capitalize">
              <FontAwesomeIcon icon={faGift} className="mr-2" />
              {props.count} {props.type}
            </span>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default FeatureBox;

FeatureBox.propTypes = {
  title: PropTypes.string,
  reviews: PropTypes.number,
};
