import moment from "moment";
import React, { useState } from "react";
import { Group, Modal } from "@mantine/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/free-solid-svg-icons";

import { useAuth } from "../../hooks/useAuth";
import { useUploadProfile } from "../../hooks/useUploadProfile";
import { convertToBase64 } from "../../utils/convertToBase64";
import SubmitButton from "../atoms/SubmitButton";

const UserAvatar = ({createdAt , name , profileImage}) => {
  const {token , setAuth}= useAuth()
  const [opened, setOpened] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState("");
  const [image, setImage] = useState("");
  const hiddenFileInput = React.useRef(null);
  const imageUploadMutation = useUploadProfile();
  const handleClose = () => {
    setOpened(false);
    setImagePreviewUrl("");
    setErrorMessage('')
  };
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  const handleChange = async (event) => {
    const fileUploaded = event.target.files[0];
    // props.handleFile(fileUploaded);
    if (fileUploaded?.size / 1024 / 1024 < 2) {
      setErrorMessage('')
      const base64 = await convertToBase64(fileUploaded);
      setImage(base64);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreviewUrl(reader.result);
      };
      reader.readAsDataURL(fileUploaded);
    } else {
      setErrorMessage("Image must be less than 2 MB");
    }
  };
  const onUploadImage = () => {
    imageUploadMutation.mutate(image, {
      onSuccess: (data) => {
        setImagePreviewUrl("");
        setOpened(false);
        setErrorMessage("")
        const session = { token, user: data?.data }
        setAuth(session);
      },
    });
  };
  return (
    <>
    <div className="user-items text-center">
      <div className="user-profile">
        <div className="user-profile-img" onClick={() => setOpened(true)}>
          <img
            src={profileImage}
            alt={name}
          />
          <FontAwesomeIcon icon={faCamera} className="upload-image-icon"/>
          </div>
          <h4 className="mt-3">{name}</h4>
          {createdAt && (
            <p className="text-secondary h6 font-weight-light">
              Member Since {moment(createdAt).fromNow()}
            </p>
          )}
      </div>
    </div>
    {/* Modal to update avatar user */}
    <Modal
        opened={opened}
        onClose={handleClose}
        size="md"
        title="Update profile picture"
      >
        <div className="border-top my-4"></div>
        <button onClick={handleClick} className="btn btn-outline-primary w-100">
          + Upload a file
        </button>
        <input
          type="file"
          ref={hiddenFileInput}
          onChange={handleChange}
          style={{ display: "none" }}
          accept="image/png , image/jpg"
        />
        {imagePreviewUrl && (
          <>
            <div className="text-center my-4">
              <img
                src={imagePreviewUrl}
                alt="profile preview"
                style={{ width: "150px", height: "150px" }}
                className="rounded-circle mx-auto"
              />
            </div>
            <Group position="right">
              <SubmitButton
                isLoading={imageUploadMutation.isLoading}
                title="Save"
                className="btn btn-primary ml-auto w-50"
                onClick={onUploadImage}
              />
            </Group>
          </>
        )}
        {errorMessage && (
          <div className="text-danger">Image must be of 2mb or less</div>
        )}
    </Modal>
    </>
  );
};

export default UserAvatar;
