import { createContext, useState } from "react";

const setPackageSearchInLocalStorage = (data) => {
    localStorage.setItem('package-details', JSON.stringify(data))
    return true
};

const getPackageData = () => {
    return JSON.parse(localStorage.getItem('package-details')) || initialValues;
};

const initialValues = {
    destination: 'kathmandu',
    count: 1,
    totalAmount: 0,
    discountAmount : 0,
    regularAmount : 0,
    bookingStartDate : '',
    bookingEndDate: ''
};

export const PackageSearch = createContext(initialValues || "")

export const PackagesSearchContext = ({ children }) => {
    const [packageSearch, setPackageSearch] = useState(getPackageData || initialValues)
    const handlePackageSearch = (data) => {
        setPackageSearchInLocalStorage(data)
        setPackageSearch(data)
    }

    return (
        <PackageSearch.Provider value={{ packageSearch, handlePackageSearch }}>
            {children}
        </PackageSearch.Provider>
    )
}