import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';

import '../../assets/styles/breadcrumb.css';

const BreadcrumbTop = (props) => {

    const { title, page, active , pageLink , titleLink } = props;
    
    return (
        <section className="breadcrumb-top">
            <div className="card main-title">
                <nav aria-label="breadcrumb" role="navigation">
                    <ol className="breadcrumb bg-white mb-0">
                        <li className="breadcrumb-item"><Link to={titleLink || '/'}>{title}</Link></li>
                        <li className="breadcrumb-item"><Link to={pageLink || '/'}>{page}</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">{active}</li>
                    </ol>
                </nav>
                <Link to="/" className="home-button">
                    <FontAwesomeIcon icon={faHome} />
                </Link>
            </div>
        </section>
    )
}

export default BreadcrumbTop

BreadcrumbTop.defaultProps = {
    titleLink : '/'
  }

BreadcrumbTop.propTypes = {
    title: PropTypes.string,
    page: PropTypes.string,
    listings: PropTypes.string,
    active: PropTypes.string
}