import { useStringHook } from "./useStringHook";

export const usePropertyFilter = () => {
  const query = useStringHook();
  const latitude = query.get("latitude");
  const longitude = query.get("longitude");
  const address = query.get("address");
  const startDate = query.get("startDate");
  const endDate = query.get("endDate");
  const adultCount = query.get("adult");
  const childCount = query.get("child");
  const roomCount = query.get("rooms");
  const searchBy = query.get("searchBy");
  const campaignCode = query.get("campaignCode")

  return {
    latitude,
    longitude,
    address,
    startDate,
    endDate,
    adultCount,
    childCount,
    roomCount,
    searchBy,
    campaignCode
  };
};
