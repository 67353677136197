import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { Col, Row } from 'react-bootstrap';

const RoomFacilities = ({ data }) => {
    return (
        <div>
            <p className="mt-0 font-weight-bold">Room Facilities</p>
            <div>
                <Row>
                    <Col lg={6}>
                        <p className="mb-0 text-bold">Bedrooms</p>
                        <ul className="information-list ml-3">
                            <li className="information-list">{data?.type}</li>
                            <li className="information-list">Total Bedrooms : {data?.total_beds}</li>
                            <li className="information-list">Total rooms : {data?.total_rooms}</li>
                        </ul>
                        <p className="mb-0 text-bold">
                            Allowed children
                        </p>
                        <ul className="information-list ml-3">
                            <li className="information-list">Max Allowed childrens : {data?.max_allowed_childrens}</li>
                            <li className="information-list">Total Allowed Guests : {data?.max_allowed_guests}</li>
                        </ul>
                    </Col>
                    <Col lg={6}>
                        <p className='mb-0 text-bold'>
                            Inclusions
                        </p>
                        <ul>
                            {data?.inclusions.map((item, index) => <li key={index}>
                                <FontAwesomeIcon icon={faCheck} className="mr-2 text-success" />
                                {item}
                            </li>)}
                        </ul>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default RoomFacilities
