import React, { useState } from "react";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import { FiPhone } from "react-icons/fi";
import { BsChatSquareDots } from "react-icons/bs";
import { RiMapPinLine } from "react-icons/ri";
import { Link, useHistory } from "react-router-dom";
import { ListItem } from "@mantine/core";
import { toast } from "react-toastify";
import { CustomModal } from "./modals/CustomModal";
import { Button, Form } from "react-bootstrap";
import { GiPostOffice } from "react-icons/gi";

import { data } from "../../utils/mock/footerData";
import SocialLinks from "../atoms/SocialLinks";
import "../../assets/styles/footer.css";
import { mockData } from "../../utils/mock/data.js";
import { useTrackBooking } from "../../hooks/useTrackBooking";
import BottomNavigationBar from "../molecules/MobileFooter";
import { useWindowSize } from "../../hooks/useWindowSize";
import esewa from "../../assets/images/payment-methods/esewa.png";
import khalti from "../../assets/images/payment-methods/khalti.png";
import nicAsia from "../../assets/images/payment-methods/visa-nic-asia.jpg";
import appStore from "../../assets/images/install-application/appStore.png";
import googlePlay from "../../assets/images/install-application/googlePlay.png";

const Footer = () => {
  const history = useHistory();
  const mutation = useTrackBooking();
  const { width } = useWindowSize();

  const [referenceId, setReferenceId] = useState("");
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    setReferenceId("");
  };
  const handleShow = () => setShow(true);

  const handleSubmit = (e) => {
    e.preventDefault();
    mutation.mutate(referenceId, {
      onSuccess: (data) => {
        handleClose();
        setReferenceId("");
        toast.success(`Booking reference found successfully`);
        history.push(`/guest/view/${data?.booking_reference}`);
      },
      onError: () => {
        toast.error(`Provided booking reference id doesn't exist`);
      },
    });
  };
  const isMobile = width <= 992;

  return (
    <>
      <footer className="footer">
        <div className="footer-content">
          <div className="d-md-flex">
            <div className="footer-left">
              <h3 className="text-primary mb-0">
                {mockData.footer.menuTitle1}
              </h3>
              <p className="text-muted py-3">{mockData.footer.description}</p>
              <div className="mb-5 d-md-flex align-items-center justify-content-between">
                <SocialLinks
                  facebook={mockData.companySocialMediaDetails.facebook}
                  instagram={mockData.companySocialMediaDetails.instagram}
                  twitter={mockData.companySocialMediaDetails.twitter}
                  linkedin={mockData.companySocialMediaDetails.linkedin}
                />
                <div>
                  <div className="payment-method-wrapper">
                    <img
                      src={esewa}
                      alt="accepted-payment-esewa"
                      className="w-50"
                    />
                    <img
                      src={khalti}
                      alt="accepted-payment-khalti"
                      className="w-50"
                    />
                    <img
                      src={nicAsia}
                      alt="accepted-payment-nicAsia"
                      className="w-50"
                    />
                  </div>
                </div>
              </div>
              <hr />
              {/* copyright text */}
              <div>
                <p className="copy-right">{mockData.footer.copyrightText}</p>
              </div>
              <div className="app-icon-wrapper">
                <img src={appStore} alt="app-store" className="w-50" />
                <img src={googlePlay} alt="google-play" className="w-50" />
              </div>
            </div>
            {!isMobile && (
              <div className="footer-right h-100">
                <Row>
                  <Col md={6}>
                    <div>
                      <h3>
                        <span className="footer-title">
                          {mockData.footer.menuTitle2}
                        </span>
                      </h3>
                      <ul className="links-vertical">
                        {data.map((item) => (
                          <ListItem key={item.id} className="text-light">
                            <Link to={item.link}>{item.title}</Link>
                          </ListItem>
                        ))}
                        <ListItem className="text-light">
                          {/* eslint-disable-next-line */}
                          <a
                            href="#"
                            className="custom-link"
                            onClick={handleShow}
                          >
                            Track your booking
                          </a>
                        </ListItem>
                      </ul>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div>
                      <h3>
                        <span className="footer-title">
                          {mockData.footer.menuTitle3}
                        </span>
                      </h3>
                      <ul className="links-vertical">
                        <ListItem>
                          <FiPhone className="text-light" />
                          <a
                            href={`tel:${mockData.companyContactDetails.phoneNumber}`}
                            className="ml-2 text-light"
                          >
                            {mockData.companyContactDetails.phoneNumber}
                          </a>
                        </ListItem>
                        <ListItem>
                          <BsChatSquareDots className="text-light" />
                          <a
                            href={`mailto:${mockData.companyContactDetails.email}`}
                            className="ml-2 text-light"
                          >
                            {mockData.companyContactDetails.email}
                          </a>
                        </ListItem>
                        <ListItem>
                          <GiPostOffice className="text-light" />
                          <span className="ml-2 text-light">
                            {mockData.companyContactDetails.companyPostOffice}
                          </span>
                        </ListItem>
                        <ListItem>
                          <RiMapPinLine className="text-light" />
                          <span className="ml-2 text-light">
                            {mockData.companyContactDetails.companyLocation}
                          </span>
                        </ListItem>
                      </ul>
                    </div>
                  </Col>
                </Row>
              </div>
            )}
          </div>
          {/* modal to track the booking by reference id */}
          <CustomModal
            show={show}
            handleClose={handleClose}
            handleShow={handleShow}
          >
            <Form onSubmit={(e) => handleSubmit(e)}>
              <Form.Group className="mb-3" controlId="referenceId">
                <Form.Label>Reference Id</Form.Label>
                <Form.Control
                  value={referenceId}
                  type="text"
                  placeholder="Enter your reference id"
                  onChange={(e) => setReferenceId(e.target.value)}
                  required
                />
                <Form.Text className="text-muted">
                  Booking reference id is provided in email.
                </Form.Text>
              </Form.Group>
              <Button
                variant="primary"
                type="submit"
                disabled={mutation.isLoading}
              >
                {!mutation.isLoading && (
                  <span className="indicator-label">Continue</span>
                )}
                {mutation.isLoading && (
                  <span
                    className="indicator-progress"
                    style={{ display: "block" }}
                  >
                    Please wait...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </Button>
            </Form>
          </CustomModal>
        </div>
      </footer>
      {isMobile && <BottomNavigationBar />}
    </>
  );
};

export default Footer;

Footer.propTypes = {
  title: PropTypes.string,
};
