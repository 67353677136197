import ForgetPassword from "../components/auth/components/ForgetPassword";
import { Logout } from "../components/auth/components/Logout";
// import UserLogin from "../components/auth/components/UserLogin";
// import UserRegister from "../components/auth/components/UserRegister";
import AboutUs from "../components/pages/AboutUs";
import BlogDescription from "../components/pages/blog/BlogDescription";
import GuestView from "../components/pages/GuestView";
import ListingDescription from "../components/pages/ListingDescription";
import Checkout from "../components/pages/package/Checkout";
import PackageHomePage from "../components/pages/package/PackageHomePage";
import PackageSearchPage from "../components/pages/package/PackageSearchPage";
import Home from "../components/pages/property/Home";
import PropertyDescription from "../components/pages/property/PropertyDescription";
import TourList from "../components/pages/package/PackageDescription";
import RoomCheckoutPage from "../components/pages/property/RoomCheckoutPage";
import SearchPropertyList from "../components/pages/property/SearchPropertyList";
import TermsAndCondition from "../components/pages/TermsAndCondition";
import ContactUs from "../components/pages/ContactUs";
import SupplierHomePage from "../components/pages/SupplierHomePage";
import ImpersontateUser from "../components/pages/ImpersontateUser";
import PrivacyDisclaimer from "../components/pages/privacyConditions/PrivacyDisclaimer";
import PrivacyCookies from "../components/pages/privacyConditions/PrivacyCookies";
import PaymentSuccessPage from "../components/pages/payment/PaymentSuccessPage";
import PaymentPage from "../components/pages/payment/PaymentPage";
import SubscriptionPage from "../components/pages/subscriptions/SubscriptionsPage";
import PaymentErrorPage from "../components/pages/payment/PaymentErrorPage";
import PaymentVerificationPage from "../components/pages/payment/PaymentVerificationPage";
import ListByCities from "../components/pages/listByCity";
import BlogPage from "../components/pages/blog/BlogPage";
import Error404 from "../components/pages/Error404";
import UserLogin from "../components/auth/components/UserLogin";
import UserRegister from "../components/auth/components/UserRegister";
import ResetPassword from "../components/auth/components/ResetPassword";
import FAQ from "../components/pages/FAQ";
import Deals from "../components/pages/Deals";

export const publicRoutes = [
  {
    path: "/travel/home",
    component: PackageHomePage,
    id: 1,
  },
  {
    path: "/",
    component: Home,
    id: 2,
  },
  {
    path: "/supplier/home",
    component: SupplierHomePage,
    id: 3,
  },
  {
    path: "/package/search",
    component: PackageSearchPage,
    id: 4,
  },
  {
    path: "/about",
    component: AboutUs,
    id: 5,
  },
  {
    path: "/blog/:slug",
    component: BlogDescription,
    id: 7,
  },
  {
    path: "/room/:roomSlug",
    component: ListingDescription,
    id: 8,
  },
  {
    path: "/package/:packageSlug",
    component: TourList,
    id: 9,
  },
  {
    path: "/property/:propertySlug",
    component: PropertyDescription,
    id: 10,
  },
  {
    path: "/checkout/:packageSlug",
    component: Checkout,
    id: 11,
  },
  {
    path: "/user/logout",
    component: Logout,
    id: 12,
  },
  {
    path: "/user/login",
    component: UserLogin,
    id: 31,
  },
  {
    path: "/user/register",
    component: UserRegister,
    id: 32,
  },
  {
    path: "/reset-password",
    component: ResetPassword,
    id: 32,
  },
  {
    path: "/forget-password",
    component: ForgetPassword,
    id: 13,
  },
  {
    path: "/guest/view/:bookingReference",
    component: GuestView,
    id: 15,
  },
  {
    path: "/checkout/property/:propertySlug/room/:roomSlug",
    component: RoomCheckoutPage,
    id: 16,
  },
  {
    path: `/search/property/listing`,
    component: SearchPropertyList,
    id: 17,
  },
  {
    path: `/terms-condition`,
    component: TermsAndCondition,
    id: 18,
  },
  {
    path: "/contact-us",
    component: ContactUs,
    id: 19,
  },
  {
    path: "/impersonate",
    id: 20,
    component: ImpersontateUser,
  },
  //privacy and user agreements
  {
    id: 21,
    path: "/privacy-disclaimer-user-agreement",
    component: PrivacyDisclaimer,
  },
  {
    id: 22,
    path: "/privacy-cookies",
    component: PrivacyCookies,
  },
  {
    id: 23,
    path: "/payment-success-page",
    component: PaymentSuccessPage,
  },
  {
    id: 24,
    path: "/payment/:bookingReference?",
    component: PaymentPage,
  },
  {
    id : 25,
    path : "/unsubscribe",
    component : SubscriptionPage
  },
  {
    id: 26,
    path : '/payment-error-page',
    component : PaymentErrorPage ,
  },{
    id:27,
    path : '/payment-verification-page',
    component : PaymentVerificationPage
  },
  {
    id:28,
    path : '/city/:city',
    component : ListByCities
  },
  {
    id : 29 ,
    path : '/blogs',
    component : BlogPage
  },
  {
    id:30,
    path: '/faq',
    component: FAQ
  },
  {
    id:31,
    path:"/deals",
    component:Deals,
  }
];
