import React from 'react'
import { useHistory } from 'react-router-dom'
import { DateRangePicker } from '@mantine/dates'
import { Col, Row } from 'react-bootstrap'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import '../../assets/styles/avaibilitySearch.css'
import { usePropertyQuery } from '../../hooks/usePropertyQuery'
import { formatDate } from '../../utils/formatdate'
import CounterBox from './CounterBox'

export const RoomAvaibilitySearchBar = ({ search, slug, date, setDate, refetch, adult, setAdult, room, setRoom, child, setChild, startDate }) => {

    const history = useHistory();

    const { handlePropertySearch } = usePropertyQuery();

    const onSubmit = () => {
        handlePropertySearch({
            startDate: formatDate(date[0]), endDate: formatDate(date[1]), adult, child, room
        })
        history.push({
            pathname: `/property/${slug}`,
            state: { search: search, startDate: formatDate(date[0]), endDate: formatDate(date[1]), adult, child },
            search: `?search=${search}&startDate=${formatDate(date[0])}&endDate=${formatDate(date[1])}&adult=${adult}&child=${child}&rooms=${room}`,
        })
        refetch()
    };

    return (
        <div className='room-avaibility-form'>
            <Row className="align-items-center">
                <Col sm={5} className="my-1">
                    <DateRangePicker
                        placeholder="Check-in - Check-out"
                        value={date || new Date()}
                        onChange={setDate}
                        minDate={new Date()}
                        amountOfMonths={2}
                    />
                </Col>
                <Col sm={4} className="my-1">
                    <CounterBox adult={adult} child={child} setAdult={setAdult} rooms={room} setRooms={setRoom} setChild={setChild} type='hotel' />
                </Col>
                <Col sm={3}>
                    <button
                        type="submit"
                        className="btn btn-primary border-0 rounded-pill fw-600"
                        onClick={onSubmit}
                    >
                        <FontAwesomeIcon icon={faSearch} />
                        <span className="mx-2">Check avaibility</span>
                    </button>
                </Col>
            </Row>
        </div>
    )
}
