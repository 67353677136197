import React from 'react'
import PropTypes from 'prop-types';

const ChatMessage = ({ variant, para, message, optional }) => {
    return (
        <div className={`mt-3 ${variant}`}>
            <div className={`${message}-msg`}>
                <div className={optional}>
                    <p>{para}</p>
                </div>
            </div>
        </div>
    )
}

export default ChatMessage


ChatMessage.propTypes = {
    optional: PropTypes.string,
    variant: PropTypes.string,
    date: PropTypes.any,
    para: PropTypes.string,
}

