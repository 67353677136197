import React from "react";
import { Col, Row } from "react-bootstrap";
import PropType from "prop-types";
import { Skeleton } from "@mantine/core";

import "../../../../assets/styles/paymentMethod.css";
import Error from "../../Error";
import { PaymentMethodsWalletItem } from "./PaymentMethodItem";

export const PaymentMethodsWallet = (props) => {

  const { handleSelection, selectedWallet , paymentMethods , isLoading, isError } = props;

  if (isError) return <Error />;

  return (
    <div className="payment_methods_wrapper">
      <h6 className="pt-3">Select Payment Methods</h6>
      <hr />
      <Row>
        {isLoading ? (
          <>
            <Col md={4} className="mb-4">
              <Skeleton height={100} width="100%" />
            </Col>
            <Col md={4} className="mb-4">
              <Skeleton height={100} width="100%" />
            </Col>
            <Col md={4} className="mb-4">
              <Skeleton height={100} width="100%" />
            </Col>
          </>
        ) : (
          paymentMethods?.payment_methods.map(
            (paymentMethod) =>
              paymentMethod?.is_enabled && (
                <Col md={4} key={paymentMethod.code} className="text-center">
                  <PaymentMethodsWalletItem
                    handleSelection={handleSelection}
                    isSelected={selectedWallet === paymentMethod.code}
                    featureName={paymentMethod.name}
                    showTitle = {paymentMethod.show_title}
                    logo={paymentMethod.logo}
                    code = {paymentMethod.code}
                  />
                </Col>
              )
          )
        )}
      </Row>
    </div>
  );
};

PaymentMethodsWallet.prototype = {
  selectedWallet: PropType.string.isRequired,
  handleSelection: PropType.func.isRequired,
};
