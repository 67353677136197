import React from "react";
import { useProperties } from "../../hooks/reactQueryCustomHook/property/useGetProperties";
import CampaignBanner from "../organisms/CampaignBanner";
import BestDeals from "../organisms/BestDeals";
import Header from "../organisms/Header";
import Footer from "../molecules/Footer";
import { Container } from "react-bootstrap";
import MainHeading from "../atoms/MainHeading";
import { mockData } from "../../utils/mock/data";

const Deals = () => {
  const { data: hotDeals, isLoading: isHotDealsLoading } =
    useProperties("hot_deals");

  return (
    <div>
      <Header />
      <div className="my-5">
        <Container>
          <MainHeading
            text={mockData.sectionHeader.deals.title}
            para={mockData.sectionHeader.deals.paragraph}
          />
        </Container>
        <CampaignBanner type="property" />
      </div>
      <BestDeals
        data={hotDeals}
        isLoading={isHotDealsLoading}
        type={"property"}
      />
      <Footer />
    </div>
  );
};

export default Deals;
