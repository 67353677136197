import React from 'react';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { Form, Field, ErrorMessage, Formik } from 'formik';
import * as Yup from 'yup';

import Navbar from '../../molecules/Navbar';
import { authenticationService } from '../../../services/api/authentication';

const initValues = {
    email: ""
};

const passwordSchema = Yup.object().shape({
    email: Yup.string()
        .email("Wrong email format")
        .min(3, "Minimum 3 symbols")
        .max(50, "Maximum 50 symbols")
        .required("Email is required"),
});

const ForgetPassword = () => {
    const { mutate, isLoading } = useMutation(authenticationService.forgetPassword, {
        onSuccess: () => {
            toast.success('Password reset link has been sent , please check your email')
        },
        onError: (err) => {
            toast.error(`${err?.response?.data?.error?.message}`)
        }
    });

    const handleSubmit = (values , {resetForm}) => {
        const { email } = values
        mutate(email , {
            onSuccess : () => {
                resetForm();
            }
        })
    }
    return (
        <div className='login'>
            <Navbar title=".Travel" />
            <div className='login-wrapper'>
                <h6 className="text-light">Find Your Account
                </h6>
                <hr />
                <Formik initialValues={initValues} validationSchema={passwordSchema} onSubmit={handleSubmit}>
                    <Form>
                        <label htmlFor='email' className='text-light'>Please enter your email to search for your account.</label>
                        <Field name="email" type="email" className="form-control " placeholder="Enter your email" />
                        <div className='text-danger'>
                            <ErrorMessage name="email" />
                        </div>
                        {/* {
                            isError && errors?. 
                        } */}
                        <button
                            type="submit"
                            id="kt_sign_in_submit"
                            className="btn btn-md p-2 btn-primary w-100 my-3"
                            disabled={isLoading}
                        >
                            {!isLoading && <span className="indicator-label">Continue</span>}
                            {isLoading && (
                                <span className="indicator-progress" style={{ display: "block" }}>
                                    Please wait...
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            )}
                        </button>
                    </Form>
                </Formik>
            </div>
        </div>
    )
}

export default ForgetPassword