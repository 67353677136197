import React from 'react'
import { faFacebookF, faInstagram, faTwitter , faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const SocialLinks = ({ facebook, instagram, twitter , linkedin }) => {

    const data = [
        {
            id: 1,
            name: faFacebookF,
            to: facebook,
            className: 'facebook-user'
        },
        {
            id: 2,
            name: faTwitter,
            to: twitter,
            className: 'twitter-user'
        },
        {
            id: 3,
            name: faInstagram,
            to: instagram,
            className: 'google-user'
        },
        {
            id: 4,
            name: faLinkedin,
            to: linkedin,
            className: 'linkedin-user'
        },

    ];

    return (
        <div>
            <div className="social-links">
                {
                    data?.map((item) =>
                        <a rel="noreferrer" key={item?.id || item} href={`${item.to}`} className={`${item?.className}`} target="_blank">
                            <FontAwesomeIcon icon={item?.name} />
                        </a>
                    )
                }
            </div>
        </div>
    )
}

export default SocialLinks
