import { useState } from 'react'

export const useFilter = () => {
    const [sortType, setSortType] = useState("");
    const [isOffer, setIsOffer] = useState(false);

    const handleSort = (e) => {
        setSortType(e.target.value);
      };
      const handleIsOffer = () => {
        setIsOffer(prev => !prev)
      };
      
    return {sortType , handleSort, isOffer , handleIsOffer}
}
  