import React from "react";

import defaultImage from "../../assets/images/userProfile.png";
import Rating from "../atoms/Rating";
import "../../assets/styles/reviewCard.css";

const ReviewCard = (props) => {
  const { children, date, para, img } = props;
  return (
    <div className="comments mb-4">
      <div className="row">
        <div className="col-md-12">
          <div className="comment-area">
            <div className="card-body pt-0">
              <div className="d-flex">
                <div>
                  <div className="avatar">
                    <img
                      className="media-object"
                      src={img || defaultImage}
                      alt="review card"
                    />
                  </div>
                </div>
                <div className="ml-4">
                  <h5 className="card-title mb-2 mt-1">{children}</h5>
                  <div className="align-items-center mb-3 text-secondary">
                    <p className="font-weight-light my-0">{date}</p>
                    <div className="rating-stars">
                      <Rating star={props?.rating} />
                    </div>
                  </div>
                  <p className="text-justify">{para}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewCard;
