import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import MainHeading from "../atoms/MainHeading";
import { mockData } from "../../utils/mock/data";
import PropertyCard from "../molecules/cards/Propertycard";
import PackageCard from "../molecules/cards/PackageCard";
import SkeletonCard from "../molecules/cards/SkeletonCard";

const BestDeals = (props) => {
  const { data } = props;
  return (
    <div>
      {props.type === "property" ? (
        <section className="section bg-light">
          <Container>
            <MainHeading
              text={mockData.sectionHeader.bestDeals.title}
              para={mockData.sectionHeader.bestDeals.subTitle}
            />
            <Row>
              {props.isLoading ? (
               <SkeletonCard count={3} />
              ) : data?.length > 0 ? (
                data?.map((item) => (
                  <Col lg={4} md={6} key={item?.id}>
                    <PropertyCard
                      title={item.title}
                      image={item?.cover_image?.url}
                      location={`${item?.city}`}
                      displayButton={false}
                      displayFooter={false}
                      rating={true}
                      item={item}
                      alt={item?.thumbnail?.original_name}
                      link="property"
                    ></PropertyCard>
                  </Col>
                ))
              ) : (
                <h4 className="text-center mx-auto">
                  No best hot deals to show
                </h4>
              )}
            </Row>
          </Container>
        </section>
      ) : (
        <section className="section bg-light">
          <Container>
            <MainHeading
              text="Best Hot Deals."
              para="These companies release their own versions of the operating systems with minor changes, and yet always."
            />
            {data?.length < 0 ? (
              <div className="text-center font-weight-bold text-muted">
                No best hot deals to show
              </div>
            ) : (
              <Row>
                {data?.slice(0, 3)?.map((item) => (
                  <div key={item.id} className="col-md-4">
                    <PackageCard
                      title={item?.title}
                      image={item?.thumbnail?.url || item?.cover_image?.url}
                      rate={`Rs .${item?.unit_price}`}
                      displayButton={true}
                      displayFooter={true}
                      rating={true}
                      item={item}
                      website={item?.company?.company_details?.website.slice(
                        0,
                        25
                      )}
                      number={item?.company?.company_details?.company_phone}
                      alt={item?.thumbnail?.original_name}
                      link="package"
                      websiteLink={item?.company?.company_details?.website}
                    >
                      {item.description?.substring(0, 120) ||
                        item?.additional_information?.substring(0, 100)}
                    </PackageCard>
                  </div>
                ))}
              </Row>
            )}
          </Container>
        </section>
      )}
    </div>
  );
};

export default BestDeals;
