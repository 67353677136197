import React from "react";

import Header from '../../organisms/Header';
import Footer from '../../molecules/Footer';
import "../../../assets/styles/paymentSuccess.css";
import { useStringHook } from "../../../hooks/useStringHook";
import PaymentSuccessDetails from "./components/PaymentSuccessDetails";
import { useGuestBooking } from "../../../hooks/reactQueryCustomHook/booking/useGuestBooking";
import { FallBackView } from "../FallBackView";
import Error from "../Error";

const PaymentSuccessPage = () => {
  // const {bookingReference} = useBookingReference();
  const query = useStringHook();  
  const bookingReference = query.get("bookingReference") || query.get("purchase_order_id");
  const {data , isLoading , isError} = useGuestBooking(bookingReference);

  if(isLoading) return <FallBackView />
  if(isError) return <Error />

  return (
    <div className="payment-success-page w-100">
      <Header />
      <PaymentSuccessDetails bookingReference={bookingReference} remarks={data?.coupon?.remarks} isFree={data?.is_free} />
      <Footer />
    </div>
  );
};

export default PaymentSuccessPage;
