import {
  faGripHorizontal,
  faGripVertical,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Form } from "react-bootstrap";

const Results = (props) => {
  const { handleSort, sortType, clickOrientation, grid , handleIsOffer } = props;
  return (
    <div className="results py-3">
      <div className="result-card">
        <div className="card-body py-2">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="d-flex align-items-center">
                <span className="mr-3 small">Filter By: </span>
                <Form.Group controlId="formBasicCheckbox" className="mb-0 text-dark small">
                  <Form.Check type="checkbox" label="Special Offers" onClick={handleIsOffer} />
                </Form.Group>
              </div>
            </div>
            <div className="col-md-6">
              <div className="sort-by d-flex justify-content-md-end align-items-center">
                <h6 className="mb-0 mr-2 small">Sort by:</h6>
                <select
                  value={sortType}
                  onChange={(e) => handleSort(e)}
                  className="form-control col-md-7 col-8"
                >
                  <option value="">Choose</option>
                  <option value="highest_rating">Rating: High - Low</option>
                  <option value="lowest_rating">Rating: Low - High</option>
                  <option value="latest">Latest</option>
                  <option value="oldest">Oldest</option>
                  <option value="highest_price">Price: High - Low</option>
                  <option value="lowest_price">Price: Low - High</option>
                </select>
                {grid === "vertical" ? (
                  <button
                    onClick={clickOrientation}
                    className="btn btn-outline-light btn-lg text-dark"
                  >
                    <FontAwesomeIcon icon={faGripVertical} />
                  </button>
                ) : (
                  <button
                    onClick={clickOrientation}
                    className="btn btn-outline-light btn-lg text-dark"
                  >
                    <FontAwesomeIcon icon={faGripHorizontal} />
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Results;
